import React from "react";
import "pages/AboutUs/AboutUs.scss";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import Badges from "components/Badges/BadgesContainer";
import BlogCarousel from "components/Carousel/BlogCarousel/BlogCarousel";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import Head from "components/reusable/Head/Head";

const InitiativeRow = ({ header, body, carouselItems, bg }) => {
  return (
    <div
      className="about-us-blog-container"
      style={{ background: bg || "#f6e8ed" }}
    >
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="about-us-blog-content-container">
            <div className="about-us-blog-content">
              <h2>{header}</h2>

              <p>{body}</p>
            </div>
            <BlogCarousel items={carouselItems} />
          </div>
        </div>
      </div>
    </div>
  );
};

const BigImagePanel = ({ image, title, url, linkText }) => {
  return (
    <div className="about-us-box">
      <WagtailImage width={556} height={305} image={image} />

      <div className="about-us-box-content">
        <h3>{title}</h3>
        <PageLink to={url} bold arrow={"true"}>
          {linkText}
        </PageLink>
      </div>
    </div>
  );
};

const AboutUsSection = ({ header, panels }) => {
  return (
    <div className="about-us-work-container">
      <h2>{header}</h2>

      <div className="about-us-box-container">
        {panels &&
          panels.map((panel) => {
            return (
              <BigImagePanel
                image={panel.image}
                title={panel.title}
                url={panel.link_url}
                linkText={panel.link_txt}
              />
            );
          })}
      </div>
    </div>
  );
};

const AboutUs = () => {
  const { search, pathname } = useLocation();
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(search).useGetAboutUsPageQuery({
      queryParams: search,
      localePath: pathname.split("/")[1],
      region: pathname.split("/")[2],
    })
  );

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield | Notre histoire" :
          "About Us | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Fondée en 1988, l’entreprise a toujours une mission simple : créer des collectivités plus connectées. Depuis nos humbles débuts à Toronto, notre portée s’est étendue en Ontario, à Montréal et à Vancouver. " :
          "Founded in 1988, our mission has always been simple - to create better connected communities. Since our humble beginnings in Toronto, our reach has grown to expand across Ontario, Montreal and Vancouver. "
        }
        imageName={"AboutUs"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="about-us-header">
            <div className="about-us-header-wrapper">
              <div className="about-us-header-content">
                <h1>{info?.main_header}</h1>

                {convertHtmlToReact(info?.main_body)}
              </div>

              <div className="about-us-video-container">
                {!info?.main_image_video_url ? (
                  <WagtailImage
                    style={{
                      borderRadius: "1rem",
                    }}
                    image={info?.main_image}
                  />
                ) : (
                  <VideoPlayer url={info?.main_image_video_url} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* QUOTE */}
      <div className="about-us-quote-container">
        <div className="resi-container">
          <div className="resi-margin-container">
            <WagtailImage image={info?.quote_icon_ras || info?.quote_icon_svg} />

            <p className="Wbold">{info?.quote_body}</p>

            <p className="Wbold">{info?.quote_author}</p>
            <p>{info?.quote_author_title}</p>
          </div>
        </div>
      </div>

      {/* BADGES WITH DESCRIPTION */}
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="about-us-badge-container">
            <h2>{info?.values_header}</h2>

            {info?.values_badges?.length > 0 && (
              <Badges
                badges={info.values_badges.map((badge) => {
                  return {
                    image: badge.image,
                    title: badge.title,
                    description: badge.body,
                  };
                })}
              />
            )}
          </div>
        </div>
      </div>

      {/* INITIATIVES */}
      <div className="about-us-carousel-header">
        <div className="resi-container">
          <div className="resi-margin-container">
            <h2>{info?.initiatives_header}</h2>
            <p>{info?.initiatives_summary}</p>
          </div>
        </div>
      </div>

      <InitiativeRow
        header={info?.initiatives_section_1_header}
        body={info?.initiatives_section_1_body}
        carouselItems={info?.initiative_1_carousel}
        bg="#f6e8ed"
      />

      <InitiativeRow
        header={info?.initiatives_section_2_header}
        body={info?.initiatives_section_2_body}
        carouselItems={info?.initiative_2_carousel}
        bg="#EAFCC3"
      />

      <InitiativeRow
        header={info?.initiatives_section_3_header}
        body={info?.initiatives_section_3_body}
        carouselItems={info?.initiative_3_carousel}
        bg="#E0D9EC"
      />

      <div className="resi-container">
        <div className="resi-margin-container">
          <AboutUsSection
            info={info}
            header={info?.careers_header}
            panels={info?.big_image_links}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
