import React from "react";
import "components/reusable/Modal/BackDrop.scss";
import { motion } from "framer-motion";

const BackDrop = ({ children, setShowModal }) => {
  return (
    <motion.div
      onClick={() => setShowModal(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      className="backdrop_container"
    >
      {children}
    </motion.div>
  );
};

export default BackDrop;
