const deriveRegionByLatAndLong = (latitude, longitude) => {
  if (
    latitude >= 44.99 &&
    latitude <= 62.59 &&
    longitude >= -79.76 &&
    longitude <= -57.10
  ) {
    return "mtl";
  } else if (
    latitude >= 48.30 &&
    latitude <= 60.00 &&
    longitude >= -139.06 &&
    longitude <= -114.03
  ) {
    return "van";
  } else if (
    latitude >= 41.66 &&
    latitude <= 56.86 &&
    longitude >= -95.16 &&
    longitude <= -74.34
  ) {
    return "tor";
  } else {
    return "";
  }
};

const useGeolocation = () => {
  if (localStorage.getItem("region") === null) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        const region = deriveRegionByLatAndLong(latitude, longitude);
        localStorage.setItem("region", region);
      }
    );
  }
};

export { useGeolocation, deriveRegionByLatAndLong };
