import React from "react";
import Caret from "assets/Icons/Caret";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useSelector } from "react-redux";
import useWindowDimensions from "utils/WindowWidth";

const CalendarHeader = ({
  currentMonth,
  nextMonth,
  prevMonth,
  currentMonthDisplayed,
  cmsData
}) => {
  const { localization } = useSelector((state) => state.localizationSlice);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  return (
    <div className="calendar_header_container">
      <div className="calendar-navigation" style={{ color: currentMonthDisplayed ? "rgba(0,0,0,0.25)" : "#000000"}} onClick={() => prevMonth()} tabIndex="0">
          <Caret
            turn={"left"}
            color={currentMonthDisplayed ? "rgba(0,0,0,0.25)" : "#000000"}
            width="15px"
            height="15px"
            viewBox="0 0 12 8"
            className="caret"
          />
          {!isMobile ? <span>{cmsData?.step_2_previous_month_btn_txt}</span> : null }

      </div>
      <h2 tabIndex="0">
        {dayjs(currentMonth[1][0]).locale(localization).format("MMMM YYYY")}
      </h2>
      <div className="calendar-navigation" onClick={() => nextMonth()} tabIndex="0">
          {!isMobile ? <span>{cmsData?.step_2_next_month_btn_txt}</span> : null }
          <Caret
            turn={"right"}
            color="#000000"
            width="15px"
            height="15px"
            viewBox="0 0 12 8"
            className="caret"
          />
      </div>
    </div>
  );
};

export default CalendarHeader;
