const { baseBeanfieldQuery } = require("api/config");
const { createApi } = require("@reduxjs/toolkit/query/react");


export const SurveyPageApi = createApi({
  reducerPath: "SurveyPageApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    submitSurvey: builder.mutation({
      query: (surveyObject) => {
        return {
          url: "/v2/customer-survey",
          method: "POST",
          body: surveyObject,
        };
      },
    }),
  }),
});


export const {
  useSubmitSurveyMutation,
} = SurveyPageApi;
