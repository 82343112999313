import React from "react";
import "components/reusable/ContactInfo/ContactInfo.scss";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import Button from "../Button/Button";

const Badge = ({ image, title, body, button }) => {
  return (
    <div className="homepage-contact-item">
      <WagtailImage height={115} image={image} />
      <h4 tabIndex="0">{title}</h4>
      <div tabIndex="0">{convertHtmlToReact(body)}</div>
      {button.text && button.url && (
        <Button
          btnStyle={"beanfield-button--hollow"}
          style={{
            borderColor: "#325a32",
            fontWeight: 700,
            color: "#325a32",
            marginTop: "1.5rem",
          }}
          click={() => {
            const widget = document.querySelector('[name="widget_trigger_iframe"]');
            if (widget) {
              let widgetButton = widget['contentWindow'].document.querySelector('.preemtive-button');
              if (widgetButton) {
                widgetButton.click();
              }
            }
          }} >
          {button.text}
        </Button>
      )}
    </div>
  );
};

const ContactInfo = ({ title, description, badges }) => {
  const query = getQueries(useLocation().search).useGetHomePageQuery({
    queryParams: useLocation().search,
    localePath: useLocation().pathname.split("/")[1],
    region: useLocation().pathname.split("/")[2],
  });

  const info = query.data?.items ? query.data?.items[0] : query.data;

  return (
    <div className="resi-container">
      <div className="resi-margin-container">
        <div className="homepage-contact-container">
          {title ? <h3 tabIndex="0">{title}</h3> : null}
          {description ? <p tabIndex="0">{description}</p> : null}

          <div className="homepage-contact">
            {badges?.length > 0 &&
            badges.map((badge, index) => {
              return (
                <Badge
                  image={badge.image}
                  title={badge.title}
                  body={badge.description}
                  button={{
                    text: badge.button_txt,
                    url: badge.button_url,
                  }}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
