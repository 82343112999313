import React, { useEffect, useState } from "react";
import "components/reusable/Accordion/Accordion.scss";
import Checkbox from "../Checkbox/Checkbox";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  setTv,
  setHomePhone,
  setTvChannels,
  setThemePacks,
} from "store/reducers/order/orderSlice";
import AlacarteCloseBtn from "assets/svg/AlacarteCloseBtn";
import useWindowDimensions from "utils/WindowWidth";
import Tooltip from "components/reusable/Tooltip/Tooltip";
import TooltipMobile from "components/reusable/Tooltip/TooltipMobile";
import Caret from "assets/Icons/Caret";
import {
  useGetInternetPackagesQuery,
  useGetTvPackagesQuery,
  useGetHomePhonePackagesQuery,
} from "api/beanfield/OrderPage";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import { getCookie } from "utils/Cookies";

const Accordion = ({
  checkbox,
  title,
  info_icon,
  children,
  inner,
  subTitle,
  type,
}) => {
  const { pathname } = useLocation();
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { order, userInfo } = useSelector((state) => state.orderSlice);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  //CMS DATA
  const { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  //INTERNET PACKAGES
  const { data: internetPackages } = useGetInternetPackagesQuery({
    address_id: userInfo?.address_id,
  });
  //TV PACKAGES
  const { data: tvPackages } = useGetTvPackagesQuery({
    address_id: userInfo?.address_id, //TODO: CHANGE TO DYNAMIC ADDRESS ID
  });
  //HOME PHONE PACKAGES
  const { data: homePhonePackages } = useGetHomePhonePackagesQuery({
    address_id: userInfo?.address_id,
  });
  const token = getCookie("token");

  const handleInternalLinks = (link) => {
    let uri;

    if (link && link.includes("https://internallink.com")) {
      uri =
        "/" + internalUrlReaderWithLangReg(link, pathname);
    } else {
      uri = link;
    }
    return uri;
  };

  const handleKeyDownCaret = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setAccordionOpen(!accordionOpen);
    }
  };

  const transform = (node) => {
      if (node.type === "tag" && node.name === "a" && node.attribs.href) {
        node.attribs.href = handleInternalLinks(node.attribs.href);
      }
  };

  //CHECKING WHAT ACTION TO DISPATCH BASED ON THE TITLE OF THE ACCORDION
  const checkBoxAction = (beanfield_package, action) => {
    const title =
      beanfield_package?.title?.split("-")[1] || beanfield_package?.title;
    switch (type) {
      case "internet":
        //NO ACTION FOR THE INTERNET BECAUSE IT IS ALWAYS SELECTED BY DEFAULT[0] ON orderPage
        break;
      case "tv":
        dispatch(
          setTv({
            price: action ? beanfield_package?.price : 0,
            id: action ? beanfield_package?.id : null,
            title: action ? title : "",
            storage: {
              id:
                beanfield_package?.storage?.choices?.filter(
                  (item) => item?.hours === 25
                )[0]?.id || null,
            },
          })
        );
        break;
      case "phone":
        dispatch(
          setHomePhone({
            id: action ? homePhonePackages?.packages[0]?.id : null,
            price: action ? homePhonePackages?.packages[0]?.price : 0,
          })
        );
        // the customer has selected homephone. send an event
        if (token) {
          sendGoogleTagEvent({ eventName: "Lucky Checkout Step 4" });
        } else {
          sendGoogleTagEvent({ eventName: "Checkout Step 4" });
        }
    }
  };

  useEffect(() => {
    //TO OPEN INTERNET ACCORDION BY DEFAULT
    if (type === "internet") {
      setAccordionOpen(true);
    }
  }, []);

  return (
    <div
      style={{ borderRadius: inner && ".5rem" }}
      className="accordion_container"
    >
      {/* ACCORDION TITLE/TOP SECTION */}
      <div className="accordion_title_wrapper">
        <div className="accordion_left_title">
          <div
            onClick={() => setAccordionOpen(!accordionOpen)}
            className="accordion_checkbox"
          >
            {/* CHECKBOX */}
            {checkbox ? (
              <div className="accordion_checkbox_element">
                <Checkbox
                  type={type}
                  title={title}
                  order={order}
                  isChecked={type === "internet"}
                  isDisabled={type === "internet"}
                  onClick={(action) => {
                    if (accordionOpen && action === true)
                      setAccordionOpen(true);
                    else setAccordionOpen(!accordionOpen);
                    checkBoxAction(tvPackages?.packages?.[0], action);
                  }}
                  ariaLabel={title}
                />
              </div>
            ) : null}
            {/* TITLE */}
            {inner ? (
              <div className="accordion_inner_title" tabIndex="0">
                <p style={{ flex: 1 }} >
                  <strong className="Wbold" style={{ fontSize: "1.25rem" }}>
                    {title}
                  </strong>{" "}
                  {width < 768 ? <br /> : null}
                  <span style={{ fontSize: "1.25rem" }}>{subTitle}</span>
                </p>
              </div>
            ) : (
              <h2
                style={{
                  marginLeft: checkbox ? "1rem" : "2.75rem",
                  fontSize: inner && "1.25rem",
                }}
                tabIndex="0"
              >
                {title}
                {/* INFO ICON */}
                {info_icon && width > 768 ? (
                  <Tooltip>
                    {convertHtmlToReact(data?.step_1_internet_tooltip, {transform: transform})}
                  </Tooltip>
                ) : info_icon ? (
                  <TooltipMobile
                    containerStyles={{ display: "inline-block"}}
                    iconStyles={{ marginBottom: 0, marginTop: 0, verticalAlign: "middle" }}
                  >
                    {convertHtmlToReact(data?.step_1_internet_tooltip, {transform: transform})}
                  </TooltipMobile>
                ) : null}
              </h2>
            )}
          </div>
        </div>
        <div
          onClick={() => setAccordionOpen(!accordionOpen)}
          onKeyDown={(e) => handleKeyDownCaret(e)}
          className="accordion_right_title"
          tabIndex="0"
        >
          {accordionOpen ? (
            <Caret color="black" turn="up" />
          ) : (
            <Caret color="black" turn="down" />
          )}
        </div>
      </div>

      {/* A LA CARTE CHANNELS WHEN SELECTED AS ADDONS */}
      {title === "Add A la Carte Channels" ? (
        <div className="accordion_alacarte_container" tabIndex="0">
          {order?.tv?.channels?.selected?.map((channel, index) => (
            <div key={channel?.channel_id} className="accordion_alacarte_item">
              <span className="accordion_alacarte_item_text">
                {channel?.title}
              </span>
              <span
                onClick={() =>
                  dispatch(
                    setTvChannels({
                      action: "del",
                      value: channel,
                    })
                  )
                }
                className="accordion_alacarte_item_close"
              >
                <AlacarteCloseBtn />
              </span>
            </div>
          ))}
        </div>
      ) : null}

      {/* THEME PACKS  WHEN SELECTED AS ADDONS */}
      {title === "Add Theme Packs" ? (
        <div className="accordion_alacarte_container" tabIndex="0">
          {order?.tv?.themePacks?.selected?.map((channel, index) => (
            <div key={index} className="accordion_alacarte_item">
              <span className="accordion_alacarte_item_text">
                {channel?.title}
              </span>
              <span
                onClick={() =>
                  dispatch(
                    setThemePacks({
                      action: "del",
                      value: channel,
                    })
                  )
                }
                className="accordion_alacarte_item_close"
              >
                <AlacarteCloseBtn />
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {/* ACCORDION CONTENT */}
      <AnimatePresence initial={false}>
        {accordionOpen && (
          <>
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.25, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div className="accordion_devider_line_title" />
              <div className="accordion_content_wrapper">{children}</div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
