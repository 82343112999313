import React from "react";
import "components/Modals/Commercial/Commercial.scss";
import Button from "components/reusable/Button/Button";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import AddressModal from "components/Modals/Address/Address";
import { useLocation } from "react-router";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const CommercialModal = () => {
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);
  const dispatch = useDispatch();

  const handleReturn = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <AddressModal /> }));
  };

  const handleStart = () => {
    dispatch(toggleModal());
    window.location.replace('https://www.beanfield.com/business');
  };

  return (
    <div className="commercial-modal-container">
      <div className="commercial-container">
        {convertHtmlToReact(cmsData?.modal_commercial_header)}
        {convertHtmlToReact(cmsData?.modal_commercial_description)}
      </div>


      <div className="commercial-container">
        <Button click={() => handleStart()}>{cmsData?.modal_commercial_btn_text}</Button>

        <p onClick={() => handleReturn()} className="Wbold commercial-modal-text">
          {cmsData?.modal_commercial_change_address_link_text}
        </p>
      </div>
    </div>
  );
};

export default CommercialModal;
