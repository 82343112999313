export const LangOptions = [
  { value: "en", label: "English" },
  { value: "fr", label: "Français" },
];

export const ProvinceOptions = [
  { value: "tor", label: "Toronto/GTA" },
  { value: "mtl", label: "Montréal Area" },
  { value: "van", label: "Metro Vancouver" },
];

export const FRProvinceOptions = [
  { value: "tor", label: "Région de Toronto" },
  { value: "mtl", label: "Région de Montréal" },
  { value: "van", label: "Région de Vancouver" },
];
