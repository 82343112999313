import React from "react";
import "components/Badges/Badges.scss";
import Badge from "components/Badges/Badge";

const BadgesContainer = ({ badges }) => {
  return (
    <div className="homepage-badge-container">
      {badges?.map((badge, i) => {
        return <Badge key={i} badge={badge} />;
      })}
    </div>
  );
};

export default BadgesContainer;
