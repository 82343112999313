import "components/OrderPage/Accordions/MobileContents.scss";

// MODAL CONTENTS SHARE A LOT OF THE SAME CSS SO I PUT THEM IN ONE SCSS FILE WITH SAME CLASSES THAT THEY SHARE
export const modalContents = {
  starterPackage: (starter) => (
    <div className="modal_container_mobile">
      <div className="header_container">
        <h3>Starter TV Package Channel List</h3>
      </div>
      <div className="header_line" />
      <div className="modalContents">
        {starter?.map((channel, index) => {
          return (
            <div className="channel_container" key={index}>
              <div className="channel_content">
                <div>
                  <img
                    className="channel_icon"
                    src={channel?.logoUrl}
                    alt={channel?.title ? channel.title : channel.name}
                  />
                </div>
                <p className="channel_name">
                  {channel?.title ? channel.title : channel.name}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ),
  essentialPackage: (essentials) => (
    <div className="modal_container_mobile">
      <div className="header_container">
        <h3>Essentials TV Package Channel List</h3>
      </div>
      <div className="header_line" />
      <div className="modalContents">
        {essentials?.map((channel, index) => {
          return (
            <div className="channel_container" key={index}>
              <div className="channel_content">
                <div>
                  <img
                    className="channel_icon"
                    src={channel?.logoUrl}
                    alt={channel?.title ? channel.title : channel.name}
                  />
                </div>
                <p className="channel_name">
                  {channel?.title ? channel.title : channel.name}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ),
  longDistancePackage: (essentials, cmsData) => {
    return (
      <div className="modal_container_mobile">
        <div className="header_container">
          <h3>{cmsData.modal_international_long_distance_header}</h3>
        </div>
        <div className="header_line" />
        <div className="modalContents_longDistance">
          {essentials?.map((channel, index) => {
            const width =
              window.innerWidth ||
              document.documentElement.clientWidth ||
              document.body.clientWidth;
            const isMobile = width < 768;
            if (isMobile && index === 0) return null;
            return (
              <div className="longDistance_content_container">
                <div className="longDistance_content">
                  <p
                    className={index === 0 ? "Wbold" : ""}
                    style={{ flex: "0.50" }}
                  >
                    {isMobile ? (
                      <strong>{channel?.country}</strong>
                    ) : (
                      channel?.country
                    )}
                  </p>
                  <p
                    className={index === 0 ? "Wbold" : ""}
                    style={{ flex: "0.50" }}
                  >
                    ${channel?.landline}/min
                  </p>
                  <p
                    className={index === 0 ? "Wbold" : ""}
                    style={{
                      flex: "0.20",
                    }}
                  >
                    ${channel?.mobile}/min
                  </p>
                </div>
                <div className="longDistance_line" />
              </div>
            );
          })}
        </div>
      </div>
    );
  },
  themePacks: (themePacks, title) => {
    return (
      <div className="modal_container_mobile">
        <div className="header_container">
          <h3>{title}</h3>
        </div>
        <div className="header_line" />
        <div className="modalContents">
          {themePacks?.map((channel, index) => {
            return (
              <div className="channel_container" key={index}>
                <div className="channel_content">
                  <div>
                    <img
                      className="channel_icon"
                      src={channel?.logoUrl}
                      alt={channel?.title ? channel?.title : channel?.name}
                    />
                  </div>
                  <p className="channel_name">
                    {channel?.title ? channel?.title : channel?.name}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
  alaCarte: (channels) => {
    return (
      <div className="modal_container_mobile">
        <div className="modalContents">
          {channels?.map((channel) => {
            return (
              <div className="channel_container">
                <div className="channel_content">
                  <div>
                    <img
                      className="channel_icon"
                      src={channel?.logoUrl}
                      alt={channel?.name}
                    />
                  </div>
                  <p className="channel_name">{channel?.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
};
