import React, { useState } from "react";
import "components/VideoPlayer/VideoPlayer.scss";
import ReactPlayer from "react-player";
import Play from "assets/Icons/Play.png";
import { MediaUrl } from "utils/link";

const VideoPlayer = ({ text, url }) => {
  const [overlay, setOverlay] = useState(true);
  const [playing, setPlaying] = useState(true);

  const handleClick = () => {
    setOverlay(!overlay);

    if (!overlay) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  };

  return (
    <div className="video-player">
      <div onClick={() => handleClick()} className="video-overlay">
        <p
          className="video-player-overlay-text"
          style={{ opacity: overlay ? 1 : 0 }}
        >
          {text}
        </p>
        <img
          src={Play}
          style={{ opacity: overlay ? 1 : 0 }}
          alt={"play button"}
        />
        <div style={{ opacity: overlay ? 0.7 : 0 }} className="overlay-bg" />
      </div>

      <ReactPlayer
        height="100%"
        width="100%"
        muted={overlay}
        playing={playing}
        loop
        controls
        url={url}
      />
    </div>
  );
};

export default VideoPlayer;
