import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.479 19.5 4.5 14.574l3.01-4.242 2.737 1.778 5.474-7.663L20.1 7.595 11.479 19.5Z"
      fill="#325a32"
    />
  </svg>
);

export default SvgComponent;
