import React, { useState } from "react";
import "components/Search/SearchBar/SearchInput.scss";
import SearchGlass from "assets/svg/Search";
import { SearchResultData } from "mock/SearchResult";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

const toSearchPage = ({ lang, region, query }) => {
  let link = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port !== 80) link += `:${window.location.port}`;
  return `${link}/${lang}/${region}/search?search=${query}`;
};

const SearchInput = ({ hamburger }) => {
  const [searchBar, setSearchBar] = useState("");
  const [_, lang, region] = useLocation().pathname.split("/");

  const navigate = useNavigate();

  const handleLink = () => {
    setSearchBar("");
  };

  return (
    <div
      className="search-bar-container"
      aria-pressed={hamburger ? true : false}
    >
      <SearchGlass
        className="search-bar-icon"
        color={hamburger ? "black" : "white"}
      />
      <input
        value={searchBar}
        onChange={(e) => setSearchBar(e.target.value)}
        placeholder="Search"
        className="search-bar"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLink();
            window.location.href = toSearchPage({
              lang,
              region,
              query: searchBar,
            });
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
