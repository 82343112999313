import dayjs from "dayjs";

export const getCalendarMatrix = (date) => {
  // START/END OF A MONTH
  const startOfMonth = dayjs(date).startOf("month");
  const endOfMonth = dayjs(date).endOf("month");
  // START/END OF A CALENDAR THAT WILL BE DISPLAYED
  const startOfCalendar = startOfMonth.startOf("week");
  const endOfCalendar = endOfMonth.endOf("week");

  const calendarMatrix = [];
  let week = [];
  let day = startOfCalendar;

  while (day.isBefore(endOfCalendar)) {
    // PUSHING DAYS INTO CURRENT WEEK
    week.push(day);
    day = day.add(1, "day");

    // PUSHING NEW WEEK WHEN DAY GETS TO SUNDAY
    if (day.day() === 0) {
      calendarMatrix.push(week);
      week = [];
    }
  }

  return calendarMatrix;
};
