import { useEffect, useState } from "react";

const useGetBuildings = () => {
  const [buildings, setBuildings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //set 15 min timer to refresh data
  const localCacheTimer = 15 * 60 * 1000;

  //FETCHING BUILDINGS
  useEffect(() => {
    getBuildings();
  }, []);

  //GET BUILDINGS REQUEST
  const getBuildings = async () => {
    setIsLoading(true);
    //TRYING TO GET ITEMS FROM SESSION STORAGE
    if (sessionStorage.getItem("buildings")) {
      const localBuildings = JSON.parse(sessionStorage.getItem("buildings"));
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - localBuildings.timestamp;
      //SETTING ITEMS FROM STORAGE IF TIME DIFFERENCE IS LESS THAN 15 MINUTES
      if (timeDiff < localCacheTimer) {
        setBuildings(localBuildings.buildings);
        setIsLoading(false);
        return;
      }
    }

    //FETCHING ITEMS FROM API IF NOT ITEMS IN STORAGE
    await fetch(
      `${process.env.REACT_APP_BEANFIELD_API_URL}/v2/residential-buildings`,
      {
        method: "GET",
      }
    )
      .then(async (res) => {
        const response = await res.json();
        if (response?.total > 0) {
          const transformedBuildings = response?.buildings.map((building) => {
            building.addresses.forEach((element) => {
              element.lat = building.latitude;
              element.lng = building.longitude;
              element.buildingId = building.buildingId;
              element.status = building.status;
            });

            return building;
          });
          setBuildings(transformedBuildings);
          sessionStorage.setItem(
            "buildings",
            JSON.stringify({
              buildings: transformedBuildings,
              timestamp: new Date().getTime(),
            })
          );
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    buildings,
    isLoading,
  };
};

export default useGetBuildings;
