import React from "react";
import "components/Footer/Footer.scss";
import TwitterIcon from "assets/socialIcons/Twitter";
import FacebookIcon from "assets/socialIcons/Facebook";
import InstagramIcon from "assets/socialIcons/Instagram";
import LinkedinIcon from "assets/socialIcons/Linkedin";
import MainLogo from "assets/svg/MainLogo";
import "utils/variables.scss";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { internalUrlReaderWithLangReg } from "utils/wagtail";

import { useLocation } from "react-router-dom";

const Column = ({ header, items }) => {
  const { pathname } = useLocation();
  const serializeLink = (linkItem) => {
      return linkItem.is_internal? `/${internalUrlReaderWithLangReg(linkItem.link, pathname)}` : linkItem.link;
  };
  return (
    <div className="footer-links-item">
      <h2 tabIndex="0">{header}</h2>

      {items.map((item, index) => {
        return (
          <a
            href={serializeLink(item)}
            key={index}
            target={item.is_internal? "_self": "_blank"}
            tabIndex="0"
          >
            {item.text}
          </a>
        );
      })}
    </div>
  );
};

const Footer = () => {
  const { pathname } = useLocation();
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetMainFooterQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );
  let baseLink = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port !== 80) baseLink += `:${window.location.port}`;
  baseLink += "/" + pathname.split("/")[1] + "/" + pathname.split("/")[2];

  return (
    <div className="footer-container resi-container">
      <div className="resi-margin-container">
        <hr className="green-line"/>
        <div className="footer-flex">
          <a
            className="footer-logo-container"
            tabIndex="0"
            href={baseLink}
          >
            <MainLogo />
          </a>

          <div className="footer-links-container">
            {info?.col_1_header && info?.column_1?.length > 0 && (
              <Column header={info?.col_1_header} items={info?.column_1} />
            )}

            {info?.col_2_header && info?.column_2?.length > 0 && (
              <Column header={info?.col_2_header} items={info?.column_2} />
            )}

            {info?.col_3_header && info?.column_3?.length > 0 && (
              <Column header={info?.col_3_header} items={info?.column_3} />
            )}

            {info?.col_4_header && info?.column_4?.length > 0 && (
              <Column header={info?.col_4_header} items={info?.column_4} />
            )}
          </div>
        </div>

        <div className="footer-copyright-container">
          <p>{info?.copyright_txt}</p>

          <div
            className="footer-border-top"
            style={{
              background: "#000",
              width: "100%",
              height: 2,
              marginTop: 16,
              marginBottom: 16,
            }}
          />

          <div className="footer-social-container">
            <a
              target="_blank"
              href="https://twitter.com/beanfieldcanada"
              className="social-icon"
            >
              <TwitterIcon />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/beanfieldcanada"
              className="social-icon"
            >
              <FacebookIcon />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/beanfieldcanada/"
              className="social-icon"
            >
              <InstagramIcon />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/beanfieldcanada/"
              className="social-icon"
            >
              <LinkedinIcon />
            </a>
          </div>
        </div>

        <div
          className="footer-border-bottom"
          style={{
            background: "#000",
            width: "100%",
            height: 2,
            marginTop: 16,
            marginBottom: 16,
          }}
        />
      </div>
    </div>
  );
};

export default Footer;
