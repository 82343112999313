import { createApi } from "@reduxjs/toolkit/query/react";
import { baseBeanfieldQuery } from "api/config";

export const AccessibilityApi = createApi({
    reducerPath: "AccessibilityApi",
    baseQuery: baseBeanfieldQuery,
    tagTypes: ['submit'],
    endpoints: builder => ({
        submitSurvey: builder.mutation({
            query: ({ survey }) => {
                return {
                    url: '/v2/accessibility/submit',
                    method: "POST",
                    body: survey,
                    providesTags: ['submit']
                }
            }
        })
    })
});

export const {
    useSubmitSurveyMutation
} = AccessibilityApi;