import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="64"
    height="64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.3335 37.3333H39.2269L38.4802 36.6133C41.6802 32.88 43.3335 27.7866 42.4269 22.3733C41.1735 14.96 34.9869 9.03996 27.5202 8.13329C16.2402 6.74663 6.74687 16.24 8.13354 27.52C9.0402 34.9866 14.9602 41.1733 22.3735 42.4266C27.7869 43.3333 32.8802 41.68 36.6135 38.48L37.3335 39.2266V41.3333L48.6669 52.6666C49.7602 53.76 51.5469 53.76 52.6402 52.6666C53.7335 51.5733 53.7335 49.7866 52.6402 48.6933L41.3335 37.3333ZM25.3335 37.3333C18.6935 37.3333 13.3335 31.9733 13.3335 25.3333C13.3335 18.6933 18.6935 13.3333 25.3335 13.3333C31.9735 13.3333 37.3335 18.6933 37.3335 25.3333C37.3335 31.9733 31.9735 37.3333 25.3335 37.3333Z"
      fill="black"
      fillOpacity="0.64"
    />
    <path
      d="M26.216 22.272C26.216 22.928 26.744 23.456 27.384 23.456C28.024 23.456 28.536 22.928 28.536 22.272C28.536 21.632 28.024 21.12 27.384 21.12C26.744 21.12 26.216 21.632 26.216 22.272ZM20.888 22.272C20.888 22.928 21.416 23.456 22.056 23.456C22.696 23.456 23.224 22.928 23.224 22.272C23.224 21.632 22.696 21.12 22.056 21.12C21.416 21.12 20.888 21.632 20.888 22.272ZM18.696 29.8581C20.536 27.9701 22.552 26.8821 25.352 26.8821C28.152 26.8821 30.168 27.9701 32.008 29.8581L32.808 29.0101C31.096 27.0421 28.776 25.5061 25.352 25.5061C21.928 25.5061 19.624 27.0421 17.912 29.0101L18.696 29.8581Z"
      fill="#5C5C5C"
    />
  </svg>
);

export default SvgComponent;