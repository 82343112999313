import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  localization: "en",
  region: "tor",
};

export const localizationSlice = createSlice({
  name: "localization",
  initialState,
  reducers: {
    setLocalization: (state, action) => {
      state.localization = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocalization, setRegion } = localizationSlice.actions;

export default localizationSlice.reducer;
