import React from "react";
import "components/Badges/Badges.scss";
import { WagtailImage } from "components/Wagtail/WagtailImage";

const BadgesContainer = ({ badge }) => {
  return (
    <div className="homepage-badge" tabIndex="0">
      <WagtailImage image={badge.image} />
      <h3>{badge.title}</h3>
      <p>{badge.description}</p>
    </div>
  );
};

export default BadgesContainer;
