import React, { useEffect, useState } from "react";
import "pages/ChannelLineup/ChannelLineup.scss";
import TableRow from "components/TableRow/TableRow";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import {
  useGetChannelListQuery,
  useLazyGetChannelListQuery,
} from "api/beanfield/ChannelList";
import { WagTailDOMString } from "components/reusable/Link/PageLink/PageLink";
import Sort from "assets/svg/Sort";
import Head from "components/reusable/Head/Head";

const ChannelLineup = () => {
  const [channelList, setChannelList] = useState(null);
  const [sortName, setSortName] = useState(false);
  const [sortBasic, setSortBasic] = useState(false);
  const [sortEssential, setSortEssential] = useState(false);
  const [sortCarte, setSortCarte] = useState(false);
  const [sortNumber, setSortNumber] = useState(true);
  const [sortTheme, setSortTheme] = useState(false);
  const [getChannelList] = useLazyGetChannelListQuery();
  const { page: info, isLoading, isError, error } = usePagesApiParser(getQueries(
    useLocation().search
  ).useGetChannelLineupPageQuery({
    queryParams: useLocation().search,
    localePath: useLocation().pathname.split("/")[1],
    region: useLocation().pathname.split("/")[2],
  }));


  const makeChannelList = () => {
    if (navigator.userAgent !== 'ReactSnap') {
      getChannelList().then((res) => {
        let tempChannels = res.data.channels;

        if (sortName) {
          let tempArr = [...tempChannels];
          let newArr = tempArr.reverse();
          setChannelList(newArr);
        } else if (sortBasic) {
          let tempArr = [...tempChannels].sort((a, b) => b.isSkinny - a.isSkinny);

          setChannelList(tempArr);
        } else if (sortEssential) {
          let tempArr = [...tempChannels].sort(
            (a, b) => b.isEssentials - a.isEssentials
          );

          setChannelList(tempArr);
        } else if (sortCarte) {
          let tempArr = [...tempChannels].sort(
            (a, b) => b.isAlaCarte - a.isAlaCarte
          );

          setChannelList(tempArr);
        } else if (sortNumber) {
          let tempArr = [...tempChannels].sort(
            (a, b) => a.channelNumber - b.channelNumber
          );

          setChannelList(tempArr);
        } else if (sortTheme) {
          let cleanArr = [];
          let emptyArr = [];
          let tempArr = [...tempChannels].sort((a, b) => {
            a = a.themePacks[0] || "";
            b = b.themePacks[0] || "";

            return a.localeCompare(b);
          });

          tempArr.map((item) => {
            if (item.themePacks.length === 0) {
              emptyArr.push(item);
            } else {
              cleanArr.push(item);
            }
          });

          emptyArr.map((item) => {
            cleanArr.push(item);
          });

          setChannelList(cleanArr);
        } else {
          setChannelList(tempChannels);
        }
      });
    }
  };

  const handleSort = (value) => {
    setSortBasic(false);
    setSortEssential(false);
    setSortName(false);
    setSortCarte(false);
    setSortNumber(false);
    setSortTheme(false);

    switch (value) {
      case "name":
        setSortName(true);
      case "basic":
        setSortBasic(true);
      case "essential":
        setSortEssential(true);
      case "carte":
        setSortCarte(true);
      case "number":
        setSortNumber(true);
      case "theme":
        setSortTheme(true);
    }
  };

  useEffect(() => {
    makeChannelList();
  }, [sortName, sortBasic, sortEssential, sortCarte, sortNumber, sortTheme]);

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield Télévision | Gamme des chaînes télévision" :
          "Beanfield TV | Channel Lineup"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Retrouvez vos chaînes préférées et découvrez-en de nouvelles. Explorez l'offre télévisuelle de Beanfield avec une grande variété de divertissements." :
          "Find your favourite channels & discover new ones. Explore Beanfield's TV lineup with a wide variety of entertainment"
        }
        imageName={"ChannelLineup"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="channel-lineup-container">
            <div className="channel-header-container">
              <h1>{info?.header}</h1>

              <p>{info?.body}</p>

              <WagTailDOMString DOMString={info?.body_subtext} />
            </div>
            <div className="table-content">
              <table>
                <tr>
                  <th onClick={() => handleSort(sortName ? null : "name")}>
                    Channel Name
                    <span>
                <Sort />
              </span>
                  </th>

                  <th onClick={() => handleSort(sortNumber ? null : "number")}>
                    Channel Number
                    <span>
                <Sort />
              </span>
                  </th>

                  <th onClick={() => handleSort(sortBasic ? null : "basic")}>
                    Skinny Basic
                    <span>
                <Sort />
              </span>
                  </th>

                  <th onClick={() => handleSort(sortEssential ? null : "essential")}>
                    Essentials
                    <span>
                <Sort />
              </span>
                  </th>

                  <th onClick={() => handleSort(sortCarte ? null : "carte")}>
                    A la Carte
                    <span>
                <Sort />
              </span>
                  </th>

                  <th onClick={() => handleSort(sortTheme ? null : "theme")}>
                    Theme Packs
                    <span>
                <Sort />
              </span>
                  </th>
                </tr>

                <TableRow list={channelList} />
              </table>
            </div>

            {info?.contact_us_page?.badges?.length > 0 && (
              <ContactInfo
                title={info.contact_us_page.title}
                description={info.contact_us_page.description}
                badges={info.contact_us_page.badges}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelLineup;
