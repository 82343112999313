import React, { useEffect, useRef, useState } from "react";
import "components/reusable/Tooltip/Tooltip.scss";

/**
 *
 * @param {object} containerStyles optional style for main container
 * @param {object} helperStyles optional style for helper element
 * @param {object} contentStyles optional style for content/children element
 * @param {object} iconStyles optional style for the icon element
 * @returns {JSX.Element} Tooltip component with icon and content
 */

const ToolTip = ({
  children,
  helperStyles = {},
  contentStyles = {},
  containerStyles = {},
  iconStyles = {},
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const iconRef = useRef();
  const iconX = useRef();

  useEffect(() => {
    if (!('override' in contentStyles)) {
      if (!iconX.current) {
        iconX.current = iconRef.current.getBoundingClientRect().x;
      }
      contentStyles.top = 0;
      if (iconX.current > 400) {
        contentStyles.right = 25;
        contentStyles.left = "unset";
        helperStyles.left = -15;
      } else {
        contentStyles.left = 25;
        contentStyles.right = "unset";
        helperStyles.left = 15;
      }
    }
  }, [tooltipOpen, contentStyles]);

  return (
    <div
      style={{
        display: "inline-flex",
        position: "relative",
        ...containerStyles,
      }}
    >
      <svg
        width={16}
        height={16}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ref={iconRef}
        onMouseOver={() => {
          setTooltipOpen(true);
        }}
        onMouseLeave={() => {
          setTooltipOpen(false);
        }}
        style={{ ...iconStyles }}
        className="accordion_info_icon"
      >
        <path
          d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm1.508 13.215c-.511.72-1.022 1.215-1.573 1.492a2.719 2.719 0 0 1-1.282.274c-1.163-.096-1.703-.973-1.196-2.366l1.529-4.2c.33-.906.01-1.182-.252-1.19-.31-.005-.683.287-1.116.876a.23.23 0 0 1-.376-.267c.51-.72 1.022-1.215 1.573-1.491a2.72 2.72 0 0 1 1.282-.275c1.162.097 1.703.973 1.195 2.367l-1.528 4.2c-.33.906-.01 1.181.251 1.188.312.006.684-.286 1.117-.875a.23.23 0 0 1 .376.267Zm1.226-8.742a1.087 1.087 0 1 1-2.042-.743 1.087 1.087 0 0 1 2.042.743Z"
          fill="#325a32"
        />
      </svg>
      {tooltipOpen ? (
        <div>
          <div
            style={{
              ...helperStyles,
            }}
            className="tooltip_helper_element"
            onMouseOver={() => {
              setTooltipOpen(true);
            }}
            onMouseLeave={() => {
              setTooltipOpen(false);
            }}
          />
          <div
            style={{
              ...contentStyles,
            }}
            onMouseOver={() => {
              setTooltipOpen(true);
            }}
            onMouseLeave={() => setTooltipOpen(false)}
            className="tooltip_content"
          >
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ToolTip;
