import React from "react";
import "pages/InternetSpeeds101/InternetSpeeds101.scss";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { getQueries } from "api/wagtailAdapter";
import { useLocation } from "react-router-dom";
import Head from "components/reusable/Head/Head";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";


const InternetSpeedsWorkItems = ({item, index}) => {
  return (
    <div className={`internet-speeds-work-item-${index}`}>
      <div className="internet-speeds-work-content">
        {convertHtmlToReact(item.body_txt)}
      </div>
      <div className="internet-speeds-work-image">
        <WagtailImage  image={item?.image}/>
      </div>
    </div>
  );
};


const TestingInternetSpeedItems = ({item}) => {
  return (
    <div className="testing-internet-speed-item">
      <div className="testing-internet-speed-number-image">
        <WagtailImage  image={item?.circle_number_image}/>
        <div className="line">

        </div>
      </div>
      <div className="testing-internet-speed-item-content">
        <div className="testing-internet-speed-description">
          {convertHtmlToReact(item.description)}
        </div>
        <div className="testing-internet-speed-image">
          <WagtailImage  image={item?.image}/>
        </div>
      </div>

    </div>
  );
};


const InternetSpeeds101 = () => {
  const {
    page: info,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetInternetSpeeds101PageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  return (
    <>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Vitesse internet 101" :
          "Internet Speeds 101"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "En savoir plus sur les vitesses Internet, l'utilisation courante et les exigences en matière de tests." :
          "Learn about Internet speeds, common usage and testing requirements."
        }
        imageName={"InternetSpeeds101"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="internet-speeds-101-header">
            {convertHtmlToReact(info?.header_title)}
            {convertHtmlToReact(info?.header_description)}
          </div>
        </div>
      </div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="how-is-internet-measured-container">
            <div className="left-content">
              <h2>{info?.how_is_internet_speed_measured_title_txt}</h2>
              {convertHtmlToReact(info?.how_is_internet_speed_measured_description_txt)}
            </div>
            <div className="right-content Wbold">
              {convertHtmlToReact(info?.how_is_internet_speed_measured_1000_mbps_txt)}
            </div>
          </div>
        </div>
      </div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="how-internet-speed-works-container">
            <div className="how-internet-speed-works-header">
              <h2>{info?.how_internet_speed_works_header}</h2>
            </div>
            <div className="how-internet-speed-works-content">
              {info?.how_internet_speed_works.map((item, index) => {
                return <InternetSpeedsWorkItems item={item} index={index}/>
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="testing-internet-speed-container">
            <div className="testing-internet-speed-header">
              <h2>{info?.testing_your_internet_speed_title}</h2>
              {convertHtmlToReact(info?.testing_your_internet_speed_description)}
            </div>
            <div className="testing-internet-speed-content">
              {info?.testing_internet_speeds.map((item, index) => {
                return <TestingInternetSpeedItems item={item} key={index}/>
              })}
            </div>
            <div className="testing-internet-speed-bottom Wbold">
              {convertHtmlToReact(info?.testing_your_internet_speed_bottom_txt)}
            </div>
          </div>
        </div>
      </div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="how-much-internet-you-need-container">
            <div className="how-much-internet-you-need-header">
              <h2>{info?.how_much_speed_you_need_title}</h2>
              {convertHtmlToReact(info?.how_much_speed_you_need_description)}
            </div>
            <div className="how-much-internet-you-need-image">
              <WagtailImage  image={info?.how_much_speed_you_need_image}/>
            </div>

          </div>
        </div>
      </div>
      <div className="more-speed-than-you-need-container">
        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="more-speed-than-you-need-content">
              <h2>{info?.more_speed_than_you_need_header}</h2>
              {convertHtmlToReact(info?.more_speed_than_you_need_body_txt)}
              <PageLink bold arrow={2} target="_blank" to={info?.more_speed_than_you_need_link}>
                {info?.more_speed_than_you_need_link_txt}
              </PageLink>
            </div>
          </div>
        </div>
      </div>

      {info?.contact_us_page?.badges?.length > 0 && (
        <ContactInfo
          title={info.contact_us_page.title}
          description={info.contact_us_page.description}
          badges={info.contact_us_page.badges}
        />
      )}
    </>
  );
};

export default InternetSpeeds101;