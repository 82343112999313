import React, { useEffect, useState } from "react";
import "components/reusable/Checkbox/Checkbox.scss";
import CheckBoxMark from "assets/svg/CheckBoxMark";
import useWindowDimensions from "utils/WindowWidth";

const Checkbox = ({
  onClick = false,
  order = false,
  title,
  channel = "",
  theme = "",
  classStyle =  "",
  type,
  isDisabled = false,
  ariaLabel = "",
}) => {
  const [checked, setChecked] = useState(false);
  // const { width } = useWindowDimensions();

  //CHECKING IF THE ORDER HAS A PRICE GREATER THAN 0
  //THEN WE TURN THE CHECKBOX ON (when user clicked on the radio btn)

  useEffect(() => {
    switch (type) {
      case "internet":
        setChecked(true);
        break;
      case "tv":
        setChecked(order.tv.price > 0);
        break;
      case "phone":
        setChecked(order.homePhone.price > 0);
        break;
      default:
        setChecked(false);
    }
    if (channel) {
      const channelSelected = order?.tv?.channels?.selected?.find(
        (item) => item.title === channel
      );
      if (channelSelected) setChecked(true);
      else setChecked(false);
    }
    if (theme) {
      const themeSelected = order?.tv?.themePacks?.selected?.find(
        (item) => item.title === theme
      );
      if (themeSelected) setChecked(true);
      else setChecked(false);
    }
  }, [order, order?.tv?.channels?.selected, order?.tv?.themePacks?.selected]);

  return (
    <div
      // style={{ width: width < 768 ? "1.125rem" : "1.125rem" }}
      className={`checkbox_container ${classStyle}`}
    >
      <input
        checked={checked}
        disabled={isDisabled}
        onChange={(e) => {
          if (onClick && !checked) {
            onClick(true);
            setChecked(true);
          } else {
            setChecked(false);
            onClick(false);
          }
        }}
        type="checkbox"
        style={{ opacity: checked ? 0 : 1, padding: 0, margin: 0 }}
        aria-label={ ariaLabel }
      />

      {checked ? (
        <span className="checkmark">
          <CheckBoxMark />
        </span>
      ) : null}
    </div>
  );
};

export default Checkbox;
