import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';


const Week = ({ calendarMonthLoaded }) => {
  const { localization } = useSelector((state) => state.localizationSlice);
  const daysOfWeek = [
    { en: "Sun", fr: "Dim" },
    { en: "Mon", fr: "Lun" },
    { en: "Tue", fr: "Mar" },
    { en: "Wed", fr: "Mer" },
    { en: "Thu", fr: "Jeu" },
    { en: "Fri", fr: "Ven" },
    { en: "Sat", fr: "Sam" },
  ];
  return (
   <>
    <div className="weekDay_container">
      {daysOfWeek.map((day, index) => (
        <div key={index} className="weekDay">
          {day[localization]}
        </div>
      ))}
    </div>
     { calendarMonthLoaded ?  null : <div><LinearProgress color="success" /></div>}
  </>
  );
};

export default Week;
