import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { renderMarkers } from "utils/availabilityMap/renderMapMarkers";
import { mapStyles } from "utils/availabilityMap/availabilityMapStyles";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";
import SuccessModal from "components/Modals/Success/Success";
import useWindowDimensions from "utils/WindowWidth";
import { useResetStateAndSetAddress } from "services/hooks/useResetStateAndSetAddress";
import FormModal from "components/Modals/Form/Form";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import SpecialOfferModal from "components/Modals/SpecialOffer/SpecialOffer";

//THIS COMPONENT RESPONSIBLE FOR MAP INITIALIZATION AND MARKER RENDERING
//IT ALSO HAS getModal FUNCTION THAT DISPATCHES MODALS WHEN CLICKING ACTION BUTTONS INSIDE INFOWINDOWS
const useRenderMap = (
  buildings,
  selectedForFilter,
  setSelectedForFilter,
  setDropdownOpen,
  setSelectedAddressForService,
  selectedAddressForService,
  cmsData
) => {
  const [activeMap, setActiveMap] = useState("Toronto/GTA");
  const mapList = {
    tor: cmsData?.tab_items[0]?.title || "Toronto/GTA",
    van: cmsData?.tab_items[1]?.title || "Metro Vancouver",
    gat: cmsData?.tab_items[2]?.title || "Gatineau",
  };
  const [mapCenter, setMapCenter] = useState({
    tor: { lat: 43.6536032, lng: -79.3840054670334 },
    van: { lat: 49.28125, lng: -123.120417 },
    gat: { lat: 45.430611, lng: -75.72015 },
    // mtl: {lat:, lng:},
  });
  const { resetStateAndSetAddress } = useResetStateAndSetAddress();
  const [mapInstance, setMapInstance] = useState(null);
  const [address, setAddress] = useState(null);
  const openInfoWindowRef = useRef(null);
  const mapRef = useRef(null);
  const dispatch = useDispatch();

  //Dispatch success modal when button clicked inside info window
  const getModal = (selectedForFilter) => {
    const status = selectedForFilter?.status === "On-Net";
    const address = selectedForFilter?.addresses ? selectedForFilter?.addresses[0] : selectedForFilter;

    if (status) {
      if (address.isSpecialBuilding) {
        dispatch(
          toggleModal({
            children: (
              <SpecialOfferModal buildingName={address.specialBuildingName} />
            ),
          })
        );
        sendGoogleTagEvent({ eventName: "Specific Building" });
      } else {
        dispatch(
          toggleModal({
            children: (
              <SuccessModal
                address={address}
                province={address?.province?.toLowerCase() || "on"}
              />
            ),
          })
        );
        sendGoogleTagEvent({eventName: "On-Net"});
      }
    } else {
      dispatch(
        toggleModal({
          children: (
            <FormModal pending editAddress={address} unit={""} />
          ),
        })
      );
      sendGoogleTagEvent({ eventName: "fbip" });
    }
  };

  //RESET STATE AND SET ADDRESS ON MARKER CLICK
  useEffect(() => {
    if (selectedForFilter && !selectedAddressForService) {
      let defaultAddress = selectedForFilter;
      if (selectedForFilter?.addresses?.length > 0) {
        defaultAddress = selectedForFilter.addresses[0];
      }

      resetStateAndSetAddress({
        addressText: defaultAddress.addressText,
        addressId: defaultAddress.addressId,
        province: defaultAddress.province,
      });
    }
  }, [selectedForFilter, selectedAddressForService]);

  //MAP MARKERS RENDER IF BUILDINGS ARE FETCHED AND MAP INITIALISED
  useEffect(() => {
    if (buildings && mapInstance) {
      //Grabbing btns b4 the function call
      //So that we don't have to filter it inside for as many times as there are buildings
      const availablePin = cmsData?.pin_types[0];
      const comingSoonPin = cmsData?.pin_types[1];
      renderMarkers(
        mapInstance,
        buildings,
        openInfoWindowRef,
        selectedForFilter,
        setDropdownOpen,
        setSelectedForFilter,
        getModal,
        availablePin,
        comingSoonPin
      );
    }
  }, [buildings, mapInstance, selectedForFilter, cmsData]);
  const { width } = useWindowDimensions();

  //MAP INITIALIZATION
  useEffect(() => {
    const initMap = async () => {
      const loader = new Loader({
        apiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
        version: "weekly",
      });
      const { Map } = await loader.importLibrary("maps");
      //get key of maplist if it matches active map
      const region = Object.keys(mapList).find(
        (key) => mapList[key] === activeMap
      );
      let center = mapCenter[region];

      // options (map controls, zoom, etc.)
      const mapOptions = {
        center,
        zoom: 13.2,
        disableDefaultUI: width < 768,
        styles: mapStyles,
      };

      // map
      const map = new Map(mapRef.current, mapOptions);
      setMapInstance(map);
    };

    if (buildings.length > 0) {
      if (mapRef.current) {
        initMap();
      }
    }
  }, [mapRef, activeMap, mapCenter, buildings]);

  return {
    openInfoWindowRef,
    mapRef,
    activeMap,
    setActiveMap,
    mapList,
    mapInstance,
    setMapInstance,
  };
};

export default useRenderMap;
