import { getQueries } from "api/wagtailAdapter";

export const useGetOrderPageData = (useLocation) => {
  let { data, isLoading, isError, error } = getQueries(
    useLocation().search
  ).useGetOrderPageQuery({
    queryParams: useLocation().search,
    localePath: useLocation().pathname.split("/")[1],
    region: useLocation().pathname.split("/")[2],
  });
  // the order page has child pages for each region in that case
  // data for the parent and the child are returned when the order process
  // is queried for that region
  if(data?.items){
    data = data?.items?.length > 1 ? data?.items[1] : data?.items[0];
  }
  return { data, isLoading, isError, error };
};
