import React from "react";
import "components/ChannelPackages/GoCard/GoCard.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import {Link} from "react-router-dom";

const GoCard = ({ card }) => {
  return (
    <div className="go-card">
      <div className="go-card-title-container">
        <WagtailImage image={card.image} width={220} height={88} />
        <Link to={card?.btn_link}>
          <p className="Wbold">{card?.btn_txt}</p>
        </Link>
      </div>

      <div className="go-card-list-container">
        <h2>{card?.channel_title}:</h2>
        <div className="go-card-list">{convertHtmlToReact(card?.body)}</div>
      </div>
    </div>
  );
};

export default GoCard;
