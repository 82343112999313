import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isStartOpen: true,
  startChildren: {},
};

export const startModalSlice = createSlice({
  name: "startModalControl",
  initialState,
  reducers: {
    offStart: (state, action) => {
      state.isStartOpen = false;
      if (action?.payload?.children)
        state.startChildren = action.payload.children;
    },
  },
});

// Action creators are generated for each case reducer function
export const { offStart } = startModalSlice.actions;

export default startModalSlice.reducer;
