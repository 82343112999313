import "pages/AvailabilityMap/AvailabilityMap.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvailabilityMapAvailable from "assets/svg/AvailabilityService_available";
import AvailabilityMapComing from "assets/svg/AvailabilityService_comingsoon";
import { CircularProgress } from "@mui/material";
import useGetBuildings from "services/hooks/useGetBuildings";
import useRenderMap from "services/hooks/useRenderMap";
import useSearch from "services/hooks/useMapSearch";
import MagnifyinGlass from "assets/svg/MagnifyinGlass";
import { useLocation } from "react-router-dom";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { getQueries } from "api/wagtailAdapter";
import { useResetStateAndSetAddress } from "services/hooks/useResetStateAndSetAddress";
import OffNet from "assets/availabilityMap/offnet.png";
import convertHtmlToReact from "@hedgedoc/html-to-react";
import { useState } from "react";
import { toggleModal } from "store/reducers/modal/modalSlice";
import FormModal from "components/Modals/Form/Form";
import Head from "components/reusable/Head/Head";

//MAIN (parent) COMPONENT FOR AVAILABILITY MAP
//OTHER PARTS OF THIS COMPONENT ARE IN /utils/availabilityMap AND /services/hooks
const AvailabilityMap = () => {
  const dispatch = useDispatch();
  let { region } = useSelector((state) => state.localizationSlice);
  const [unavailableBuilding, setUnavailableBuilding] = useState(null); //building that is not available
  const { resetStateAndSetAddress } = useResetStateAndSetAddress();
  const { buildings, isLoading } = useGetBuildings();
  const {
    page: cmsData,
    isLoading: cmsLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetAvailabilityMapQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  useEffect(() => {
    if (cmsData?.pin_types) {
      const unavailableBuilding = cmsData?.pin_types?.filter(
        (pin) => pin?.identifier === "not-available"
      )[0];
      setUnavailableBuilding(unavailableBuilding);
    }
  }, [cmsData]);

  //SEARCH
  let {
    searchTerm,
    setSearchTerm,
    handleSearchChange,
    handleKeyPress,
    dropdownOpen,
    setDropdownOpen,
    selectedForFilter,
    setSelectedForFilter,
    setSelectedAddressForService,
    selectedAddressForService,
    filteredBuildings,
    buildingOffline,
    setBuildingOffline,
  } = useSearch(buildings);
  const { mapRef, activeMap, setActiveMap, mapList, openInfoWindowRef } =
    useRenderMap(
      buildings,
      selectedForFilter,
      setSelectedForFilter,
      setDropdownOpen,
      setSelectedAddressForService,
      selectedAddressForService,
      cmsData
    );

  //set map center based on region TODO: in Montreal maybe render Montreal map?
  useEffect(() => {
    if (region === "mtl") {
      region = "gat";
    }
    setActiveMap(mapList[region]);
  }, [region]);
  //CLICK ON THE DROPDOWN ELEMENT
  const handleDropdownAddressClick = (e, address) => {
    if (
      address?.status === "Interested" ||
      address?.status === "Currently Not On-Net"
    ) {
      setBuildingOffline(true);
      //close windowref
      openInfoWindowRef.current?.close();
    } else {
      setBuildingOffline(false);
    }
    setDropdownOpen(false);
    setSelectedForFilter(address);
    setSelectedAddressForService(address?.addressText);
    handleKeyPress(e);
    resetStateAndSetAddress({
      addressText: address?.addressText,
      addressId: address?.addressId,
      province: address?.province,
    });
    setSearchTerm(address?.addressText);
  };

  useEffect(() => {
    if (
      filteredBuildings?.length === 0 &&
      searchTerm?.trim()?.length > 0 &&
      buildingOffline
    ) {
      openInfoWindowRef.current?.open();
    }
  }, [buildingOffline]);
  const notOnNetAddress = () => {
    if (typeof selectedAddressForService === "string") {
      return {
        addressText: selectedAddressForService,
      };
    }
  };

  return (
    <div className="availability_container">
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Zones de service | Beanfield" :
          "Availability Map | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Découvrez si Beanfield est disponible dans votre région ! Explorez notre carte de disponibilité et voyez si nous offrons des services à votre adresse." :
          "Find out if Beanfield is available in your area! Explore our availability map and see if we offer services at your address."
        }
        imageName={"AvailabilityMap"}
        locale={useLocation().pathname.split("/")[1]}
      />
      {navigator.userAgent !== 'ReactSnap' ? (
        <>
          <div className="availability_wrapper">
            {/* HEADER */}
            <div className="availability_header">
              {convertHtmlToReact(cmsData?.header)}
              {convertHtmlToReact(cmsData?.body)}
            </div>
            {/* MAP TOGGLES*/}
            <div className="availability_map_controls">
              {cmsData?.tab_items?.map((item, i) => {
                return (
                  <button
                    key={i}
                    className={activeMap === item?.title ? "selected" : ""}
                    onClick={(e) => {
                      setSelectedForFilter(null);
                      setActiveMap(e.target.value);
                    }}
                    value={item?.title}
                  >
                    {item?.title}
                  </button>
                );
              })}
            </div>
            {/* MAP */}
            <div className="availability_map_wrapper">
              {!isLoading ? (
                <div style={{ position: "relative" }}>
                  {/* INSTANCE OF THE MAP */}
                  {/* React snap breaks when trying to get the instance of the map so removing it on prerender */}
                  {navigator.userAgent !== 'ReactSnap' ? (
                    <div
                      onClick={() => {
                        setDropdownOpen(false);
                        setBuildingOffline(false);
                      }}
                      style={{ height: "600px" }}
                      ref={mapRef}
                    />
                  ) : null}
                  {/* SEARCH INPUT */}
                  <div className="availability_search">
                    <input
                      onKeyDown={(e) => handleKeyPress(e)}
                      type="text"
                      placeholder={cmsData?.search_placeholder}
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    {/* dropdown with filtered buildings max 5 clickable */}
                    {filteredBuildings.length > 0 && dropdownOpen ? (
                      <div className="availability_search_dropdown">
                        {filteredBuildings?.map((address, i) => {
                          return (
                            <div
                              key={i}
                              onClick={(e) =>
                                handleDropdownAddressClick(e, address)
                              }
                              value={address?.addressText}
                            >
                              {address?.addressText}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    <MagnifyinGlass
                      onClick={(e) => {
                        setDropdownOpen(true);
                        handleKeyPress(e, true);
                      }}
                    />
                  </div>
                  {/* MODAL FOR off-net buildings */}
                  {(filteredBuildings?.length === 0 ||
                    filteredBuildings[0]?.status === "Interested" ||
                    filteredBuildings[0]?.status === "Currently Not On-Net") &&
                  searchTerm?.trim()?.length > 0 &&
                  buildingOffline ? (
                    <div className="offnet_container">
                      <div className="offnet_window_container">
                        <h2 className="offnet_window">
                          {selectedAddressForService?.slice(0, 75)}
                        </h2>
                        <div className="offnet_window_service">
                          <img src={OffNet} alt="service image" />
                          <p style={{ marginLeft: "5px" }}>
                            {unavailableBuilding?.title}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            if (dropdownOpen) setDropdownOpen(false);
                            dispatch(
                              toggleModal({
                                children: (
                                  <FormModal
                                    editAddress={notOnNetAddress()}
                                    unit={""}
                                  />
                                ),
                              })
                            );
                          }}
                          className="offnet_window_button"
                        >
                          <p className="offnet_window_button_p">
                            {unavailableBuilding?.btn_text}
                          </p>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="availability_map_loading">
                  <CircularProgress />
                </div>
              )}
            </div>
            <div className="availability_map_marks">
              <div className="availability_marks">
                <AvailabilityMapAvailable />
                <p>{cmsData?.service_label_available}</p>
              </div>
              <div className="availability_marks">
                <AvailabilityMapComing />
                <p>{cmsData?.service_label_coming}</p>
              </div>
            </div>
          </div>
        </>
      ) : null }

    </div>
  );
};

export default AvailabilityMap;
