import React, { useEffect, useState } from "react";
import "components/OrderPage/StepThree/index.scss";
import Button from "components/reusable/Button/Button";
import AddPayment from "components/OrderPage/StepThree/AddPayment";
import PersonalDetails from "components/OrderPage/StepThree/PersonalDetails";
import { useDispatch, useSelector } from "react-redux";
import { stepMinus, stepPlus } from "store/reducers/order/orderSlice";
import useWindowDimensions from "utils/WindowWidth";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import { getCookie } from "utils/Cookies";

const YourInfo = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { firstName, lastName, phoneNumber, unit } = useSelector(
    (state) => state.orderSlice.userInfo
  );
  const [info, setInfo] = useState({
    firstName: firstName || "",
    lastName: lastName || "",
    phoneNumber: phoneNumber || "",
    unit: unit || "",
  });

  const isDataFilledOut = () => {
    if (info.firstName && info.lastName && info.phoneNumber && info.unit) {
      return true;
    } else {
      return false;
    }
  };
  const token = getCookie("token");

  //CMS DATA FETCH
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  return (
    <div className="stepThree_yourInfo_container">
      {/* PERSONAL DETAILS */}
      <PersonalDetails info={info} setInfo={setInfo} />

      {/*/!* CREDIT CARD INFO *!/*/}
      {/*<AddPayment />*/}

      {/* BUTTONS */}
      <div className="stepThree_btn_container">
        {width > 768 ? (
          <Button
            btnStyle={"beanfield-button--hollow Wbold"}
            click={() => dispatch(stepMinus())}
            style={{ width: "10.9375rem" }}
          >
            {data?.step_3_back_btn_txt}
          </Button>
        ) : null}
        <Button
          disabled={!isDataFilledOut()}
          btnStyle={"beanfield-button Wbold"}
          click={() => {
            dispatch(stepPlus());
            if (token) {
              sendGoogleTagEvent({ eventName: "Lucky Checkout Step 9" })
            } else {
              sendGoogleTagEvent({ eventName: "Checkout Step 9" })
            }
          }}
          style={{
            width: width > 750 ? "fit-content" : "100%",
            height: "2.5rem",
            padding: "0 2rem",
          }}
        >
          {data?.step_3_review_btn_txt}
        </Button>
      </div>
    </div>
  );
};

export default YourInfo;
