import { combineReducers } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { persistConfig } from "store/persist";
import { persistReducer } from "redux-persist";

//REDUCERS
import modalControl from "store/reducers/modal/modalSlice";
import startModalControl from "store/reducers/modal/startModalSlice";
import orderSlice from "store/reducers/order/orderSlice";
import surveySlice from "store/reducers/survey/surveySlice";
import localizationSlice from "store/reducers/localization";
//API CALLS RTK QUERY
import { Page } from "api/cms/live/Pages";
import { BusinessCampaign } from "api/cms/live/Pages";
import { Page as PageDraft } from "api/cms/drafts/Pages";
import { Media } from "api/cms/Media/Media";
import { InternetAvailabilityApi } from "api/beanfield/InternetAvailability";
import { OrderPageApi } from "api/beanfield/OrderPage";
import { SurveyPageApi } from "api/beanfield/SurveyPage";
import { ChannelListApi } from "api/beanfield/ChannelList";
import { AccessibilityApi } from "api/beanfield/Accessibility";
import { ContactFormApi } from "api/beanfield/ContactForm";
import { Search, SupportSearch } from "api/cms/live/Search";
import { SearchApi } from "api/beanfield/Search";
import { GeolocationApi } from "api/beanfield/Geolocation";
import { AvailabilityApi } from "api/beanfield/AvailabilityMap";

//COMBINE REDUCERS FOR PERSIST
const rootReducer = combineReducers({
  modalControl,
  startModalControl,
  orderSlice,
  surveySlice,
  localizationSlice,
  [Page.reducerPath]: Page.reducer,
  [PageDraft.reducerPath]: PageDraft.reducer,
  [Media.reducerPath]: Media.reducer,
  [InternetAvailabilityApi.reducerPath]: InternetAvailabilityApi.reducer,
  [OrderPageApi.reducerPath]: OrderPageApi.reducer,
  [SurveyPageApi.reducerPath]: SurveyPageApi.reducer,
  [ChannelListApi.reducerPath]: ChannelListApi.reducer,
  [AccessibilityApi.reducerPath]: AccessibilityApi.reducer,
  [ContactFormApi.reducerPath]: ContactFormApi.reducer,
  [SearchApi.reducerPath]: SearchApi.reducer,
  [Search.reducerPath]: Search.reducer,
  [GeolocationApi.reducerPath]: GeolocationApi.reducer,
  [SupportSearch.reducerPath]: SupportSearch.reducer,
  [AvailabilityApi.reducerPath]: AvailabilityApi.reducer,
  [BusinessCampaign.reducerPath]: BusinessCampaign.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

//CONFIGURE STORE FOR REDUX
export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      Page.middleware,
      PageDraft.middleware,
      Media.middleware,
      InternetAvailabilityApi.middleware,
      OrderPageApi.middleware,
      SurveyPageApi.middleware,
      ChannelListApi.middleware,
      AccessibilityApi.middleware,
      ContactFormApi.middleware,
      SearchApi.middleware,
      Search.middleware,
      GeolocationApi.middleware,
      SupportSearch.middleware,
      AvailabilityApi.middleware,
      BusinessCampaign.middleware
    ),
});
