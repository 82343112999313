import ReactGA from 'react-ga4';

function useGoogleTagPageViews() {
  ReactGA.initialize([
    {
      trackingId: 'G-9SF1JRSHPB'
    },
    {
      trackingId: 'G-BBFQDVR4KW'
    },
    {
      trackingId: 'GTM-TBP7R8'
    },
  ], {gaOptions: {cookieDomain: 'beanfield.com'}});
}

const EVENTS = {
  'On-Net': { // Added
    category: 'pcsearch_onnet',
    action: 'Address Search - On Net',
    label: 'Address Search - On-Net Success',
  },
  'fbip': { // Added
    category: 'pcsearch_fbip',
    action: 'Address Search - FBIP',
    label: 'Address Search - Fibre Building In Progress',
  },
  'Not On-Net': { // Added
    category: 'Pcsearch_not_onnet',
    action: 'Address Search - Not On-Net',
    label: 'Address Search - Not On-Net',
  },
  'Requested': { // Added
    category: 'pcsearch_pm_request',
    action: 'Address Search - Interested - Requested',
    label: 'Address Search - Interested',
  },
  'Tenant Request:Copied': {
    category: 'Postal Code Search',
    action: 'Tenant Request',
    label: 'Text Copied',
  },
  // Note that we cannot tell if the user has actually
  //  sent the email, only that they opened the client.
  'Tenant Request:Sent': { // Likely cannot be added, what we can view will end at the :Copied request above.
    category: 'pcsearch_tenant_request',
    action: 'Address Search - Tenant Request',
    label: 'Address Search - Tenant Request - Email sent',
  },
  'PM Request': {
    category: 'Postal Code Search',
    action: 'PM Request',
    label: 'PM Request Completed',
  },
  'Specific Building': { // Added
    category: 'pcsearch_onnet_special',
    action: 'Address Search On-Net Special Building',
    label: 'Address Search On-Net Special Building',
  },
  'Checkout Step 1': {
    category: 'order_internet_step1',
    action: 'Internet Order',
    label: 'Internet Order - Step 1',
   },
  'Checkout Step 2': {
    category: 'order_tv_step2',
    action: 'TV Order step 1',
    label: 'TV Order - Step 2',
  },
  'Checkout Step 3': {
    category: 'order_tv_step3',
    action: 'TV Order step 1',
    label: 'TV Order - Step 3',
  },
  'Checkout Step 4': {
    category: 'order_phone_step4',
    action: 'Phone Order',
    label: 'Phone Order Step 4',
  },
  'Checkout Step 5': {
    category: 'order_date_step5',
    action: 'Order Installation - Select Date',
    label: 'Order Installation - Select Date - Step 5',
  },
  'Checkout Step 5b': {
    category: 'order_date_step5b',
    action: 'Order Installation - Edit Date',
    label: 'Order Installation - Edit Date - Step 5b',
  },
  'Checkout Step 6': {
    category: 'Order_signup_step6',
    action: 'Order Sign Up',
    label: 'Email Verification - Send Code - Step 6',
  },
  'Checkout Step 7': {
    category: 'Order_verifi_step7',
    action: 'Order Sign Up Verification',
    label: 'Email Verification - Verify Email - Step 7',
  },
  'Checkout Step 8': {
    category: 'order_payment_step8',
    action: 'Order Payment Method',
    label: 'Order Payment Method - Add Card- Step 8',
  },
  'Checkout Step 9': {
    category: 'Order_review_step9',
    action: 'Order Sign Up Review',
    label: 'Order Review - Step 9',
  },
  'Checkout Step 10': {
    category: 'Order_confirmed_step10',
    action: 'Order Sign Up Confirmed',
    label: 'Order Confirmed - Step 10',
  },
  'Order Services Selected': {
    category: 'order_services_selected',
    action: 'Order Services Selected',
    label: 'Order Services Selected',
  },
  "Set Up Account - Start": {
    category: 'setup_account_start',
    action: 'Set Up Account - Start',
    label: 'Set Up Account - Start',
  },
  "Select Calendar Day": {
    category: 'select_calendar_day',
    action: 'Selected Calendar Day',
    label: 'Select Calendar Day',
  },
  'Lucky Checkout Step 1': {
    category: 'lucky_order_internet_step1',
    action: 'Lucky Internet Order',
    label: 'Lucky Internet Order - Step 1',
  },
  'Lucky Checkout Step 2': {
    category: 'lucky_order_tv_step2',
    action: 'Lucky TV Order step 2',
    label: 'Lucky TV Order - Step 2',
  },
  'Lucky Checkout Step 3': {
    category: 'lucky_order_tv_step3',
    action: 'Lucky TV Order step 3',
    label: 'Lucky TV Order - Step 3',
  },
  'Lucky Checkout Step 4': {
    category: 'lucky_order_phone_step4',
    action: 'Lucky Phone Order',
    label: 'Lucky Phone Order Step 4',
  },
  'Lucky Checkout Step 5': {
    category: 'lucky_order_date_step5',
    action: 'Lucky Order Installation - Select Date',
    label: 'Lucky Order Installation - Select Date - Step 5',
  },
  'Lucky Checkout Step 5b': {
    category: 'lucky_order_date_step5b',
    action: 'Lucky Order Installation - Edit Date',
    label: 'Lucky Order Installation - Edit Date - Step 5b',
  },
  'Lucky Checkout Step 6': {
    category: 'lucky_order_signup_step6',
    action: 'Lucky Order Sign Up',
    label: 'Lucky Email Verification - Send Code - Step 6',
  },
  'Lucky Checkout Step 7': {
    category: 'lucky_order_verify_step7',
    action: 'Lucky Order Sign Up Verification',
    label: 'Lucky Email Verification - Verify Email - Step 7',
  },
  'Lucky Checkout Step 8': {
    category: 'lucky_order_payment_step8',
    action: 'Lucky Order Payment Method',
    label: 'Lucky Order Payment Method - Add Card- Step 8',
  },
  'Lucky Checkout Step 9': {
    category: 'lucky_order_review_step9',
    action: 'Lucky Order Sign Up Review',
    label: 'Lucky Order Review - Step 9',
  },
  'Lucky Checkout Step 10': {
    category: 'lucky_order_confirmed_step10',
    action: 'Lucky Order Sign Up Confirmed',
    label: 'Lucky Order Confirmed - Step 10',
  },
  'Lucky Order Services Selected': {
    category: 'lucky_order_services_selected',
    action: 'Lucky Order Services Selected',
    label: 'Lucky Order Services Selected',
  },
  "Lucky Set Up Account - Start": {
    category: 'lucky_setup_account_start',
    action: 'Lucky Set Up Account - Start',
    label: 'Lucky Set Up Account - Start',
  },
  "2 gbps fibre internet": {
    category: '2gbps_fibre_internet',
    action: '2 Gbps Fibre Internet',
    label: '2 Gbps Fibre Internet',
  },
  "4 gbps fibre internet": {
    category: '4gbps_fibre_internet',
    action: '4 Gbps Fibre Internet',
    label: '4 Gbps Fibre Internet',
  },
  "8 gbps fibre internet": {
    category: '8gbps_fibre_internet',
    action: '8 Gbps Fibre Internet',
    label: '8 Gbps Fibre Internet',
  },

  "2gbps Availability Check": {
    category: '2gbps_availability_check',
    action: '2gbps Availability Check',
    label: '2gbps Availability Check',
  },
  "4gbps Availability Check": {
    category: '4gbps_availability_check',
    action: '4gbps Availability Check',
    label: '4gbps Availability Check',
  },
  "8gbps Availability Check": {
    category: '8gbps_availability_check',
    action: '8gbps Availability Check',
    label: '8gbps Availability Check',
  },
  "Confirmed Internet Package": {
    category: 'Confirmed_internet_package',
    action: 'Confirmed Internet Package',
    label: 'Confirmed Internet Package',
  }
};

const consoleLog = (msg) => {
  if (['development', 'staging'].includes(process.env.REACT_APP_NODE_ENV)) {
    console.log("Event fired");
    console.log(msg);
  }
};

function sendGoogleTagEvent({eventName}) {
  ReactGA.initialize([
    {
      trackingId: 'G-9SF1JRSHPB'
    },
    {
      trackingId: 'G-BBFQDVR4KW'
    },
  ], {gaOptions: {cookieDomain: 'beanfield.com'}});
  ReactGA.event(EVENTS[eventName]);
  consoleLog(JSON.stringify(EVENTS[eventName]));
}

const sendSelectedDayEvent = ( payload ) => {
  ReactGA.initialize([
    {
      trackingId: 'G-9SF1JRSHPB'
    },
    {
      trackingId: 'G-BBFQDVR4KW'
    },
  ], {gaOptions: {cookieDomain: 'beanfield.com'}});
  const evt = EVENTS["Select Calendar Day"];
  evt.label = `Selected Calendar Day - ${payload}`;
  ReactGA.event(evt);
};

const sendSelectedInternetPackageEvent = ( payload ) => {
  ReactGA.initialize([
    {
      trackingId: 'G-9SF1JRSHPB'
    },
    {
      trackingId: 'G-BBFQDVR4KW'
    },
  ], {gaOptions: {cookieDomain: 'beanfield.com'}});
  const evt = EVENTS[payload.toLowerCase()];
  ReactGA.event(evt);
  consoleLog(JSON.stringify(evt));
};

const sendConfirmedInternetPackageEvent = ( payload ) => {
  ReactGA.initialize([
    {
      trackingId: 'G-9SF1JRSHPB'
    },
    {
      trackingId: 'G-BBFQDVR4KW'
    },
  ], {gaOptions: {cookieDomain: 'beanfield.com'}});
  const evt = EVENTS["Confirmed Internet Package"];
  if (payload) evt.label = `${payload}`;
  ReactGA.event(evt);
  consoleLog(JSON.stringify(evt));
};

export {
  useGoogleTagPageViews,
  sendGoogleTagEvent,
  sendSelectedDayEvent,
  sendSelectedInternetPackageEvent,
  sendConfirmedInternetPackageEvent
};
