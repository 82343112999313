import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={60}
    height={64}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <g clipPath="url(#b)">
        <rect
          width={33}
          height={32}
          rx={16}
          transform="matrix(1 0 0 -1 12 44)"
          fill="#325a32"
        />
        <g clipPath="url(#c)">
          <path
            d="M23.483 34.467 29.95 28l-6.467-6.467a1.66 1.66 0 0 1 0-2.35c.65-.65 1.7-.65 2.35 0l7.65 7.65c.65.65.65 1.7 0 2.35l-7.65 7.65c-.65.65-1.7.65-2.35 0-.633-.65-.65-1.716 0-2.366Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <rect
          width={33}
          height={32}
          rx={16}
          transform="matrix(1 0 0 -1 12 44)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="c">
        <path fill="#fff" transform="matrix(0 1 1 0 8 8)" d="M0 0h40v40H0z" />
      </clipPath>
      <filter
        id="a"
        x={0}
        y={0}
        width={65}
        height={64}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={4} dy={4} />
        <feGaussianBlur stdDeviation={8} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8402_71408"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_8402_71408"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgComponent;
