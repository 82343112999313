import React from "react";
import "components/Header/CareerHeader/CareerHeader.scss";
import MainLogo from "assets/svg/MainLogo";
import { LangOptions } from "utils/DropDownData";
import Button from "components/reusable/Button/Button";
import Dropdown from "components/reusable/Dropdown/Dropdown";
import CareerHamburger from "components/HamburgerMenu/CareerHamburger/CareerHamburger";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CareerHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isFrench = pathname.split("/")[1] === "fr";
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetCareersHeaderQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );
  let baseLink = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port !== 80) baseLink += `:${window.location.port}`;
  baseLink += "/" + pathname.split("/")[1] + "/" + pathname.split("/")[2];

  const links = {
    internet: baseLink + "/internet",
    tv: baseLink + "/tv",
    phone: baseLink + "/phone",
    ourStory: baseLink + "/aboutus",
    careers: baseLink + "/careers",
    whyUs: baseLink + "/whyus",
    freeWiFi: baseLink + "/public",
    contact: baseLink + "/contact",
    support: baseLink + "/support",
    accessibility: baseLink + "/accessibility",
    legal: baseLink + "/legal",
    termsofservice: baseLink + "/termsofservice",
    lifeAtBeanfield: baseLink + "/about-us/life-at-beanfield",
    blog: "",
    howWeHire: baseLink + "/careers/how-we-hire",
  };

  const { localization } = useSelector((state) => state.localizationSlice);

  const langDict = {
    en: 0,
    fr: 1,
  };

  return (
    <>
      <div className="career-navbar-container">
        <div className="career-navbar-sub-container">
          <a href={links.careers}>
            <div className="career-navbar-logo-container">
              <MainLogo />
              <p className="career-navbar-logo-text">careers</p>
            </div>
          </a>
          <a
            className="career-navbar-link-text"
            href={"/" + internalUrlReaderWithLangReg(info?.link_1_url, pathname)}>
            {info?.link_1_txt}
          </a>

          <a
            className="career-navbar-link-text"
            href={"/" + internalUrlReaderWithLangReg(info?.link_2_url, pathname)}>
            {info?.link_2_txt}
          </a>
        </div>

        <div className="career-navbar-sub-container">
          <Dropdown
            options={LangOptions}
            BGColor={"#fff"}
            textColor={"#000"}
            placeholder={LangOptions[langDict[localization]].value}
            urlPosition={1}
          />

          <Button
            click={() => {
              window.open(info?.btn_url);
            }}
          >
            {info?.btn_txt}
          </Button>
        </div>
      </div>

      <CareerHamburger links={links} />
    </>
  );
};

export default CareerHeader;
