import React, { useState } from "react";

import YourAddress from "components/OrderPage/YourAddress/YourAddress";
import InstallationDate from "components/OrderPage/InstallationDate/InstallationDate";
import TotalOrder from "components/OrderPage/TotalOrder/TotalOrder";

import "components/OrderPage/StepThree/index.scss";
import EmailVerification from "components/OrderPage/StepThree/EmailVerification";
import YourInfo from "components/OrderPage/StepThree/YourInfo";
import { useSelector } from "react-redux";
import useWindowDimensions from "utils/WindowWidth";
import MobileSteps from "components/OrderPage/MobileSteps/MobileSteps";

const StepThree = () => {
  const { emailVerificationComplete } = useSelector(
    (state) => state.orderSlice.userInfo
  );
  const { width } = useWindowDimensions();

  return (
    <>
      {/* LEFT SIDE */}
      <div className="stepThree_left_block_container">
        <YourAddress />
        <InstallationDate />
        {/* MOBILE VIEW */}
        {width < 768 ? (
          <>
            <div style={{ marginTop: "1.75rem" }} />
            <MobileSteps activeStep={2}>
              <div style={{ paddingLeft: "1rem" }}>
                {/* LEFT SIDE */}
                <div className="stepThree_left_block_container_mobile">
                  {!emailVerificationComplete ? (
                    <EmailVerification width={width} />
                  ) : null}
                  {emailVerificationComplete ? <YourInfo /> : null}
                </div>
              </div>
            </MobileSteps>
          </>
        ) : null}

        {!emailVerificationComplete && width > 768 ? (
          <EmailVerification width={width} />
        ) : null}
        {emailVerificationComplete && width > 768 ? <YourInfo /> : null}
      </div>
      {/* RIGHT SIDE */}
      {width > 768 ? (
        <div className="orderPage_right_block_container">
          <TotalOrder />
        </div>
      ) : null}
    </>
  );
};

export default StepThree;
