import React from "react";
import "components/reusable/Modal/StartModal.scss";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { getQueries } from "api/wagtailAdapter";

const StartModal = ({ setShowModal, setRegionOpen }) => {
  const dispatch = useDispatch();

  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetStartPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const handleStartModalClose = () => {
    setShowModal(false);
    setRegionOpen(true);
    if (!localStorage.getItem("region")) {
      localStorage.setItem("region", "tor");
    }
  };

  return (
    <>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.22 }}
        className="start-modal-container"
      >
        <h1>{info?.modal_location_check_header}</h1>

        <p>{info?.modal_location_check_body}</p>

        <div
          className="start-modal-close-container"
          onClick={() => handleStartModalClose()}
        >
          <p className="Wbold start-modal-close-button">
            {info?.modal_location_check_btn_txt}
          </p>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.22 }}
        className="start-modal-triangle"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.22 }}
        className="start-modal-triangle-back"
      />
    </>
  );
};

export default StartModal;
