import React from "react";

export const processWagtailImage = (image) => {
  if (!image) return null;
  return `${process.env.REACT_APP_WAGTAIL_URL}${image?.meta?.download_url}`;
};

export const WagtailImage = ({
  image,
  width,
  height,
  alt,
  className,
  style,
}) => {
  return (
    <img
      style={{ ...style, maxWidth: "100%" }}
      className={className}
      src={processWagtailImage(image)}
      width={width}
      height={height}
      alt={alt}
    />
  );
};
