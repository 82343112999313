import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import useWindowDimensions from "utils/WindowWidth";

const Day = ({ day, month, onClick, cmsData }) => {
  const { installationDate, unavailableDates } = useSelector(
    (state) => state.orderSlice.order
  );
  const { width } = useWindowDimensions();
  const isMobile = width < 1024;

  //CHECKING CURRENT MONTH TO GRAY OUT DAYS THAT ARE NOT WITHIN IT
  const current = day.format("M") !== month[1][0].format("M");

  //CHECKING IF DAY IS BEFORE CURRENT DAY TO DISABLE IT
  const beforeCurrentDay =
    Number(day.format("D")) < Number(dayjs().format("D")) &&
    Number(day.format("M")) <= Number(dayjs().format("M")) &&
    Number(day.format("YYYY")) <= Number(dayjs().format("YYYY"));

  //CHECKING IF DAY IS TODAY
  const today =
    dayjs().format("D") === day.format("D") &&
    dayjs().format("M") === day.format("M");

  //CHECKING IF DAY IS SELECTED
  const isSelected =
    day?.format("D M") === dayjs(installationDate)?.format("D M");

  //CHECKING IF DAY IS UNAVAILABLE
  unavailableDates?.forEach((date) => {
    if (dayjs(date).format("D M") === day?.format("D M")) {
      day.unavailable = true;
    }
  });

  const handleSelectDay = (event, day) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (current || beforeCurrentDay || day.unavailable) {
        return;
      }
      onClick(day);
    }
  };

  return (
    <div
      onClick={() => {
        if (current || beforeCurrentDay || day.unavailable) return;
        onClick && onClick(day);
      }}
      className="day"
      style={{
        fontFamily: isSelected ? "WalsheimBold" : "WalsheimRegular",
        fontWeight: isSelected ? "bold" : "400",
        backgroundColor:
          current || beforeCurrentDay
            ? "rgba(75, 74, 74, 0.045)"
            : day.unavailable
            ? "transparent"
            : isSelected
            ? "#bbdd54"
            : "",
        color:
          current || beforeCurrentDay
            ? "rgba(75, 74, 74, 0.25)"
            : "#4b4a4a",
      }}
      onKeyDown={(e) => handleSelectDay(e, day)}
      tabIndex={current || beforeCurrentDay || day.unavailable ? "" : 0}
    >
      <span style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        {day.unavailable && isMobile ? null : (
          <span className={day.unavailable ? "strikethrough" : ""}>{day.format("D")}</span>
        )}
        {day.unavailable? (
          <span className="fully-booked">{cmsData?.step_2_fully_booked_txt}</span>

        ): null}

      </span>


      {today && (
        <span className="today_dot" />
      )}
    </div>
  );
};

export default Day;
