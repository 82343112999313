import React from "react";
import Select from "react-select";

const ServiceDropdown = ({
  options,
  BGColor,
  textColor,
  placeholder,
  className,
  classNamePrefix,
  select = null,
  preventDefault = false,
}) => {
  const colorStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      border: "none",
      width: 400,
      borderRadius: 16,
      padding: 10,
      "&:hover": {
        cursor: "pointer",
      },
      "@media (max-width: 480px)": {
        width: 300,
      },
    }),
    option: (styles) => ({
      ...styles,
      background: BGColor,
      color: textColor,
      "&:hover": {
        background: "#d0e88a",
        cursor: "pointer",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: textColor,
    }),
    menu: (styles) => ({
      ...styles,
      width: 360,
      position: "absolute",
      left: 15,
      margin: 1,
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
      "@media (min-width: 481px) and (max-width: 768px)": {
        width: "90%",
        maxWidth: 360
      },
      "@media (max-width: 480px)": {
        width: "85%",
        maxWidth: 260,
      },
    }),
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: textColor,
      "&:hover": {
        color: textColor,
        cursor: "pointer",
      },
    }),
  };

  return (
    <div style={{ zIndex: 4 }}>
      <Select
        className={className}
        classNamePrefix={classNamePrefix}
        styles={colorStyles}
        options={options}
        placeholder={placeholder}
        components={{ IndicatorSeparator: () => null }}
        onChange={(value, e) => {
          preventDefault && e.preventDefault();
          select && select(value);
        }}
        isSearchable={false}
      />
    </div>
  );
};

export default ServiceDropdown;
