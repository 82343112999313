import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "api/config";

export const Media = createApi({
    reducerPath: "Media",
    baseQuery: fetchBaseQuery({
        baseUrl: 'http://localhost:8000/api/v2/images/'
    }),
    endpoints: (builder) => ({
        getMediaById: builder.query({
            query: ({ imageId }) => {
                return {
                    url: `${imageId}`,
                };
            }
        })
    })
});

export const { useGetMediaByIdQuery, useLazyGetMediaByIdQuery } = Media;