import React from "react";
import "pages/TermsOfService/TermsOfService.scss";
import { getQueries } from "api/wagtailAdapter";
import { useLocation } from "react-router-dom";
import { WagTailDOMString } from "components/reusable/Link/PageLink/PageLink";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import Head from "components/reusable/Head/Head";

const AcceptableUsePolicy = () => {
  const { page: info, isLoading, isError, error } = usePagesApiParser(getQueries(
      useLocation().search
    ).useGetAcceptableUsePolicyPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
  }));

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield | Politique d’utilisation acceptable" :
          "Beanfield | Acceptable Use Policy"
        }
        description={""}
        imageName={"UsePolicy"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="terms-header">
        <h1>{info?.header}</h1>
      </div>

      <div className="terms-container">
        <WagTailDOMString DOMString={info?.body} />
      </div>
    </div>
  );
};

export default AcceptableUsePolicy;
