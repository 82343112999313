import React, { useEffect, useRef, useState } from "react";
import "components/OrderPage/StepFive/StepFive.scss";
import Button from "components/reusable/Button/Button";
import Facebook from "assets/socialIcons/Facebook";
import X from "assets/socialIcons/X";
import Instagram from "assets/socialIcons/Instagram";
import Linkedin from "assets/socialIcons/Linkedin";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/WindowWidth";
import MobileSteps from "components/OrderPage/MobileSteps/MobileSteps";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import Order_process_confetti_animation from "assets/animation/confetti.json";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";

const StepFive = () => {
  const navigate = useNavigate();
  const { localization } = useSelector((state) => state.localizationSlice);
  const { width } = useWindowDimensions();
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  const containerRef = useRef(null);
  const animationRef = useRef(null);
  const [animationWidth, setAnimationWidth] = useState(0);
  const [divHeight, setDivHeight] = useState(0);

  //PAUSING ANIMATION TO KEEP IT ON THE SCREEN
  useEffect(() => {
    setTimeout(() => {
      animationRef.current.pause();
    }, 2750);
  }, []);

  useEffect(() => {
    if (containerRef.current.clientWidth) {
      setAnimationWidth(containerRef.current.clientWidth);
    }
  }, [containerRef?.current?.clientWidth]);

  //Getting reference for the animation container to resize animation with it
  useEffect(() => {
    if (animationRef.current) {
      setDivHeight(
        animationRef.current.animationContainerRef.current.clientHeight
      );
    }
  }, [animationRef.current]);

  const renderContent = () => {
    return (
      <div ref={containerRef} className="step_five_container">
        {/* ANIMATION */}
        <Lottie
          style={{
            marginBottom: "3.4375rem",
            width: width < 768 ? width * 1.2 : animationWidth,
            position: "absolute",
            top: width < 768 ? -15 : -55,
            left: width < 768 ? (width / -10) * 1.75 : 0,
            zIndex: -1,
          }}
          lottieRef={animationRef}
          autoplay={true}
          animationData={Order_process_confetti_animation}
          loop={false}
        />

        <div
          style={{
            marginTop: `${divHeight * 0.6}px`,
          }}
          className="step_five_text_wrapper"
        >
          {/* HEADER */}
          <h2 tabIndex="0">{data?.step_5_header}</h2>
          <div className="connect_text" tabIndex="0">
            <span className="mt-1">{data?.step_5_body}</span>
          </div>
          {/* GO BACK */}
          <Button
            style={{ width: "fit-content" }}
            click={() => navigate(`/${localization}/ca`)}
          >
            {data?.step_5_home_btn_txt}
          </Button>
          {/* SOCIAL */}
          <span className="follow Wbold" tabIndex="0">{data?.step_5_social_title}</span>
          <span className="social_icons">
            <a target="_blank" href="https://twitter.com/beanfieldcanada">
              <X />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/beanfieldcanada/"
            >
              <Instagram />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/beanfieldcanada/"
            >
              <Linkedin />
            </a>
            <a target="_blank" href="https://www.facebook.com/beanfieldcanada">
              <Facebook />
            </a>
          </span>
        </div>
      </div>
    );
  };

  return width > 768 ? (
    renderContent()
  ) : (
    <MobileSteps activeStep={4}>{renderContent()}</MobileSteps>
  );
};

export default StepFive;
