import React, { useState } from "react";
import "components/Modals/Questionnaire/Questionnaire.scss";
import Button from "components/reusable/Button/Button";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import SlideTwoModal from "./SlideTwo/SlideTwo";
import FormModal from "components/Modals/Form/Form";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import RadioBtn from "components/reusable/Radio/RadioBtn";

const QuestionnaireModal = ({ address, unit }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);

  const handleSlide = () => {
    dispatch(toggleModal());

    if (selected?.toLowerCase() === "house") {
      dispatch(
        toggleModal({
          children: <FormModal editAddress={address} unit={unit}/>,
        })
      );
    } else {
      dispatch(
        toggleModal({
          children: <SlideTwoModal address={address} unit={unit}/>,
        })
      );
    }
  };

  return (
    <div className="questionnaire-modal-container">
      {/* TITLE */}
      {convertHtmlToReact(cmsData?.modal_ndb_initial_modal_header)}
      {/* QUESTION */}
      {convertHtmlToReact(cmsData?.modal_ndb_initial_modal_q1_title)}
      <div className="radio_btn_wrap">
        <RadioBtn
          id="house"
          name="question"
          value={cmsData?.modal_ndb_initial_modal_q1_answer1}
          onClick={(e) => setSelected(e.target.value)}
        />
        <label htmlFor="house">
          {cmsData?.modal_ndb_initial_modal_q1_answer1}
        </label>
      </div>

      <div className="radio_btn_wrap">
        <RadioBtn
          id="condo"
          name="question"
          value={cmsData?.modal_ndb_initial_modal_q1_answer2}
          onClick={(e) => setSelected(e.target.value)}
        />
        <label htmlFor="condo">
          {cmsData?.modal_ndb_initial_modal_q1_answer2}
        </label>
      </div>

      <Button
        click={() => handleSlide()}
        disabled={selected === null}
      >
        Next
      </Button>
    </div>
  );
};

export default QuestionnaireModal;
