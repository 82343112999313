import React from "react";
import "components/Modals/SpecialOffer/SpecialOffer.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router";

const SpecialOfferModal = ({ error, address, buildingName }) => {
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);
  return (
    <div className="special-offer-container">
      {error ? (
        convertHtmlToReact(cmsData?.modal_non_serviceable_header)
      ) : (
        <h2>{cmsData?.modal_special_offer_header}</h2>
      )}

      {error ? (
        convertHtmlToReact(cmsData?.modal_non_serviceable_body)
      ) : cmsData?.modal_special_offer_body?.includes("{building}") ? (
        convertHtmlToReact(
          cmsData?.modal_special_offer_body?.split("{building}")[0] +
            `<strong>${buildingName}</strong>` +
            cmsData?.modal_special_offer_body?.split("{building}")[1]
        )
      ) : (
        <p>
          {`*Field should be set in cms "Home Page => Modal special offer body" with a {building} to inject the building and render all the text before and after.*`}
        </p>
      )}
    </div>
  );
};

export default SpecialOfferModal;
