import React from "react";
import "components/Modals/OneGb/OneGb.scss";
import Button from "components/reusable/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import AddressModal from "components/Modals/Address/Address";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import ExclamationMark from "assets/svg/ExclamationMark";
import {setCookie} from "../../../utils/Cookies";
import MD5 from "crypto-js/md5";

const OneGbModal = ({province}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const baseLink = location.pathname.split("/");
  const { localization, region } = useSelector(
    (state) => state.localizationSlice
  );
  const provinceData = {
    "bc": {
      name: "Metro Vancouver",
      region: "van"
    },
    "on": {
      name: "Toronto/GTA",
      region: "tor"
    },
    "qc": {
      name: "Montréal Area",
      region: "mtl"
    },
  };

  const provinceDataFR = {
    "bc": {
      name: "Région de Vancouver",
      region: "van"
    },
    "on": {
      name: "Région de Toronto",
      region: "tor"
    },
    "qc": {
      name: "Région de Montréal",
      region: "mtl"
    },
  };


  const handleReturn = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <AddressModal /> }));
  };

  const handleStart = () => {
    handleSpecialCookie();
    dispatch(toggleModal());
    navigate(`/${baseLink[1]}/${baseLink[2]}/residential-order`);
  };

  const handleSpecialCookie = () => {
    const luckyNumber = Math.floor(Math.random() * 10 + 1);
    // Disabling lucky cookie
    if (false && luckyNumber === 10 && province === "on") {
      setCookie("token", MD5("kraken").toString());
    } else {
      setCookie("token", "");
    }
  };

  const handleSwitchRegion = () => {
    dispatch(toggleModal());
    const region = baseLink[1] === "fr" ? provinceDataFR[province].region : provinceData[province].region;
    navigate(`/${baseLink[1]}/${region}/residential`);
  };

  const handleShowRegionWarning = () => {
    const region = baseLink[1] === "fr" ? provinceDataFR[province].region : provinceData[province].region;
    if (region !== baseLink[2]) {
      return true;
    }
  };

    const switchRegion = () => {
      return (
        <div
          style={{ justifyContent: "flex-start" }}
          className="switch-region-container"
        >
          <div className="left-content">
            <ExclamationMark />
          </div>
            <div className="right-content">
              <p style={{ flex: 1 }}>{cmsData?.modal_one_gb_switch_region_text} {baseLink[1] === 'fr' ? provinceDataFR[province].name : provinceData[province].name}, <span onClick={() => handleSwitchRegion()}>{cmsData?.modal_one_gb_switch_region_link_text}</span>.
              </p>
            </div>
        </div>
      );
    };

  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);
  return (
    <div className="one-gb-modal-container">
      <div className="one-gb-container-header">
        {convertHtmlToReact(cmsData?.modal_one_gb_header)}
        {convertHtmlToReact(cmsData?.modal_one_gb_description)}
      </div>

      <div className="one-gb-container">
        <Button click={() => handleStart()}>
          {cmsData?.modal_one_gb_start_order_btn_text}
        </Button>

        <p onClick={() => handleReturn()} className="Wbold one-gb-modal-text">
          {cmsData?.modal_one_gb_change_address_link_text}
        </p>
      </div>
      <div className="one-gb-container-footer">
        {handleShowRegionWarning() ? switchRegion() : null}
      </div>
    </div>
  );
};

export default OneGbModal;
