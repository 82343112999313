export const setUTMVariables = (searchParams) => {
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
    localStorage.setItem(key, value);
  });
};

export const getUTMVariables = () => {
  let utmValues = {};
  utmValues.utm_campaign = localStorage.getItem("utm_campaign");
  utmValues.utm_source = localStorage.getItem("utm_source");
  utmValues.utm_medium = localStorage.getItem("utm_medium");
  utmValues.utm_language = localStorage.getItem("utm_language");
  utmValues.utm_content = localStorage.getItem("utm_content");
  utmValues.gclid = localStorage.getItem("gclid");

  return utmValues;
};


