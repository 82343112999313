import React from "react";
import "components/reusable/Package/NumberPackage.scss";
import Button from "../Button/Button";
import Checkmark from "assets/ServicesPages/TV/Checkmark";
import Carot from "assets/Icons/Caret";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { modalContents } from "components/OrderPage/Accordions/ModalContents";
import { useDispatch } from "react-redux";
import AddressModal from "components/Modals/Address/Address";

const NumberPackage = ({ item, channels, itemsCount }) => {
  const dispatch = useDispatch();
  const channelPack =
    item.title === "Skinny Basic"
      ? channels.basic
      : item.title === "Essentials"
      ? channels.premium
      : null;

  return (
    <div className={`number-package-container ${itemsCount > 1 ? "max-width-set" : ""}`}>
      <div className="number-package-top">
        <h2>{item?.title}</h2>

        <p>{item?.description}</p>

        <div className="price-container">
          <p className="number-dollar Wbold">$</p>
          <p className="number-value Wbold">{item.value || item.cost}</p>
          <div className="extra-info">
            <p className="number-tax">{item.tax}</p>
            <p className="number-text">/mo.</p>
          </div>
        </div>

        <Button
          style={{ maxWidth: 300 }}
          click={() => dispatch(toggleModal({ children: <AddressModal /> }))}
        >
          {item?.button}
        </Button>
      </div>

      <div className="number-package-bottom">
        {!item?.features || !item?.features.length > 0 ? null : (
          <ul>
            {item.features.map((feature) => {
              return (
                <li>
                  <Checkmark /> {feature}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      {channelPack === null ? null : (
        <div>
          <div className="number-pack-img-container">
            {channelPack?.map((item, i) => {
              if (i < 6) {
                return (
                  <img src={item.logoUrl} alt={item.name} title={item.name} />
                );
              }
            })}
          </div>

          <div
            className="number-pack-arrow-container"
            onClick={() => {
              dispatch(
                toggleModal({
                  children:
                    item.title === "Skinny Basic"
                      ? modalContents.starterPackage(channelPack)
                      : modalContents.essentialPackage(channelPack),
                })
              );
            }}
          >
            <p className="Wbold">{item?.channel_link_txt} </p>
            <Carot color={"#325a32"} turn={"right"} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NumberPackage;
