import React from "react";
import Button from "components/reusable/Button/Button";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";

const AddressModalContent = ({ cmsData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localization, region } = useSelector(
    (state) => state.localizationSlice
  );
  return (
    <div className="address_warning_container">
      <h2>{cmsData?.modal_warning_address_header}</h2>
      <p style={{ margin: "20px 0px", maxWidth: "70%", textAlign: "center" }}>
        {cmsData?.modal_warning_address_body}
      </p>
      <div className="address_warning_btn_wrapper">
        <Button
          click={() => dispatch(toggleModal())}
          btnStyle={"beanfield-button--hollow green Wbold"}
        >
          {cmsData?.modal_warning_address_btn_cancel}
        </Button>
        <Button
          click={() => {
            dispatch(toggleModal());
            navigate(`/${localization}/${region}/residential`);
          }}
          btnStyle="beanfield-button Wbold"
        >
          {cmsData?.modal_warning_address_btn_confirm}
        </Button>
      </div>
    </div>
  );
};

export default AddressModalContent;
