import "components/BusinessCampaign/CarouselComponent.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import BusinessCampaignCarousel from "../Carousel/BusinessCampaignCarousel/BusinessCampaignCarousel";

const CarouselComponent = ({
    item,
    title_text,
    primary_colour
}) => {
    return (
      <>
    <div className="bcarousel-container resi-container">
        <div className="resi-margin-container">
              <div className="header-title">
                  {convertHtmlToReact(title_text)}
              </div>

              <div className="carousel-items">
                <BusinessCampaignCarousel items={item} primaryColour={primary_colour}/>
              </div>
            </div>
    </div>
      </>
    );
};

export default CarouselComponent;