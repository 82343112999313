import React from "react";
import "components/reusable/Package/SinglePackage.scss";
import Button from "../Button/Button";
import { WagTailDOMString } from "../Link/PageLink/PageLink";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";
import AddressModal from "components/Modals/Address/Address";

const SinglePackage = ({ item }) => {
  const dispatch = useDispatch();
  return (
    <div className="single-select-container">
      <div className="single-select-header">
        <h2>{item.title}</h2>

        <div className="single-spacer-text">{item.description}</div>

        <div className="single-desktop-button">
          <Button
            style={{ maxWidth: "18.75rem" }}
            click={() => dispatch(toggleModal({ children: <AddressModal /> }))}
          >
            {item.button}
          </Button>
        </div>
      </div>

      <div className="single-package-container">
        {item.internet ? (
          <div className="single-text-container month-tax-container">
            <div className="month-tax-text">
              <p className="single-large Wbold">{item.internet}</p>
              <p className="single-medium">Gbps</p>
            </div>
            <div className="single-divider" />
            <div className="month-tax-text">
              <p className="single-dollar Wbold">$</p>
              <p className="single-large Wbold">{item.cost}</p>
              <div className="extra-info">
                <p className="single-tax">{item.tax}</p>
                <p className="single-small">/mo.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="single-text-container month-tax-container">
            <div className="month-tax-text">
              <p className="single-dollar Wbold">$</p>
              <p className="single-large Wbold">{item.cost}</p>
              <div className="extra-info">
                <p className="single-tax">{item.tax}</p>
                <p className="single-small">/mo.</p>
              </div>
            </div>
          </div>
        )}

        <p className="single-spacer-text subtitle">
          <WagTailDOMString DOMString={item.subTitle} />
        </p>
      </div>

      <div className="single-mobile-button">
        <Button
          click={() => dispatch(toggleModal({ children: <AddressModal /> }))}
        >
          {item.button}
        </Button>
      </div>
    </div>
  );
};

export default SinglePackage;
