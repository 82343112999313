import { createApi } from "@reduxjs/toolkit/query/react";
import { baseBeanfieldQuery } from "api/config";

export const ContactFormApi = createApi({
  reducerPath: "ContactFormApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    submitContactForm: builder.mutation({
      query: ({
        firstName,
        lastName,
        email,
        telephone,
        postalCode,
        message,
      }) => {
        return {
          url: `/v1/residential/contact-form`,
          method: "POST",
          body: {
            firstName,
            lastName,
            email,
            telephone,
            postalCode,
            message,
          },
        };
      },
    }),
    submitGetFibreForm: builder.mutation({
      query: ({
                firstName,
                lastName,
                email,
                telephone,
                postalCode,
                message,
                suite,
                addressId,
              }) => {
        return {
          url: `/v2/residential/contact-form`,
          method: "POST",
          body: {
            firstName,
            lastName,
            email,
            telephone,
            postalCode,
            message,
            suite,
            addressId,
          },
        };
      },
    }),
    submitCampaignContactForm: builder.mutation({
      query: ({
                firstName,
                lastName,
                company,
                email,
                phone,
                serviceAddress,
                message,
                token,
                subscribe,
                utms
              }) => {
        return {
          url: `/v2/business/campaign-contact-form`,
          method: "POST",
          body: {
            firstName,
            lastName,
            email,
            phone,
            company,
            serviceAddress,
            message,
            token,
            subscribe,
            utms
          },
        };
      },
    }),
  }),
});

export const {
  useSubmitContactFormMutation,
  useSubmitGetFibreFormMutation,
  useSubmitCampaignContactFormMutation
} = ContactFormApi;
