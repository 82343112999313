import React from "react";
import "components/reusable/Link/DownloadLink/DownloadLink.scss";
import PDF from "assets/Icons/PDF";

const DownloadLink = ({ file, index }) => {
  return (
    <div className="download-link-container">
      <PDF />

      <a href={file} target="_blank">
        {/* this will be updated with the file text */}
        {`User Guide ${index + 1}`}
      </a>
    </div>
  );
};

export default DownloadLink;
