import React, {useEffect, useRef, useState} from "react";
import "components/Modals/Address/Address.scss";
import Button from "components/reusable/Button/Button";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import SuccessModal from "components/Modals/Success/Success";
import FormModal from "components/Modals/Form/Form";
import SelectModal from "components/Modals/Select/Select";
import QuestionnaireModal from "components/Modals/Questionnaire/Questionnaire";
import SpecialOfferModal from "components/Modals/SpecialOffer/SpecialOffer";
import CommercialModal from "components/Modals/Commercial/Commercial";
import FibrestreamModal from "components/Modals/Fibrestream/Fibrestream";
import OneGbModal from "components/Modals/OneGb/OneGb";
import BulkBuildingModal from "components/Modals/BulkBuilding/BulkBuilding";
import SpecialDeliveryUnitModal from "components/Modals/SpecialDeliveryUnit/SpecialDeliveryUnit";
import OldResidentialModal from "components/Modals/OldResidential/OldResidential";
import { useResetStateAndSetAddress } from "services/hooks/useResetStateAndSetAddress";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router-dom";
import { useLazyGetSearchQuery } from "api/beanfield/Search";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";

const AddressModal = ({ internetSelected }) => {
  const dispatch = useDispatch();
  const unitInput = useRef(null);
  const [addressError, setAddressError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [addressText, setAddressText] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const { resetStateAndSetAddress } = useResetStateAndSetAddress();
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);

  const [searchClick, setSearchClick] = useState(false);
  const [getSearch, { data }] = useLazyGetSearchQuery();


  const handleSubmit = () => {
    const info = address;

    if (info.unserviceableUnits.includes(unit)) {
      dispatch(toggleModal());
      dispatch(toggleModal({ children: <SpecialOfferModal error /> }));
    } else if (info.specialDeliveryUnits.includes(unit)) {
      dispatch(toggleModal());
      dispatch(toggleModal({ children: <SpecialDeliveryUnitModal />}));
    } else {
      if (info.isSpecialBuilding) {
        dispatch(toggleModal());
        dispatch(
          toggleModal({
            children: (
              <SpecialOfferModal buildingName={info.specialBuildingName} />
            ),
          })
        );
        sendGoogleTagEvent({ eventName: "Specific Building" });
      } else {
        dispatch(toggleModal());
        if (info.isOnNet && info.isFibrestream) {
          dispatch(toggleModal({ children: <FibrestreamModal /> }));
        } else if (info.isOnNet && info.isOldResidential) {
          dispatch(toggleModal({children: <OldResidentialModal/>}));
        } else if (info.isOnNet && !info.isResidential) {
          dispatch(toggleModal({children: <CommercialModal/>}));
        } else if (info.isOnNet && info.isOneGbps) {
          dispatch(toggleModal({ children: <OneGbModal province={info.province.toLowerCase()} /> }));
          resetStateAndSetAddress({ ...info, unit });
          sendGoogleTagEvent({ eventName: "On-Net" });
        } else if (info.isOnNet && info.isBulkBuilding) {
          dispatch(toggleModal({ children: <BulkBuildingModal province={info.province.toLowerCase()} /> }));
          resetStateAndSetAddress({ ...info, unit });
          sendGoogleTagEvent({ eventName: "On-Net" });
        } else if (info.isOnNet) {
          dispatch(toggleModal({ children: <SuccessModal province={info.province.toLowerCase()} address={address} /> }));
          resetStateAndSetAddress({ ...info, unit, internetSelected });
          sendGoogleTagEvent({ eventName: "On-Net" });
        } else {
          if (info.status === "Fibre Build In Progress") {
            dispatch(
              toggleModal({
                children: <FormModal pending editAddress={address} unit={unit}/>,
              })
            );
            sendGoogleTagEvent({ eventName: "fbip" });
          } else if (info.status === "Interested") {
            dispatch(
              toggleModal({
                children: <SelectModal address={address} unit={unit}/>,
              })
            );
            sendGoogleTagEvent({ eventName: "Requested" });
          } else if (info.status === "Currently Not On-Net") {
            dispatch(
              toggleModal({
                children: <FormModal editAddress={address} unit={unit}/>,
              })
            );
            sendGoogleTagEvent({ eventName: "Not On-Net" });
          } else {
            dispatch(
              toggleModal({
                children: <QuestionnaireModal address={address} unit={unit}/>,
              })
            );
            sendGoogleTagEvent({ eventName: "Not On-Net" });
          }
        }
      }
    }
  };

  const handleDropdown = (value) => {
    setSearchClick(true);
    setAddress(value);
    setAddressText(value.addressText)
  };

  const handleBlur = (value) => {
    if (value === "address") {
      if (!addressText) {
        setAddressError(true);
      } else {
        setAddressError(false);
        unitInput.current.focus();
      }
    }
    if (value === "unit") {
      if (!unit) {
        setUnitError(true);
      } else {
        setUnitError(false);
      }
    }
  }

  const handleAddressInput = (value) => {
    setSearchClick(false);
    setAddressText(value);
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getSearch({ address: addressText });
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [addressText]);

  return (
    <div className="address-modal-container">
      <h1>{cmsData?.modal_check_header}</h1>

      <div className="address-modal-input-container">
        <div className="address-error-container">
          <input
            value={addressText}
            onChange={(e) => handleAddressInput(e.target.value)}
            placeholder={cmsData?.modal_check_building_address_placeholder}
            className="address-modal-address-input"
            aria-border={addressError}
            onBlur={(e) => handleBlur("address")}
          />
          {!addressError ? null : (
            <p className="input-error-text">
              { cmsData?.main_carousel[0]?.value?.inline_empty_address_error }
            </p>
          )}
        </div>
        <div className="address-modal-dropdown">
          {!data ? null : data?.length === 0 ? (
            <p>
              Oops! There aren't any matches for that search. Please try again.
            </p>
          ) : searchClick ? null : (
            data.map((item, index) => {
              return (
                <p key={index} onClick={() => handleDropdown(item)}>
                  {item.addressText}
                </p>
              );
            })
          )}
        </div>

        <div className="address-error-container">
          <input
            ref={unitInput}
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            placeholder={cmsData?.modal_check_building_unit_placeholder}
            className="address-modal-unit-input"
            aria-border={unitError}
            onBlur={(e) => {handleBlur("unit")}}
          />
          {!unitError ? null : (
            <p className="input-error-text">{ cmsData?.main_carousel[0]?.value?.inline_empty_unit_error }</p>
          )}
        </div>
      </div>

      <Button
        disabled={address === "" || unit === ""}
        click={() => handleSubmit()}
      >
        {cmsData?.modal_check_submit_btn_text}
      </Button>
    </div>
  );
};

export default AddressModal;
