import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "pages/Home/HomePage";
import Layout from "layout/Layout";
import OrderPage from "pages/Order/OrderPage";
import StartPage from "pages/Start/StartPage";
import ContactPage from "pages/Contact/ContactPage";
import Internet from "pages/InternetService/InternetService";
import Phone from "pages/PhoneService/PhoneService";
import TermsOfService from "pages/TermsOfService/TermsOfService";
import AcceptableUsePolicy from "pages/AcceptableUsePolicy/AcceptableUsePolicy";
import Legal from "pages/Legal/Legal";
import MDUAccessAgreements from "pages/MDU/MDU";
import Accessibility from "pages/Accessibility/Accessibility";
import TVService from "pages/TVService/TVService";
import GoService from "pages/GoService/GoService";
import ChannelLineup from "pages/ChannelLineup/ChannelLineup";
import WhyChooseUs from "pages/WhyChooseUs/WhyChooseUs";
import Technical from "pages/Technical/Technical";
import Public from "pages/PublicWiFi/Public";
import Support from "pages/Support/Support/Support";
import SupportFAQ from "pages/Support/SupportFAQ/SupportFAQ";
import AboutUs from "pages/AboutUs/AboutUs";
import Survey from "pages/Survey/Survey";
import RealityOfInternetSpeed from "pages/RealityOfInternetSpeed/realityOfInternetSpeed";
import LifeAtBeanfield from "pages/LifeAtBeanfield/LifeAtBeanfield";
import SearchResult from "pages/SearchResults/SearchResults";
import HowWeHire from "pages/HowWeHire/HowWeHire";
import Careers from "pages/Careers/Careers";
import InternetSpeeds101 from "pages/InternetSpeeds101/InternetSpeeds101";
import { store } from "store/store";
import AvailabilityMap from "pages/AvailabilityMap/AvailabilityMap";
import CCTS from "pages/CCTS/CCTS";
import BusinessCampaign from "pages/BusinessCampaign/BusinessCampaign";
import BusinessCampaignLayout from "layout/BusinessCampaignLayout";
import { useLocation } from "react-router-dom";

export const langs = ["en", "fr"];
export const regions = ["bc", "on", "qc", "ca", "tor", "mtl", "van"];
// 30
const routes = [
  <Route exact path={""} element={<StartPage />} />,
  <Route path={"search"} element={<SearchResult />} />,

  <Route exact path={"residential"}>
    <Route index={true} element={<HomePage />} />,
    <Route path={"tos"} element={<TermsOfService />} />,
    <Route exact path={"internet"}>
      <Route index={true} element={<Internet />} />,
      <Route
        path={"reality-of-internet-speed"}
        element={<RealityOfInternetSpeed />}
      />,
    </Route>,
    <Route path={"public-wifi"} element={<Public />} />,
    <Route path={"internet-tech-hardware"} element={<Technical />} />,
    <Route path={"home-phone"} element={<Phone />} />,
    <Route path={"tv"}>
      <Route index={true} element={<TVService />} />,
      <Route path={"go-services"} element={<GoService />} />,
      <Route path={"channels"} element={<ChannelLineup />} />,
    </Route>
    ,
    <Route exact path={"support"}>
      <Route index={true} element={<Support />} />,
      <Route path={"billing"} element={<SupportFAQ />} />,
      <Route path={"internet"} element={<SupportFAQ />} />,
      <Route path={"home-phone"} element={<SupportFAQ />} />,
      <Route path={"tv"} element={<SupportFAQ />} />,
      <Route path={"general-accounts"} element={<SupportFAQ />} />,
    </Route>
    <Route path={"internet-speeds-101"} element={<InternetSpeeds101 />} />,
    ,
    <Route path={"availability-map"} element={<AvailabilityMap />} />,
  </Route>,
  <Route path={"legal"} element={<Legal />} />,
  <Route path={"ccts"} element={<CCTS />} />,
  <Route path={"aup"} element={<AcceptableUsePolicy />} />,
  <Route path={"mdu"} element={<MDUAccessAgreements />} />,
  <Route path={"accessibility"} element={<Accessibility />} />,

  <Route path={"why-choose-us"} element={<WhyChooseUs />} />,

  <Route path={"contact-us"} element={<ContactPage />} />,
  // Test route for CMS
  <Route path={"about-us"}>
    <Route index={true} element={<AboutUs />} />
    <Route path={"life-at-beanfield"} element={<LifeAtBeanfield />} />,
  </Route>,

  <Route path={"careers"}>
    <Route index={true} element={<Careers />} />
    <Route path={"how-we-hire"} element={<HowWeHire />} />,
  </Route>,

  <Route exact path={"residential-order"} element={<OrderPage />}>
    {/* Will let Andrey handle this, if any work is neccesary. */}
  </Route>,
];

const campaignRoutes = [
  <Route exact path={"business"}>
    <Route path={"campaign/:slug"} element={<BusinessCampaign />} />
  </Route>,
]

routes.push( <Route path={"survey"} element={<Survey/>}  /> )


let { localization, region } = store.getState().localizationSlice;
localization = localStorage.getItem("localization");
region = localStorage.getItem("region");

let path = `/${localization}/${region}`;
if (!localization || !region) path = "/en/tor";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route exact path="/" element={<Navigate to={`${path}/`} />} />,
      <Route path="/" element={<Layout />}>
        {langs.map((_lang, i) => {
          return (
            <Route key={i} path={_lang}>
              <Route
                exact
                path={`/${_lang}`}
                element={<Navigate to={`/${_lang}/${region}/`} />}
              />
              ,
              {regions.map((_region, i) => {
                return (
                  <Route key={i} path={_region}>
                    {[
                      ...routes,
                      <Route
                        exact
                        path={`/${_lang}/${_region}`}
                        element={<Navigate to={`/${_lang}/${_region}/`} />}
                      />,
                    ]}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Route>

      {/*build business campaign routes*/}
      <Route path="/" element={<BusinessCampaignLayout />}>
        {langs.map((_lang, i) => {
          return (
            <Route key={i} path={_lang}>
              <Route
                exact
                path={`/${_lang}`}
                element={<Navigate to={`/${_lang}/${region}/`} />}
              />
              ,
              {regions.map((_region, i) => {
                return (
                  <Route key={i} path={_region}>
                    {[
                      ...campaignRoutes,
                      <Route
                        exact
                        path={`/${_lang}/${_region}`}
                        element={<Navigate to={`/${_lang}/${_region}/`} />}
                      />,
                    ]}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Route>
    </>
  )
);

export default router;
