import React, { useEffect, useState } from "react";
import "pages/Accessibility/Accessibility.scss";
import "pages/TermsOfService/TermsOfService.scss";
import Button from "components/reusable/Button/Button";
import EmailValidation from "utils/EmailValidation";

import { getQueries } from "api/wagtailAdapter";
import { useLocation } from "react-router-dom";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";

import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { langs } from "routes/Routes";

import { useSubmitSurveyMutation } from "api/beanfield/Accessibility";
import Head from "components/reusable/Head/Head";

const interpolateTagsWithAttributes = (str, dict) => {
  let new_str = `${str}`;
  for (const [tag, value] of Object.entries(dict)) {
    new_str = new_str.replace(`<placeholder>${tag}</placeholder>`, value);
  }

  return new_str;
};

const useAccessibilityOppositeLocale = (currentLang) => {
  let useLang;
  if (currentLang === langs[0]) {
    useLang = langs[1];
  } else {
    useLang = langs[0];
  }

  // We can write another query here that gets only the required fields.
  //  This will likely not work in preview.
  const { page: data, isLoading, isError, error } = usePagesApiParser(getQueries(
    useLocation().search
  ).useGetAccessibilityPageQuery({
    localePath: useLang,
  }));

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const useManageAccessibilityPOSTTemplate = ({ p_data, o_data, lang }) => {
  const [POSTTemplate, setPOSTTemplate] = useState({
    survey: [
      {
        question_fr: null,
        question_en: null,
        answer: "",
      },
      {
        question_fr: null,
        question_en: null,
        answer: "",
      },
      {
        question_fr: null,
        question_en: null,
        answer: "",
      },
      {
        question_fr: null,
        question_en: null,
        answer: "",
      },
    ],
    user: {
      name: "",
      email: "",
    },
  });

  useEffect(() => {
    const temp = POSTTemplate;
    let en, fr;
    if (lang === "en") {
      en = p_data;
      fr = o_data;
    } else {
      fr = p_data;
      en = o_data;
    }
    if (p_data && o_data) {
      temp.survey = temp.survey.map((question, index) => {
        question = {
          ...question,
          question_en: en[`form_q${index + 1}`],
          question_fr: fr[`form_q${index + 1}`],
        };
        return question;
      });
    }

    setPOSTTemplate(temp);
  }, [p_data, o_data, lang]);

  const setEmail = (value) => {
    setPOSTTemplate({
      ...POSTTemplate,
      user: {
        ...POSTTemplate.user,
        email: value,
      },
    });
  };

  const setName = (value) => {
    setPOSTTemplate({
      ...POSTTemplate,
      user: {
        ...POSTTemplate.user,
        name: value,
      },
    });
  };

  const resetAnswers = () => {
    const newPOSTTemplate = { ...POSTTemplate };

    newPOSTTemplate.survey.forEach((question, i) => {
      newPOSTTemplate.survey[i].answer = "";
    });

    setPOSTTemplate(newPOSTTemplate);
  };

  return [
    POSTTemplate,
    setPOSTTemplate,
    POSTTemplate.user.name,
    setName,
    POSTTemplate.user.email,
    setEmail,
    resetAnswers,
  ];
};

const isAtLeastOneQuestionFilled = (POSTTemplate) => {
  const { email, name } = POSTTemplate.user;
  if (email.length > 0 !== name.length > 0) return false;

  for (const entry of POSTTemplate.survey) {
    if (entry?.answer?.length > 0) return true;
  }

  return false;
};

const Accessibility = () => {
  const [lang] = useState(useLocation().pathname.split("/")[1]);

  const { page: info, isLoading, isError, error } = usePagesApiParser(
     getQueries(
      useLocation().search
    ).useGetAccessibilityPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
  }));

  const oppositeLocale = useAccessibilityOppositeLocale(
    useLocation().pathname.split("/")[1]
  );
  const oppositeInfo = oppositeLocale?.data?.items
    ? oppositeLocale?.data?.items[0]
    : oppositeLocale.data;

  const [submitForm, formResponse] = useSubmitSurveyMutation();

  const [
    POSTTemplate,
    setPOSTTemplate,
    name,
    setName,
    email,
    setEmail,
    resetAnswers,
  ] = useManageAccessibilityPOSTTemplate({
    p_data: info,
    o_data: oppositeInfo,
    lang,
  });

  const [emailError, setEmailError] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [responseSubmitted, setResponseSubmitted] = useState(false);

  useEffect(() => {
    if (isAtLeastOneQuestionFilled(POSTTemplate)) {
      if (email.length === 0) {
        setDisableButton(false);
      } else if (email.length > 0 && !EmailValidation(email)) {
        setEmailError(true);
        setDisableButton(true);
      } else {
        setEmailError(false);
        setDisableButton(false);
      }
    } else {
      setDisableButton(true);
    }
  }, [POSTTemplate, email]);

  const handleInput = (type, value) => {
    const tempObj = { ...POSTTemplate };

    tempObj.survey[type].answer = value;

    setPOSTTemplate(tempObj);
  };

  const handleSubmit = () => {
    if (responseSubmitted) {
      setResponseSubmitted(false);
      resetAnswers();
      setName("");
      setEmail("");
      formResponse.reset();
    } else {
      submitForm({ survey: POSTTemplate });
      setResponseSubmitted(true);
    }
  };

  useEffect(() => {}, [formResponse]);

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Accessibilité | Beanfield" :
          "Accessibility | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield s'engage à assurer l'accessibilité pour tous. En savoir plus sur nos initiatives et nos fonctionnalités." :
          "Beanfield is committed to providing accessibility for all. Learn more about our initiatives and features."
        }
        imageName={"Accessibility"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="access-header">
            <h1>{info?.banner_header}</h1>

            <p>{info?.banner_body}</p>
          </div>
        </div>
      </div>

      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="access-container">
            {responseSubmitted ? (
              <p>{convertHtmlToReact(info?.form_acknowledge_submission)}</p>
            ) : (
              <>{convertHtmlToReact(info?.main_body)}</>
            )}

            <h2>
              {responseSubmitted
                ? info?.form_your_response_header
                : info?.form_header}
            </h2>

            {responseSubmitted ? (
              <>
                {convertHtmlToReact(
                  interpolateTagsWithAttributes(
                    info?.form_your_response_name_provided,
                    {
                      name: " " + name, // Name needs an extra space at the beginning if it exists.
                    }
                  )
                )}

                {email ? (
                  <>
                    {email &&
                      email !== "" &&
                      convertHtmlToReact(
                        interpolateTagsWithAttributes(
                          info?.form_your_response_email_provided,
                          {
                            email: email,
                          }
                        )
                      )}
                  </>
                ) : (
                  <></>
                )}

                {convertHtmlToReact(info?.form_your_response_body)}
              </>
            ) : null}

            <div
              className={
                responseSubmitted && !POSTTemplate.survey[0].answer.length > 0
                  ? "access-question-display"
                  : "access-qustion-container"
              }
            >
              <p className={responseSubmitted ? "Wbold" : null}>{info?.form_q1}</p>

              {responseSubmitted ? (
                <p>{POSTTemplate.survey[0].answer}</p>
              ) : (
                <textarea
                  value={POSTTemplate.survey[0].answer}
                  onChange={(e) => handleInput(0, e.target.value)}
                  placeholder={info?.form_field_placeholder}
                  aria-label={info?.form_q1}
                />
              )}
            </div>

            <div
              className={
                responseSubmitted && !POSTTemplate.survey[1].answer.length > 0
                  ? "access-question-display"
                  : "access-qustion-container"
              }
            >
              <p className={responseSubmitted ? "Wbold" : null}>{info?.form_q2}</p>
              {responseSubmitted ? (
                <p>{POSTTemplate.survey[1].answer}</p>
              ) : (
                <textarea
                  value={POSTTemplate.survey[1].answer}
                  onChange={(e) => handleInput(1, e.target.value)}
                  placeholder={info?.form_field_placeholder}
                  aria-label={info?.form_q2}
                />
              )}
            </div>

            <div
              className={
                responseSubmitted && !POSTTemplate.survey[2].answer.length > 0
                  ? "access-question-display"
                  : "access-qustion-container"
              }
            >
              <p className={responseSubmitted ? "Wbold" : null}>{info?.form_q3}</p>
              {responseSubmitted ? (
                <p>{POSTTemplate.survey[2].answer}</p>
              ) : (
                <textarea
                  value={POSTTemplate.survey[2].answer}
                  onChange={(e) => handleInput(2, e.target.value)}
                  placeholder={info?.form_field_placeholder}
                  aria-label={info?.form_q3}
                />
              )}
            </div>

            <div
              className={
                responseSubmitted && !POSTTemplate.survey[3].answer.length > 0
                  ? "access-question-display"
                  : "access-qustion-container"
              }
            >
              <p className={responseSubmitted ? "Wbold" : null}>{info?.form_q4}</p>
              {responseSubmitted ? (
                <p>{POSTTemplate.survey[3].answer}</p>
              ) : (
                <textarea
                  value={POSTTemplate.survey[3].answer}
                  onChange={(e) => handleInput(3, e.target.value)}
                  placeholder={info?.form_field_placeholder}
                  aria-label={info?.form_q4}
                />
              )}
            </div>

            {responseSubmitted ? null : (
              <div className="access-qustion-container">
                <p>{info?.form_name_email_text}</p>

                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={info?.form_name_placeholder}
                  aria-label={"Your Name"}
                />

                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={info?.form_email_placeholder}
                  aria-label={"Your Email"}
                />
                {!emailError ? null : (
                  <p className="access-error-text">Please enter a valid email</p>
                )}
              </div>
            )}

            <div className="access-button-container">
              <Button click={() => handleSubmit()} disabled={disableButton}>
                {responseSubmitted
                  ? info?.form_resubmit_btn
                  : info?.form_submit_btn }
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accessibility;
