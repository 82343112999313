import React, { useState } from "react";
import "components/Modals/Form/Form.scss";
import Button from "components/reusable/Button/Button";
import AddressModal from "../Address/Address";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import ThanksModal from "components/Modals/Thanks/Thanks";
import { useSubmitGetFibreFormMutation } from "api/beanfield/ContactForm";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router-dom";

const FormModal = ({ editAddress, pending, PM, unit }) => {
  const dispatch = useDispatch();
  const [submitForm] = useSubmitGetFibreFormMutation();
  const [address, setAddress] = useState(editAddress ? editAddress : "");
  const [firstName, SetFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleEdit = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <AddressModal /> }));
  };

  const handleSubmit = () => {
    submitForm({
      firstName: firstName,
      lastName: lastName,
      email: email,
      telephone: phone,
      message: address?.addressText || "",
      suite: unit || "",
      postalCode: address?.postalCode || "",
      addressId: address?.addressId || "",
    });
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <ThanksModal thanks={PM} /> }));
  };
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);

  return (
    <div className="form-modal-container">
      <h2>
        {pending
          ? cmsData?.modal_fbip_header
          : PM
          ? cmsData?.modal_int_mngboard_header
          : cmsData?.modal_non_header}
      </h2>
      <p>
        {PM
          ? cmsData?.modal_int_mngboard_description
          : cmsData?.modal_fbip_description}
      </p>

      <div className="form-modal-input-container">
        <div className="form-modal-input">
          <label htmlFor="address">{cmsData?.modal_nca_address_title}</label>
          <input
            id="address"
            onChange={(e) => setAddress(e.target.value)}
            value={address.addressText}
            disabled
          />

          <p className="form-modal-edit-button" onClick={() => handleEdit()}>
            {cmsData?.modal_nca_edit_btn}
          </p>
        </div>

        <div className="form-modal-flex-container">
          <div className="form-modal-input">
            <label htmlFor="firstName">{cmsData?.modal_nca_fname_title}</label>

            <input
              id="firstName"
              onChange={(e) => SetFirstName(e.target.value)}
              value={firstName}
            />
          </div>

          <div className="form-modal-input">
            <label htmlFor="lastName">{cmsData?.modal_nca_lname_title}</label>

            <input
              id="lastName"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </div>
        </div>

        <div className="form-modal-flex-container">
          <div className="form-modal-input">
            <label htmlFor="email">{cmsData?.modal_nca_email_title}</label>

            <input
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          {PM ? (
            <div className="form-modal-input">
              <label htmlFor="phone">{cmsData?.modal_int_phone_title}</label>

              <input
                id="phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>
          ) : null}
        </div>
      </div>

      <Button
        disabled={firstName === "" || lastName === "" || email === ""}
        click={() => handleSubmit()}
      >
        {cmsData?.modal_nca_submit_btn_text}
      </Button>
    </div>
  );
};

export default FormModal;
