import React, { useState } from "react";
import "components/reusable/Tooltip/TooltipMobile.scss";
import OrderPage_info_icon from "assets/svg/OrderPage_info_icon";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";

/**
 *
 * @param {object} containerStyles optional style for main container
 * @param {object} helperStyles optional style for helper element
 * @param {object} contentStyles optional style for content/children element
 * @param {object} iconStyles optional style for the icon element
 * @returns {JSX.Element} Tooltip component with icon and content
 */

const ToolTipMobile = ({
  children: data,
  helperStyles = {},
  contentStyles = {},
  containerStyles = {},
  iconStyles = {},
}) => {
  const dispatch = useDispatch();

  const modalContent = () => {
    return <div className="tooltip_content_mobile">{data}</div>;
  };

  return (
    <>
      <OrderPage_info_icon
        onClick={() => {
          dispatch(toggleModal({ children: modalContent() }));
        }}
        style={{ ...iconStyles }}
        className="info_icon"
      />
    </>
  );
};

export default ToolTipMobile;
