import React from "react";
import "components/Marquee/MarqueeCard/MarqueeCard.scss";
import { WagtailImage } from "components/Wagtail/WagtailImage";

const MarqueeCard = ({ movie, test }) => {
  return (
    <div className="marquee-card-container">
      <WagtailImage image={movie?.image} alt={movie?.title} />

      <p style={{ width: "100%" }}>{movie?.title}</p>
    </div>
  );
};

export default MarqueeCard;
