import React, { useState } from "react";
import "components/reusable/Package/ImagePackage.scss";
import { MediaUrl } from "utils/link";
import PageLink from "../Link/PageLink/PageLink";
import convertHtmlToReact from "@hedgedoc/html-to-react";
import { useLocation } from "react-router-dom";
import { internalUrlReaderWithLangReg } from "utils/wagtail";

const ImagePackage = ({ item, center }) => {
  const [hover, setHover] = useState(false);
  const location = useLocation().pathname;

  const createStepBackPath = (pathLength) => {
    let stepBack = "";
    for (let i = 0; i < pathLength - 1; i++) {
      stepBack += "../";
    }
    return stepBack;
  };

  let uri;
  if (item?.link.includes("https://internallink.com")) {
    const pathLength = location.split("/").length;
    uri =
      createStepBackPath(pathLength) +
      internalUrlReaderWithLangReg(item?.link, location);
  } else {
    uri = item?.link;
  }

  return (
    <div
      onClick={() => (window.location.href = uri)}
      className={
        item?.id
          ? `image-package-container  package${item?.id}`
          : "image-package-container"
      }
      style={{
        backgroundImage: `url(${MediaUrl}${
          hover
            ? item?.image_hover?.meta.download_url
            : item?.image?.meta.download_url
        })`,
        paddingLeft: !center ? 20 : null,
        height: !center ? 290 : 600,
        alignItems: !center ? null : "center",
        flexDirection: !center ? null : "column",
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={center ? "image-package-content" : null} tabIndex="0">
        <h2>{item?.title}</h2>

        <div>{convertHtmlToReact(item?.description)}</div>

        <PageLink bold arrow={"true"} to={uri}>
          {item?.link_text}
        </PageLink>
      </div>
    </div>
  );
};

export default ImagePackage;
