import React, { useRef } from "react";
import "components/SupportAccordion/SupportAccordion.scss";
import { AccordionItem } from "@szhsin/react-accordion";
import Caret from "assets/Icons/Caret";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { WagTailDOMString } from "components/reusable/Link/PageLink/PageLink";

const makeAnchorFriendly = (string) => {
  return string.replace(/\W/g, "");
};

const VerticalSplit = ({ image, paragraph }) => {
  return (
    <div className={"vertical-split"}>
      <WagtailImage image={image} />
      <div
        style={{
          marginTop: "1.5rem",
        }}
      >
        <WagTailDOMString DOMString={paragraph} />
      </div>
    </div>
  );
};

const contentMappings = {
  paragraph: (value) => <WagTailDOMString DOMString={value} />,
  image: (value) => <WagtailImage image={value} />,
  verticalSplit: (value) => (
    <VerticalSplit image={value.image} paragraph={value.paragraph} />
  ),
};

const SupportAccordion = ({ toggle, item, center, index }) => {
  const handleClick = () => {
    setTimeout(() => {
      const element = document.getElementById(`support-accordion${index}`);
      const yOffset = -80;
      const yTarget =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yTarget, behavior: "smooth" });
    }, 200);

    return toggle;
  };

  return (
    <AccordionItem
      id={`support-accordion${index}`}
      aria-center={center}
      onClick={() => handleClick()}
      itemKey={makeAnchorFriendly(item.value.title)}
      header={({ state }) => (
        <>
          <Caret color={"#325a32"} turn={state.isEnter ? "down" : "right"} />
          <p
            id={makeAnchorFriendly(item.value.title)}
            className={
              state.isEnter
                ? `faq-accordion-title Wbold`
                : `faq-accordion-title`
            }
          >
            {convertHtmlToReact(item.value.title)}
          </p>
        </>
      )}
    >
      <div tabIndex="0">
        {item?.value?.content.map((content) => {
          return contentMappings[content.type](content.value);
        })}
      </div>
    </AccordionItem>
  );
};

const HowWeHireAccordion = ({ toggle, item, center }) => {
  return (
    <AccordionItem
      aria-center={center}
      onClick={toggle}
      itemKey={item.title}
      header={({ state }) => (
        <>
          <Caret color={"#325a32"} turn={state.isEnter ? "down" : "right"} />
          <p
            className={
              state.isEnter
                ? `faq-accordion-title Wbold`
                : `faq-accordion-title`
            }
          >
            {item.title}
          </p>
        </>
      )}
    >
      {item.content}
    </AccordionItem>
  );
};

const LegalAccordion = ({ toggle, title, body, center }) => {
  return (
    <AccordionItem
      aria-center={center}
      onClick={toggle}
      itemKey={title}
      header={({ state }) => (
        <>
          <Caret color={"#325a32"} turn={state.isEnter ? "down" : "right"} />
          <p
            className={
              `${state.isEnter
                ? `faq-accordion-title Wbold`
                : `faq-accordion-title`}`
            }
          >
            {title}
          </p>
        </>
      )}
    >
      {convertHtmlToReact(body)}
    </AccordionItem>
  );
};

export default SupportAccordion;

export { HowWeHireAccordion, LegalAccordion };
