import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalOpen: false,
  modalChildren: {},
};

export const modalSlice = createSlice({
  name: "modalControl",
  initialState,
  reducers: {
    toggleModal: (state, action) => {
      state.isModalOpen = !state.isModalOpen;
      if (action?.payload?.children)
        state.modalChildren = action.payload.children;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleModal } = modalSlice.actions;

export default modalSlice.reducer;
