import { useLocation } from "react-router-dom";

const imageResolver = (imageId) => {
  // Hit /api/v2/images/{imageId} and return
  //  the uri to the image.
  // The uri will be under keys meta->download_url.
};

const imageUrlReader = (imgObj) => {
  return process.env.REACT_APP_WAGTAIL_URL + imgObj?.meta?.download_url;
};

const internalUrlReader = (url) => {
  if (!url) return "";
  if (url.startsWith("https://internallink.com")) {
    const urlArr = url.split("/");
    return "/" + urlArr.slice(3).join("/");
  }
  return url;
};

const internalUrlReaderWithLangReg = (url, location) => {
  let newUrl = internalUrlReader(url);
  if (newUrl === "" || newUrl.includes("https://")) return newUrl;

  let path = location.split("/");
  return `${path[1]}/${path[2]}${newUrl}`;
};

export {
  imageResolver,
  imageUrlReader,
  internalUrlReader,
  internalUrlReaderWithLangReg,
};
