import React, { useEffect, useState } from "react";
import "components/TableRow/TableRow.scss";
import ChannelCheckmark from "assets/Icons/ChannelCheckmark";
import { WagtailImage } from "components/Wagtail/WagtailImage";

const TableRow = ({ list }) => {
  return list?.map((data) => {
    return (
      <tr>
        <td className="table-row-image-container">
          <img src={data.logoUrl} />

          <p>{data?.name}</p>
        </td>
        <td>
          <p>{data?.channelNumber}</p>
        </td>
        <td>{data.isSkinny ? <ChannelCheckmark /> : null}</td>
        <td>{data?.isEssentials ? <ChannelCheckmark /> : null}</td>
        <td>{data?.isAlaCarte ? <ChannelCheckmark /> : null}</td>
        <td>
          {data?.themePacks.map((theme, index) => {
            if (index + 1 === data?.themePacks.length) {
              return <p>{theme}</p>;
            } else {
              return <p>{theme},</p>;
            }
          })}
        </td>
      </tr>
    );
  });
};

export default TableRow;
