import { createApi } from "@reduxjs/toolkit/query/react";
import { baseSearch, supportSearch } from "api/config";

const localeDict = {
  en: "en-CA",
  fr: "fr-CA",
};

export const Search = createApi({
  reducerPath: "Search",
  baseQuery: baseSearch,
  endpoints: (builder) => ({
    getSearch: builder.query({
      query: ({ searchQuery, localePath, region, page }) => {
        const locale = localeDict[localePath];
        return {
          url: `?query=${searchQuery}&locale=${locale}&page=${page || 1}`,
        };
      },
    }),
  }),
});

export const SupportSearch = createApi({
  reducerPath: "SupportSearch",
  baseQuery: supportSearch,
  endpoints: (builder) => ({
    getSupportSearch: builder.query({
      query: ({ searchQuery, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?query=${searchQuery}&locale=${locale}`,
        };
      },
    }),
  })
})

export const { useGetSearchQuery } = Search;
export const { useGetSupportSearchQuery, useLazyGetSupportSearchQuery } = SupportSearch;
