import React from "react";
import "components/OrderPage/InstallationDate/InstallationDate.scss";
import { useDispatch, useSelector } from "react-redux";
import { stepMinus } from "store/reducers/order/orderSlice";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";

const InstallDate = () => {
  const dispatch = useDispatch();
  const { installationDate, installationTime } = useSelector(
    (state) => state.orderSlice.order
  );
  const { localization } = useSelector((state) => state.localizationSlice);
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  return (
    <div className="yourAddress_container m1">
      <span tabIndex="0">
        {data ? <h4>{data?.step_3_installation_date_text}</h4> : null}
        {installationDate && installationTime ? (
          <p>
            {dayjs(installationDate)
              .locale(localization)
              .format("dddd, MMMM DD")}{" "}
            • {installationTime}
          </p>
        ) : null}
      </span>
      <button onClick={() => { dispatch(stepMinus());}} className="Wbold">
        {data?.step_3_form_edit_btn_txt}
      </button>
    </div>
  );
};

export default InstallDate;
