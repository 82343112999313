import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.819 20a1.297 1.297 0 0 1-.508-.098 1.262 1.262 0 0 1-.424-.286L.379 13.119A1.232 1.232 0 0 1 0 12.234c0-.332.136-.65.379-.885.242-.235.571-.367.915-.367.343 0 .672.132.914.367l5.37 5.41L17.675.494a1.3 1.3 0 0 1 .773-.468c.308-.06.628-.01.9.141.273.151.48.393.581.68.102.288.092.602-.027.883l-10.98 17.67a1.308 1.308 0 0 1-.412.407c-.167.104-.357.17-.554.194h-.138Z"
      fill="#201F1F"
    />
  </svg>
);

export default SvgComponent;
