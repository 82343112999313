const { baseBeanfieldQuery } = require("api/config");
const { createApi } = require("@reduxjs/toolkit/query/react");

export const OrderPageApi = createApi({
  reducerPath: "OrderPageApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getInternetPackages: builder.query({
      query: ({ address_id }) => {
        return {
          url: `/v2/definitions/internet/${address_id}`,
          method: "GET",
        };
      },
    }),
    getTvPackages: builder.query({
      query: ({ address_id }) => {
        return {
          url: `/v2/definitions/iptv/${address_id}`,
          method: "GET",
        };
      },
    }),
    getHomePhonePackages: builder.query({
      query: ({ address_id }) => {
        return {
          url: `/v2/definitions/phone/${address_id}`,
          method: "GET",
        };
      },
    }),
    getLongDistanceRates: builder.query({
      query: () => ({
        url: "/v1/residential/voip/long-distance/rate-groups",
        method: "GET",
      }),
    }),
    getAvailableTimeSlots: builder.query({
      query: ({ address_id, startDate, endDate, unitNumber, token }) => ({
        url: `/v2/order/available-install-time-periods/${address_id}?start_date=${startDate}&end_date=${endDate}&unit=${unitNumber}&token=${token}`,
        method: "GET",
      }),
    }),
    getSpecificTimeSlot: builder.query({
      query: ({ address_id, startDate, endDate, unitNumber }) => ({
        url: `/v2/order/available-install-time-periods/${address_id}?start_date=${startDate}&end_date=${endDate}&unit=${unitNumber}`,
        method: "GET",
      }),
    }),
    verifyEmail: builder.mutation({
      query: ({ email }) => ({
        url: "/v2/order/email-verification/send",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    verificationCode: builder.mutation({
      query: ({ email, code }) => ({
        url: "/v2/order/email-verification/verify",
        method: "POST",
        body: {
          email,
          code,
        },
      }),
    }),
    getAutoPayToken: builder.query({
      query: () => ({
        url: "/v2/order/autopay-token",
        method: "GET",
      }),
    }),
    verifyInstallationDateTime: builder.mutation({
      query: ({ installationDate, installationTime, token, customer }) => {
        return {
          url: "/v2/order/verify-install-time",
          method: "POST",
          body: {
            customer: {
              address_id: customer?.address_id,
              unit: customer?.unit,
            },
            preferred_date: installationDate,
            preferred_time_period: installationTime,
            token: token,
          },
        };
      },
    }),
    submitOrder: builder.mutation({
      query: (finalObject) => {
        return {
          url: "/v2/order",
          method: "POST",
          body: finalObject,
        };
      },
    }),
  }),
});

export const {
  useGetInternetPackagesQuery,
  useLazyGetInternetPackagesQuery,
  useGetTvPackagesQuery,
  useLazyGetAvailableTimeSlotsQuery,
  useLazyGetSpecificTimeSlotQuery,
  useGetLongDistanceRatesQuery,
  useLazyGetLongDistanceRatesQuery,
  useGetHomePhonePackagesQuery,
  useVerifyEmailMutation,
  useVerificationCodeMutation,
  useGetAutoPayTokenQuery,
  useVerifyInstallationDateTimeMutation,
  useSubmitOrderMutation,
} = OrderPageApi;
