import React from "react";
import "components/Modals/Thanks/Thanks.scss";
import TwitterIcon from "assets/socialIcons/Twitter";
import FacebookIcon from "assets/socialIcons/Facebook";
import InstagramIcon from "assets/socialIcons/Instagram";
import LinkedinIcon from "assets/socialIcons/Linkedin";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router-dom";

const ThanksModal = ({ thanks }) => {
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);

  return (
    <div className="thanks-modal-container">
      <div className="thanks-modal-header-container">
        <h2>
          {thanks
            ? cmsData?.modal_int_thanks_header
            : cmsData?.modal_fbip_complete_header}
        </h2>
        <p>
          {thanks
            ? cmsData?.modal_int_thanks_description
            : cmsData?.modal_fbip_complete_description}
        </p>
      </div>

      <div className="thanks-modal-social-container">
        <h3>{cmsData?.modal_fbip_follow_us_text}</h3>

        <div className="thanks-modal-social-icon-container">
          <a
            target="_blank"
            href={`${cmsData?.modal_thanks_tw_link}`}
            className="social-icon"
          >
            <TwitterIcon />
          </a>

          <a
            target="_blank"
            href={`${cmsData?.modal_thanks_fb_link}`}
            className="social-icon"
          >
            <FacebookIcon />
          </a>

          <a
            target="_blank"
            href={`${cmsData?.modal_thanks_in_link}`}
            className="social-icon"
          >
            <InstagramIcon />
          </a>

          <a
            target="_blank"
            href={`${cmsData?.modal_thanks_li_link}`}
            className="social-icon"
          >
            <LinkedinIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThanksModal;
