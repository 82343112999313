import React from "react";
import "components/OrderPage/TotalOrder/TotalOrder.scss";
import { useSelector } from "react-redux";
import { minTwoDigits } from "utils/twoDigitsAtTheEnd";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";

const TotalOrder = () => {
  const { order } = useSelector((state) => state.orderSlice);
  const { province } = useSelector(
    (state) => state.orderSlice.userInfo
  );
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  return (
    <div className="orderPage_right_total_wrapper" tabIndex="0">
      <h2>{data?.order_summary_header}</h2>
      {/* INTERNET SECTION */}
      <>
        <p className="Wbold">{data?.order_summary_internet_title}</p>
        <span>
          <p>{order?.internet?.title}</p>
          <p>${order?.internet?.price}</p>
        </span>
        <span>
          <p>{data?.order_summary_internet_router}</p>
          <p>{data?.order_included_title}</p>
        </span>
        {province !== "BC" ? (
          <span>
            <p>{data?.order_summary_internet_access_point}</p>
            <p>{data?.order_included_title}</p>
          </span>
        ) : null}
        <span>
          <p>{data?.order_summary_internet_access_installation}</p>
          <p>{data?.order_included_title}</p>
        </span>
        <div className="orderPage_right_deviderLine" />
        {order?.internet?.price ? (
          <span>
            <p className="Wbold">{data?.order_summary_internet_total}</p>
            <p className="Wbold">${order?.internet?.price?.toFixed(2)}</p>
          </span>
        ) : null}
      </>
      {/* TV SECTION */}
      {order?.tv?.price ? (
        <>
          <div className="orderPage_right_deviderLine" />
          <div>
            <p className="Wbold">TV</p>
            <span>
              <p>{order?.tv?.title}</p>
              <p>${order?.tv?.price}</p>
            </span>
            <span>
              <p>{data?.order_summary_tv_top_box}</p>
              <p>{data?.order_included_title}</p>
            </span>
            {/* TOP BOXES */}
            {order?.tv?.topBoxes?.selected > 0 ? (
              <span>
                <p>
                  {data?.order_summary_tv_top_box_additional} (
                  {order?.tv?.topBoxes?.selected})
                </p>
                <p>${order?.tv?.topBoxes?.price}</p>
              </span>
            ) : null}
            <span>
              <p>{data?.order_summary_tv_pvr_storage}</p>
              <p>{data?.order_included_title}</p>
            </span>
            {/* THEME PACKS */}
            {order?.tv?.themePacks?.selected?.length > 0 ? (
              <span>
                <p>
                  {data?.order_summary_tv_theme_packs} (
                  {order?.tv?.themePacks?.selected?.length})
                </p>
                <p>${order?.tv?.themePacks?.price}</p>
              </span>
            ) : null}
            {/* CHANNELS */}
            {order?.tv?.channels?.selected?.length > 0 ? (
              <span>
                <p>
                  {data?.order_summary_tv_alacarte_channels} (
                  {order?.tv?.channels?.selected?.length})
                </p>
                <p>${order?.tv?.channels?.price?.toFixed(2)}</p>
              </span>
            ) : null}
          </div>
          <div className="orderPage_right_deviderLine" />
          <span>
            <p className="Wbold">{data?.order_summary_tv_total}</p>
            <p className="Wbold">
              $
              {(order?.tv?.price +
                order?.tv?.channels?.price +
                order?.tv?.topBoxes?.price +
                order?.tv?.themePacks?.price)?.toFixed(2)}
            </p>
          </span>
        </>
      ) : null}
      {/* HOME PHONE SECTION */}
      {order?.homePhone?.price ? (
        <>
          <div className="orderPage_right_deviderLine" />
          <div>
            <p className="Wbold">{data?.order_summary_phone_title}</p>
            <span>
              <p>Standard</p>
              <p>${order?.homePhone?.price?.toFixed(2)}</p>
            </span>
          </div>
          <div className="orderPage_right_deviderLine" />
          <span>
            <p className="Wbold">{data?.order_summary_phone_total}</p>
            <p className="Wbold">${order?.homePhone?.price?.toFixed(2)}</p>
          </span>
        </>
      ) : null}
      {/* ORDER TOTAL AND TAX */}
      <>
        <div className="orderPage_right_deviderLine" />
        <span>
          <p className="Wbold">{data?.order_subtotal_title}</p>
          <p className="Wbold">${order?.subTotal?.toFixed(2)}</p>
        </span>
        {province === "BC" ? (
          <>
            <span>
              <p>{data?.order_gst}</p>
              <p>${order?.gst?.toFixed(2)}</p>
            </span>
            <span>
              <p>{data?.order_pst}</p>
              <p>${order?.pst?.toFixed(2)}</p>
            </span>
          </>
        ) : null }

        {province === "QC" ? (
          <>
            <span>
              <p>{data?.order_gst}</p>
              <p>${order?.gst?.toFixed(2)}</p>
            </span>
            <span>
              <p>{data?.order_qst}</p>
              <p>${order?.qst?.toFixed(2)}</p>
            </span>
          </>
        ) : null }
        {province === "ON" ? (
          <>
            <span>
              <p>{data?.order_hst}</p>
              <p>{order?.hst?.toFixed(2) || 0}</p>
            </span>
          </>
        ) : null }
        <div className="orderPage_right_deviderLine" />
        <span>
          <h2>{data?.total_txt}</h2>
          <h2>
            ${order?.total?.toFixed(2)}
            {data?.total_sfx}
          </h2>
        </span>
      </>
    </div>
  );
};

export default TotalOrder;
