import React from "react";
import "components/OrderPage/MobileSteps/MobileSteps.scss";
import { useSelector } from "react-redux";
import OrderCheckMark from "assets/svg/OrderCheckMark";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";

const MobileSteps = ({ activeStep, children }) => {
  //PAGE DATA
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  const steps = [
    { stepCount: 1, stepTitle: data?.step_1_h },
    { stepCount: 2, stepTitle: data?.step_2_h },
    { stepCount: 3, stepTitle: data?.step_3_h },
    { stepCount: 4, stepTitle: data?.step_4_h },
    { stepCount: 5, stepTitle: data?.step_5_h },
  ];

  const generateSteps = () => {
    return (
      <>
        {/* COMPLETED STEPS */}
        {activeStep > 0 ? (
          <>
            <div
              className={`step_two_completed_mobile_container height_completed_${activeStep}`}
            >
              {steps.map((step) => {
                if (activeStep !== 4) {
                  if (step.stepCount > activeStep + 1) return null;
                  if (step.stepCount === activeStep + 1) return null;
                }
                return (
                  <div className="Wbold completed_step_wrapper">
                    <span className="step_number_complete">
                      <OrderCheckMark />
                    </span>
                    <span className="completed_step_text">
                      {step.stepTitle}
                    </span>
                  </div>
                );
              })}
            </div>
            {activeStep == 4 ? (
              <div style={{ paddingTop: "2.25rem" }}>{children}</div>
            ) : null}
          </>
        ) : null}
        {/* ACTIVE STEP */}
        {activeStep + 1 === steps[activeStep].stepCount && activeStep !== 4 ? (
          <div className="step_one_mobile_container">
            <div
              className={`Wbold active_step_wrapper padding_top_${activeStep}`}
            >
              <div
                className={`active_step_title_wrapper margin_top_${activeStep}`}
              >
                <span className="step_number">
                  {steps[activeStep].stepCount}
                </span>
                <span style={{ marginLeft: "0.5rem" }}>
                  {steps[activeStep].stepTitle}
                </span>
              </div>
              {children}
            </div>
          </div>
        ) : null}
        {/* INACTIVE STEPS */}
        <div
          className={`step_two_inactive_mobile_container height_${activeStep}`}
        >
          {steps.map((step) => {
            if (step.stepCount === activeStep + 1) return null;
            if (step.stepCount < activeStep + 1) return null;
            return (
              <div className="Wbold inactive_step_wrapper">
                <span className="inactive_step_number">{step.stepCount}</span>
                <span className="inactive_step_text">{step.stepTitle}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return <div>{generateSteps()}</div>;
};

export default MobileSteps;
