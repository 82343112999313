import { createMedia } from "@artsy/fresnel";
const BreakPoints = createMedia({
  breakpoints: {
    smallMobile: 0,
    largeMobile: 480,
    tablet: 768,
    desktop: 1024,
  },
});

// Generate CSS to be injected into the head
export const mediaStyle = BreakPoints.createMediaStyle();
export const { Media, MediaContextProvider } = BreakPoints;
