import "components/reusable/SingleChoice/SingleChoice.scss";


const SingleChoice = ({ name , question, number, children, error, classes }) => {
  const classNames = `single-choice-container ${error? "error": ''} ${classes}`;
  return (
    <div className={classNames} key={name}>
       <div className="question">
           {number? <span>{number}.</span>: null }
           <span>{ question }</span>
       </div>
        { error ? <div className="error">{error}</div>: null }
       <div className="answers">
           {children}
       </div>
    </div>
  );
};

export default SingleChoice;