import React from "react";
import "pages/HowWeHire/HowWeHire.scss";
import Button from "components/reusable/Button/Button";
import {
  ControlledAccordion,
  useAccordionProvider,
} from "@szhsin/react-accordion";
import { HowWeHireAccordion } from "components/SupportAccordion/SupportAccordion";

import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import Head from "components/reusable/Head/Head";

const HowWeHire = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetHowWeHirePageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const accordianValue = useAccordionProvider({
    allowMultiple: false,
    transition: true,
    transitionTimeout: 200,
  });
  const { toggle } = accordianValue;

  const btnUrl = "https://recruiting.ultipro.ca/BEA5000BEAN/JobBoard/d0d559da-ea8c-4302-9f29-633b74dbebee/?q=&o=postedDateDesc&w=&wc=&we=&wpst=";

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Notre méthode d'embauche | Beanfield" :
          "How We Hire | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Nous recherchons des personnes talentueuses qui apportent leurs compétences uniques pour rejoindre l'équipe de Beanfield. En savoir plus et postuler dès aujourd'hui." :
          "Seeking talented people who bring their unique skills to join the Beanfield team. Learn more & apply today."
        }
        imageName={"HowWeHire"}
        locale={useLocation().pathname.split("/")[1]}
        />
      <div className="how-we-hire-container">
        <div className="how-we-hire-header">
          <div>
            <h1>{info?.title}</h1>
            {convertHtmlToReact(info?.header_body)}
            <Button click={() => window.open(info?.header_btn_url || btnUrl)}>
              {info?.header_btn}
            </Button>
          </div>

          <WagtailImage image={info?.header_img} />
        </div>

        <div className="how-we-hire-process">
          {convertHtmlToReact(info?.process_body)}
        </div>

        <div className="how-we-hire-steps">
          <div className="how-we-hire-step-container">
            <WagtailImage
              className="how-we-hire-desktop-number"
              image={info?.process_1_img_desktop}
              height={174}
              width={174}
            />
            <WagtailImage
              className="how-we-hire-mobile-number"
              image={info?.process_1_img_mobile}
              height={70}
              width={70}
            />

            <div className="how-we-hire-text">
              {convertHtmlToReact(info?.process_1_body)}
            </div>
          </div>

          <div className="how-we-hire-step-container">
            <WagtailImage
              className="how-we-hire-desktop-number"
              image={info?.process_2_img_desktop}
              height={174}
              width={174}
            />
            <WagtailImage
              className="how-we-hire-mobile-number"
              image={info?.process_2_img_mobile}
              height={70}
              width={70}
            />

            <div className="how-we-hire-text">
              {convertHtmlToReact(info?.process_2_body)}
            </div>
          </div>

          <div className="how-we-hire-step-container">
            <WagtailImage
              className="how-we-hire-desktop-number"
              image={info?.process_3_img_desktop}
              height={174}
              width={174}
            />
            <WagtailImage
              className="how-we-hire-mobile-number"
              image={info?.process_3_img_mobile}
              height={70}
              width={70}
            />

            <div className="how-we-hire-text">
              {convertHtmlToReact(info?.process_3_body)}
            </div>
          </div>

          <div className="how-we-hire-step-container">
            <WagtailImage
              className="how-we-hire-desktop-number"
              image={info?.process_4_img_desktop}
              height={174}
              width={174}
            />
            <WagtailImage
              className="how-we-hire-mobile-number"
              image={info?.process_4_img_mobile}
              height={70}
              width={70}
            />

            <div className="how-we-hire-text" aria-border>
              {convertHtmlToReact(info?.process_4_body)}
            </div>
          </div>
        </div>

        <div className="how-we-hire-positions">
          {convertHtmlToReact(info?.apply_body)}

          <Button
            click={() => window.open(btnUrl)}
            style={{ width: 300 }}
          >
            {info?.apply_btn}
          </Button>
        </div>

        <div className="how-we-hire-faq">
          <h2>{info?.faq_header}</h2>

          <ControlledAccordion providerValue={accordianValue}>
            {info?.faqs?.length > 0 &&
            info.faqs
              .map((item) => {
                return {
                  title: item.title,
                  content: convertHtmlToReact(item.body),
                };
              })
              .map((item) => {
                return <HowWeHireAccordion item={item} toggle={toggle} />;
              })}
          </ControlledAccordion>
        </div>
      </div>
    </div>

  );
};

export default HowWeHire;
