import { createApi } from "@reduxjs/toolkit/query/react";
import { baseBeanfieldQuery } from "api/config";

export const ChannelListApi = createApi({
  reducerPath: "ChannelListApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getChannelList: builder.query({
      query: () => {
        return {
          url: `/v1/residential/iptv/channels`,
          method: "GET",
        };
      },
    }),
    getPremiumPackage: builder.query({
      query: () => {
        return {
          url: `/v1/residential/iptv/package/skinny-basic`,
          method: "GET",
        };
      },
    }),
    getBasicPackage: builder.query({
      query: () => {
        return {
          url: `/v1/residential/iptv/package/essentials`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetChannelListQuery,
  useLazyGetChannelListQuery,
  useLazyGetPremiumPackageQuery,
  useLazyGetBasicPackageQuery,
  useGetBasicPackageQuery,
  useGetPremiumPackageQuery,
} = ChannelListApi;
