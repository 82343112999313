import { createApi } from "@reduxjs/toolkit/query/react";
import { baseBeanfieldQuery } from "api/config";

export const InternetAvailabilityApi = createApi({
  reducerPath: "InternetAvailabilityApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    checkInternetAvailability: builder.query({
      query: ({ addressText }) => {
        return {
          url: `/v1/search/address?q=${addressText}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useCheckInternetAvailabilityQuery,
  useLazyCheckInternetAvailabilityQuery,
} = InternetAvailabilityApi;
