import * as React from "react";

const SvgComponent = (props) => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M29.42 31.76 21.66 24l7.76-7.76c.78-.78.78-2.04 0-2.82-.78-.78-2.04-.78-2.82 0l-9.18 9.18c-.78.78-.78 2.04 0 2.82l9.18 9.18c.78.78 2.04.78 2.82 0 .76-.78.78-2.06 0-2.84Z"
          fill="#325a32"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="rotate(90 24 24)" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgComponent;
