// key: url piece
// value: api locale query
export const localeDict = {
    en: "en-CA",
    fr: "fr-CA",
};

// key: url piece
// value: api region query
export const regionDict = {
    bc: 'bc',
    on: 'on',
    qc: 'qc',
    tor: 'tor',
    mtl: 'mtl',
    van: 'van',
};