import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseBeanfieldQuery } from "api/config";

export const GeolocationApi = createApi({
  reducerPath: "GeolocationApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getGeolocation: builder.query({
      query: ({ latitude, longitude }) => ({
        url: "/v2/geolocation",
        method: "GET",
        params: {
          latitude,
          longitude
        },
      }),
    }),
  }),
});

export const {
  useGetGeolocationQuery,
  useLazyGetGeolocationQuery,
} = GeolocationApi;