import React, { useState } from "react";
import "components/Modals/Questionnaire/SlideTwo/SlideTwo.scss";
import Button from "components/reusable/Button/Button";
import EmailModal from "components/Modals/Email/Email";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import FormModal from "components/Modals/Form/Form";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import RadioBtn from "components/reusable/Radio/RadioBtn";

const SlideTwoModal = ({ address, unit }) => {
  const dispatch = useDispatch();
  const [questionOne, setQuestionOne] = useState(null);
  const [questionTwo, setQuestionTwo] = useState(null);
  const [questionThree, setQuestionThree] = useState(null);
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);

  const handleSubmit = () => {
    dispatch(toggleModal());

    if (
      questionOne === "true" &&
      questionTwo === "true" &&
      questionThree === "true"
    ) {
      dispatch(toggleModal({ children: <EmailModal /> }));
    } else {
      dispatch(
        toggleModal({
          children: <FormModal editAddress={address} unit={unit}/>,
        })
      );
    }
  };

  return (
    <div className="questionnaire-slide-two-modal-container">
      {convertHtmlToReact(cmsData?.modal_ndb_second_modal_header)}

      <div>
        {convertHtmlToReact(cmsData?.modal_ndb_second_modal_q1_title)}

        <div className="slide-two-question-container">
          <div className="radio_btn_wrapper">
            <RadioBtn
              id="yesOne"
              name="questionOne"
              value="true"
              onClick={(e) => setQuestionOne(e.target.value)}
            />
            <label htmlFor="yesOne">
              {cmsData?.modal_ndb_second_modal_q1_answer1}
            </label>
          </div>

          <div className="radio_btn_wrapper">
            <RadioBtn
              id="noOneOne"
              name="questionOne"
              value="false"
              onClick={(e) => setQuestionOne(e.target.value)}
            />
            <label htmlFor="noOneOne">
              {cmsData?.modal_ndb_second_modal_q1_answer2}
            </label>
          </div>

          <div className="radio_btn_wrapper">
            <RadioBtn
              id="notsureOne"
              name="questionOne"
              value="true"
              onClick={(e) => setQuestionOne(e.target.value)}
            />
            <label htmlFor="notsureOne">
              {cmsData?.modal_ndb_second_modal_q1_answer3}
            </label>
          </div>
        </div>
      </div>

      <div>
        {convertHtmlToReact(cmsData?.modal_ndb_second_modal_q2_title)}

        <div className="slide-two-question-container">
          <div className="radio_btn_wrapper">
            <RadioBtn
              id="yesTwo"
              name="questionTwo"
              value="true"
              onClick={(e) => setQuestionTwo(e.target.value)}
            />
            <label htmlFor="yesTwo">
              {cmsData?.modal_ndb_second_modal_q2_answer1}
            </label>
          </div>

          <div className="radio_btn_wrapper">
            <RadioBtn
              id="noTwo"
              name="questionTwo"
              value="false"
              onClick={(e) => setQuestionTwo(e.target.value)}
            />
            <label htmlFor="noTwo">
              {cmsData?.modal_ndb_second_modal_q2_answer2}
            </label>
          </div>

          <div className="radio_btn_wrapper">
            <RadioBtn
              value="true"
              id="notsureTwo"
              name="questionTwo"
              onClick={(e) => setQuestionTwo(e.target.value)}
            />
            <label htmlFor="notsureTwo">
              {cmsData?.modal_ndb_second_modal_q2_answer3}
            </label>
          </div>
        </div>
      </div>

      <div>
        {convertHtmlToReact(cmsData?.modal_ndb_second_modal_q3_title)}

        <div className="slide-two-question-container">
          <div className="radio_btn_wrapper">
            <RadioBtn
              value="true"
              id="yesThree"
              name="questionThree"
              onClick={(e) => setQuestionThree(e.target.value)}
            />
            <label htmlFor="yesThree">
              {" "}
              {cmsData?.modal_ndb_second_modal_q3_answer1}
            </label>
          </div>

          <div className="radio_btn_wrapper">
            <RadioBtn
              value="false"
              id="noThree"
              name="questionThree"
              onClick={(e) => setQuestionThree(e.target.value)}
            />
            <label htmlFor="noThree">
              {" "}
              {cmsData?.modal_ndb_second_modal_q3_answer2}
            </label>
          </div>
        </div>
      </div>

      <Button
        style={{ minWidth: "9.375rem" }}
        disabled={
          questionOne === null || questionTwo === null || questionThree === null
            ? true
            : false
        }
        click={() => handleSubmit()}
      >
        {cmsData?.modal_ndb_second_modal_submit_btn}
      </Button>
    </div>
  );
};

export default SlideTwoModal;
