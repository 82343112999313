import React, { useEffect, useRef } from "react";
import "components/Modals/Success/Success.scss";
import Button from "components/reusable/Button/Button";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import AddressModal from "components/Modals/Address/Address";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import ExclamationMark from "assets/svg/ExclamationMark";
import ToolTip from "components/reusable/Tooltip/Tooltip";
import {processWagtailImage, WagtailImage} from "components/Wagtail/WagtailImage";
import {usePagesApiParser} from "services/hooks/PagesAPIParser";
import {getQueries} from "api/wagtailAdapter";

const SuccessModal = ({
  province,
  address,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const successModalRef = useRef(null);
  const baseLink = location.pathname.split("/");
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const provinceData = {
    bc: {
      name: "Metro Vancouver",
      region: "van",
    },
    on: {
      name: "Toronto/GTA",
      region: "tor",
    },
    qc: {
      name: "Montréal Area",
      region: "mtl",
    },
  };

  const provinceDataFR = {
    bc: {
      name: "Région de Vancouver",
      region: "van",
    },
    on: {
      name: "Région de Toronto",
      region: "tor",
    },
    qc: {
      name: "Région de Montréal",
      region: "mtl",
    },
  };

  const handleReturn = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <AddressModal /> }));
  };

  const handleStart = () => {
    dispatch(toggleModal());
    navigate(`/${baseLink[1]}/${baseLink[2]}/residential-order`);
  };

  const handleSwitchRegion = () => {
    dispatch(toggleModal());
    const region =
      baseLink[1] === "fr"
        ? provinceDataFR[province].region
        : provinceData[province].region;
    navigate(`/${baseLink[1]}/${region}/residential`);
  };

  const handleShowRegionWarning = () => {
    const region =
      baseLink[1] === "fr"
        ? provinceDataFR[province].region
        : provinceData[province].region;
    if (region !== baseLink[2]) {
      return true;
    }
  };

  const switchRegion = () => {
    return (
      <div
        style={{ justifyContent: "flex-start" }}
        className="switch-region-container"
      >
        <div className="left-content">
          <ExclamationMark />
        </div>
        <div className="right-content">
          <p style={{ flex: 1 }}>
            {cmsData?.modal_confirmation_switch_region_text}{" "}
            {baseLink[1] === "fr"
              ? provinceDataFR[province].name
              : provinceData[province].name}
            ,{" "}
            <span onClick={() => handleSwitchRegion()}>
              {cmsData?.modal_confirmation_switch_region_link_text}
            </span>
            .
          </p>
        </div>
      </div>
    );
  };

  const checkBlackFridayPromo = () => {
    return cmsData?.main_carousel[0].value.use_black_friday_promo_layout
  }

  const checkIsXGSPON = () => {
    return address?.accessPlatforms?.includes('XGS-PON')
  }

  useEffect(() => {
    successModalRef?.current?.getElementsByClassName("success-container-header")[0].focus()
  },[successModalRef])

  //CMS API CALL
  const { page: cmsData } = usePagesApiParser(
    getQueries(useLocation().search).useGetHomePageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  return (
    <>
      <div ref={successModalRef} className="success-modal-container">
        <div className="success-container-header" tabIndex="0">
          {convertHtmlToReact(cmsData?.modal_confirmation_header)}
        </div>
          {province !== 'bc' ? (
            <>
              <div className="address-info" tabIndex="0">
                {
                  cmsData?.modal_confirmation_following_services_available_txt?.includes("{address}") ? (
                    convertHtmlToReact(
                      cmsData?.modal_confirmation_following_services_available_txt?.split("{address}")[0] +
                      `<strong>${address.streetAddress}</strong>` +
                      cmsData?.modal_confirmation_following_services_available_txt?.split("{address}")[1]
                    )
                  ) : null
                }
              </div>
              <div className={
                (checkBlackFridayPromo() && width < 768 && checkIsXGSPON())
                ? "packages-container black-friday-promo xgspon"
                : (checkBlackFridayPromo() && width < 768 && !checkIsXGSPON())
                ? "packages-container black-friday-promo gpon"
                : "packages-container"
              }>
                {cmsData?.packages.map((pkg, i) => {
                  return (
                    <div className="package-box-container" key={i}>
                      {((i === 0 && !checkIsXGSPON())
                        || (i === 2 && checkIsXGSPON())) ? (
                        <div
                          className={
                            (checkBlackFridayPromo() && width < 768)
                            ? "package-box black-friday-promo"
                            : "package-box"
                          }
                          style={
                            (checkBlackFridayPromo() && width < 768)
                            ? { backgroundImage: `url(${processWagtailImage(
                                checkIsXGSPON() 
                                  ? cmsData?.modal_promo_xgspon_mobile_image
                                  : cmsData?.modal_promo_gpon_mobile_image
                              )})`}
                            : {}
                          }
                          tabIndex="0">
                          <div className={
                            (checkBlackFridayPromo() && width < 768)
                            ? "box-header black-friday-promo"
                            : "box-header"
                          }>
                            {pkg?.title}
                          </div>
                          <div className={
                            (checkBlackFridayPromo() && width < 768)
                            ? "box-body black-friday-promo"
                            : "box-body"
                          }>
                            <span className="Wbold">{pkg?.description}</span>
                            {pkg?.tooltip ? (
                              <ToolTip
                                contentStyles={{left: width > 768 ? 0 : "-11.5rem", top: -15, override: true}}
                                helperStyles={{top: 0, left: 15}}
                                iconStyles={{marginBottom: "0", marginTop: "0"}}
                              >
                                <p>{pkg.tooltip}</p>
                              </ToolTip>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div className="package-box" tabIndex="0">
                          {checkIsXGSPON() ? (
                            <>
                              <div className="box-header">
                                {pkg?.title}
                              </div>
                              <div className="box-body">
                                <span className="Wbold">{pkg?.description}</span>
                                {pkg?.tooltip ? (
                                  <ToolTip
                                    contentStyles={{left: width > 768 ? 0 : "-11.5rem", top: -15, override: true}}
                                    helperStyles={{top: 0, left: 15}}
                                    iconStyles={{marginBottom: "0", marginTop: "0"}}
                                  >
                                    <p>{pkg.tooltip}</p>
                                  </ToolTip>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="box-header coming-soon">
                                {pkg?.coming_soon_txt}
                              </div>
                              <div className="box-body coming-soon">
                                <span className="Wbold">{pkg?.description}</span>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="extra-services-info">
                <p tabIndex="0">{convertHtmlToReact(cmsData?.modal_confirmation_tv_home_phone_available_txt)}</p>
              </div>
              <div className="success-container">
                <Button click={() => handleStart()}>
                  {cmsData?.modal_confirmation_start_order_btn_text}
                </Button>
                <p onClick={() => handleReturn()} className="Wbold success-modal-text" tabIndex="0">
                  {cmsData?.modal_confirmation_change_address_link_text}
                </p>
              </div>
              {handleShowRegionWarning() ? (
                <div className="success-container-footer">
                  {switchRegion()}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="success-container">
                <Button click={() => handleStart()}>
                  {cmsData?.modal_confirmation_start_order_btn_text}
                </Button>

                <p onClick={() => handleReturn()} className="Wbold success-modal-text">
                {cmsData?.modal_confirmation_change_address_link_text}
                </p>
              </div>
              {handleShowRegionWarning() ? (
                <div className="success-container-footer">
                  {switchRegion()}
                </div>
              ) : null}
            </>
          )}
      </div>
      {(cmsData?.main_carousel[0].value.use_black_friday_promo_layout && width > 768) ? (
        <div className="promo-container">
          <div className="promo-image-container">
            {address?.accessPlatforms?.includes('XGS-PON') ? (
              <WagtailImage image={cmsData?.modal_promo_xgspon_image}/>
            ) : (
              <WagtailImage image={cmsData?.modal_promo_gpon_image}/>
            )}
          </div>
          <div className="offset-border"></div>
        </div>
      ) : null}
    </>
  );
};

export default SuccessModal;
