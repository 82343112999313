import * as React from "react";

const SvgComponent = (props) => {
  return (
    <svg
      style={{ margin: "0 5px" }}
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={6}
        cy={6}
        r={5}
        fill="#fff"
        stroke="#4B4A4A"
        strokeWidth={2}
      />
    </svg>
  );
};

export default SvgComponent;
