import { useState, useEffect } from "react";

//COMPONENT IS USED FOR SEARCHING BUILDINGS ON AVAILABILITY MAP
//IT SETS THE SEARCH TERM AND FILTERS BUILDINGS BASED ON THE SEARCH TERM
const useSearch = (buildings) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const [selectedForFilter, setSelectedForFilter] = useState(null);
  const [selectedAddressForService, setSelectedAddressForService] =
    useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [buildingOffline, setBuildingOffline] = useState(false);

  // Filter buildings based on search term
  const applySearchFilter = (term) => {
    if (term.trim() === "") {
      setSelectedForFilter(null);
      return [];
    }
    if (selectedForFilter === true) {
      setSelectedForFilter(null);
    }
    const building = buildings.filter((building) => {
      const isAddressAMatch = building.addresses.some((address) =>
        address.addressText.toLowerCase().includes(term.toLowerCase())
      );

      const isBuildingNameAMatch = building.building
        .toLowerCase()
        .includes(term.toLowerCase());

      return isAddressAMatch || isBuildingNameAMatch;
    });
    return building.map(
      (building) => {
        return building.addresses.filter((address) =>
          address.addressText.toLowerCase().includes(term.toLowerCase())
        );
      }).flat();
  };

  // Debounce the search term to update filteredBuildings after a delay
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const filteredResults = applySearchFilter(searchTerm);
      setFilteredBuildings(filteredResults);
    }, 1000);

    return () => clearTimeout(debounceTimer); // Clear the timer on component unmount or when searchTerm changes
  }, [searchTerm]);

  // Handle search input change
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    if (!filteredBuildings || filteredBuildings?.length === 0) {
      setBuildingOffline(false);
    }
    setSearchTerm(newSearchTerm);
  };

  // Handle search input keypress or magnifying icon click
  const handleKeyPress = (event, magGlass = false) => {
    if (event.key === "Escape") {
      setSearchTerm("");
      setFilteredBuildings([]);
      setDropdownOpen(false);
      return;
    }

    if ((event.key === "Enter" || event.type === "click") && !magGlass) {
      if (searchTerm.trim() === "") {
        return;
      }
      const filteredResults = applySearchFilter(searchTerm);

      if (
        filteredResults?.length === 0 ||
        filteredResults[0]?.status === "Interested" ||
        filteredResults[0]?.status === "Currently Not On-Net"
      ) {
        setBuildingOffline(true);
        setSelectedAddressForService(searchTerm);
        return;
      }
      setFilteredBuildings(filteredResults);
      return;
    } else {
      setDropdownOpen(true);
      if (
        (!filteredBuildings || filteredBuildings?.length === 0) &&
        searchTerm?.trim()?.length > 0
      )
        // setBuildingOffline(true);

        return;
    }
  };

  return {
    searchTerm,
    handleSearchChange,
    filteredBuildings,
    handleKeyPress,
    selectedForFilter,
    setSelectedForFilter,
    dropdownOpen,
    setSelectedAddressForService,
    selectedAddressForService,
    setDropdownOpen,
    setSearchTerm,
    buildingOffline,
    setBuildingOffline,
  };
};

export default useSearch;
