import React from "react";
import "pages/RealityOfInternetSpeed/realityOfInternetSpeed.scss";
import { NavLink } from "react-router-dom";
import Caret from "assets/Icons/Caret";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";

import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import Head from "components/reusable/Head/Head";

const ListPanelItem = ({ header, body }) => {
  return (
    <li>
      <h2>{header}</h2>
      {body}
    </li>
  );
};

const RealityOfInternetSpeed = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetRealityOfSpeedPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const location = useLocation();

  return (
    <div className="realityOfTheInternet_container">
      <Head
        title={"Beanfield | Reality of Internet Speed"}
        description={"When more isn't more"}
        imageName={"RealityOfInternet"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="banner">{convertHtmlToReact(info?.header)}</div>
      <div className="realityOfTheInternet_content_container">
        <div className="realityOfTheInternet_fastTruth">
          {convertHtmlToReact(info?.main_section)}
        </div>

        <div className="realityOfTheInternet_whatsGiven">
          <h2>{info?.list_section_header}</h2>
          <ol>
            {info?.list_section?.length > 0 &&
              info.list_section
                .map((item) => {
                  return {
                    header: item.header,
                    body: convertHtmlToReact(item.body),
                  };
                })
                .map((item) => {
                  return <ListPanelItem {...item} />;
                })}
          </ol>
        </div>

        <div className="realityOfTheInternet_howMuchYouNeed">
          <h2>{info?.big_image_header}</h2>
          <WagtailImage
            style={{ maxWidth: "100%" }}
            image={info?.big_image_img}
          />
        </div>

        <div className="realityOfTheInternet_howDifferent">
          {convertHtmlToReact(info?.end_section)}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <NavLink
              style={{ marginRight: ".3125rem" }}
              to={`/${internalUrlReaderWithLangReg(
                info?.end_section_link,
                location.pathname
              )}`}
            >
              {info?.end_section_link_txt}{" "}
            </NavLink>
            <Caret
              containerStyle={{ marginTop: "0px" }}
              color="rgba(0, 102, 62, 1)"
              turn="right"
            />
          </div>
        </div>
      </div>

      <div className="realityOfTheInternet_contact_container">
        {info?.contact_us_page?.badges?.length > 0 && (
          <ContactInfo
            title={info.contact_us_page.title}
            description={info.contact_us_page.description}
            badges={info.contact_us_page.badges}
          />
        )}
      </div>
    </div>
  );
};

export default RealityOfInternetSpeed;
