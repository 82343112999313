import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.479 15.5.5 10.574 3.51 6.33l2.737 1.78L11.721.446 16.1 3.595 7.479 15.5Z"
      fill="#325a32"
    />
  </svg>
);

export default SvgComponent;
