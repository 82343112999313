export const setCookie = function(cookieName, cookieValue) {
  const d = new Date();
  d.setTime(d.getTime() + (60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = `${cookieName}=${cookieValue};` + expires + ";path=/";
};

export const getCookie = function(cookieName) {
  let name = cookieName + "=";
  let cookieParts = document.cookie.split(';');
  for(let i = 0; i < cookieParts.length; i++) {
    let cookie = cookieParts[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};