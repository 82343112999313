import "components/reusable/Radio/RadioBtn.scss";

const Select = ({ name, checked = false, onClick, style, value, id }) => {
  return (
    <div className="radioBtn_container" style={{ ...style }}>
      {value ? (
        <input
          id={id}
          value={value}
          onChange={(e) => {
            onClick(e);
          }}
          type="radio"
          name={name}
          checked={checked}
        />
      ) : (
        <input
          onChange={(e) => {
            onClick(e);
          }}
          checked={checked}
          type="radio"
          name={name}
        />
      )}

      <span className="checkmark_radio"></span>
    </div>
  );
};

export default Select;
