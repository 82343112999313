import React, { useState } from "react";
import "components/Header/Header.scss";
import "components/Header/Banner.scss";
import UserIcon from "assets/svg/User";
import Dropdown from "components/reusable/Dropdown/Dropdown";
import Carrot from "assets/Icons/Caret";
import MainLogo from "assets/svg/MainLogo";
import HamburgerMenu from "components/HamburgerMenu/HamburgerMenu";
import {
  ProvinceOptions,
  LangOptions,
  FRProvinceOptions,
} from "utils/DropDownData";
import Close from "assets/Icons/Close";
import Button from "components/reusable/Button/Button";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";
import AddressModal from "components/Modals/Address/Address";
import SearchInput from "components/Search/SearchBar/SearchInput";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { useRegionCheckModal } from "../../services/hooks/FilterNavBars";
import StartModal from "../reusable/Modal/StartModal";

const Header = () => {
  const { pathname, search } = useLocation();
  const isFrench = pathname.split("/")[1] === "fr" ? true : false;
  const {
    page: info
  } = usePagesApiParser(
    getQueries(search).useGetMainHeaderQuery({
      queryParams: search,
      localePath: pathname.split("/")[1],
      region: pathname.split("/")[2],
    })
  );
  const [isStartModalOpen, setIsStartModalOpen] = useState(
    useRegionCheckModal()
  );
  const [isRegionOpen, setIsRegionOpen] = useState(false);
  const [closed, setClosed] = useState(true);
  const [disableClose, setDisableClose] = useState(false);
  const dispatch = useDispatch();
  let baseLink = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port !== 80) baseLink += `:${window.location.port}`;
  baseLink += "/" + pathname.split("/")[1] + "/" + pathname.split("/")[2];

  const links = {
    internet: baseLink + "/residential/internet",
    tv: baseLink + "/residential/tv",
    phone: baseLink + "/residential/home-phone",
    ourStory: baseLink + "/about-us",
    whyUs: baseLink + "/why-choose-us",
    freeWiFi: baseLink + "/residential/public-wifi",
    contact: baseLink + "/contact-us",
    support: baseLink + "/residential/support",
    home: baseLink + "/residential",
    start: baseLink,
  };

  const handleInternalLinks = (link) => {
    let uri;
    if (link?.includes("https://internallink.com")) {
      uri =
        "/" + internalUrlReaderWithLangReg(link, pathname);
    } else {
      uri = link;
    }
    return uri;
  };

  const handleStart = (e) => {
    if (e.key === "Enter") {
      window.location.href = links.start;
    }
  };

  const handleSignIn = (e) => {
    if (e.key === "Enter") {
      window.location.href = info?.nav_sign_in_button_link;
    }
  };

  return (
    <div className="home-header-container back-navbar">
      <div className="resi-container is-relative">
        {isStartModalOpen ? (
          <StartModal setShowModal={setIsStartModalOpen} setRegionOpen={setIsRegionOpen} />
        ) : null}
        {closed ? null : (
          <div className="banner-container">
            <div className="banner-text">
              <p>This is a banner that has some placeholder text</p>
              <a className="Wbold">Learn More?</a>
            </div>

            {disableClose ? null : (
              <div
                className="banner-close-container"
                onClick={() => setClosed(true)}
              >
                <Close />
              </div>
            )}
          </div>
        )}
        <div className="header-top-bar">
          <div className="header-type-select">
            <p>
              <a href={handleInternalLinks(info?.nav_business_button_link)}>
                {info?.nav_business_button_txt}
              </a>
            </p>
            <p className="header-residential">
              <a href={handleInternalLinks(info?.nav_residential_button_link)}>
                {info?.nav_residential_button_txt}
              </a>
            </p>
          </div>

          <div className="header-select-container">
            <SearchInput />

            <div className="header-input-container header-sign-in">
              <UserIcon color={"white"} />
              <p onKeyDown={(e) => handleSignIn(e)}>
                <a href={info?.nav_sign_in_button_link}>
                  {info?.nav_sign_in_button_txt}
                </a>
              </p>
            </div>

            <Dropdown
              options={isFrench ? FRProvinceOptions : ProvinceOptions}
              BGColor={"#202020"}
              textColor={"#fff"}
              placeholder={pathname.split("/")[2]}
              urlPosition={2}
            />

            <Dropdown
              options={LangOptions}
              BGColor={"#202020"}
              textColor={"#fff"}
              placeholder={pathname.split("/")[1]}
              urlPosition={1}
            />
          </div>
        </div>

        <HamburgerMenu links={links} />

        <div className="header-bottom-bar">
          <div className="header-nav-container">
            <div
              className="header-nav-logo-container"
              onKeyDown={(e) => handleStart(e)}
            >
              <a href={links.start}>
                <MainLogo />
              </a>
            </div>

            <div className="header-hover-container" tabIndex="0">
              <div className="header-nav-item">
                <p>{info?.services_dropdown_title}</p>

                <div className="header-hover-dropdown">
                  {info?.services_items?.map((item, index) => {
                    return (
                      <a
                        href={
                          "/" + internalUrlReaderWithLangReg(item.link, pathname)
                        }
                        key={index}
                      >
                        <p className="hover-dropdown-item">{item.text}</p>
                      </a>
                    );
                  })}
                </div>
              </div>
              <Carrot color={"black"} />
            </div>

            <div className="header-hover-container" tabIndex="0">
              <div className="header-nav-item">
                <p>{info?.about_dropdown_title}</p>

                <div className="header-hover-dropdown">
                  {info?.about_items?.map((item, index) => {
                    return (
                      <a
                        href={
                          "/" + internalUrlReaderWithLangReg(item.link, pathname)
                        }
                        key={index}
                      >
                        <p className="hover-dropdown-item">{item.text}</p>
                      </a>
                    );
                  })}
                </div>
              </div>
              <Carrot color={"black"} />
            </div>

            <p className="header-nav-item">
              <a href={"/" + internalUrlReaderWithLangReg(info?.link_1_url, pathname)}>
                {info?.link_1_txt}
              </a>
            </p>

            <p className="header-nav-item">
              <a href={"/" + internalUrlReaderWithLangReg(info?.link_2_url, pathname)}>
                {info?.link_2_txt}
              </a>
            </p>
          </div>

          <Button
            click={() => dispatch(toggleModal({ children: <AddressModal /> }))}
          >
            {info?.btn_txt}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
