import React from "react";
import "components/Badges/ListBadge.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";

const ListBadge = ({ image, body }) => {
  return (
    <div className="list-badge-container">
      <WagtailImage image={image} />

      {convertHtmlToReact(body)}
    </div>
  );
};

export default ListBadge;
