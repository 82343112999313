import React, { useEffect } from "react";
import "layout/Layout.scss";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import Modal from "components/reusable/Modal/Modal";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { setLocalization, setRegion } from "store/reducers/localization";
import CareerHeader from "components/Header/CareerHeader/CareerHeader";
import { useGoogleTagPageViews } from "services/hooks/GoogleTags";
import { useShowWhichNavbars } from "services/hooks/FilterNavBars";
import { setUTMVariables } from "utils/utm";

// Scroll to top in navigation.
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Layout = () => {
  const { isModalOpen, modalChildren } = useSelector(
    (state) => state.modalControl
  );

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  useGoogleTagPageViews();
 

  const { pathname } = useLocation();
  const { showNavbar, showFooter, showCareer, showSurvey } = useShowWhichNavbars();

  //LOCALIZATION
  const localization = pathname.split("/")[1];
  const region = pathname.split("/")[2];
  document.cookie = `_LOCALE_=${localization}; path=/`;
  document.cookie = `region=${region}; path=/`;
  localStorage.setItem("language", localization);
  useEffect(() => {
    if (localization) {
      dispatch(setLocalization(localization));
    }
    if (region) {
      dispatch(setRegion(region));
    }
    if (searchParams) {
      setUTMVariables(searchParams);
    }
  }, [pathname, searchParams]);

  document.body.style.overflow = isModalOpen ? "hidden" : "auto";

  return (
    <>
      <div
        className={
          isModalOpen ? "layout-container blurred" : "layout-container"
        }
      >
        <ScrollToTop />
        <a className="skip-link" href="#main" tabIndex="0">Skip to content</a>
        {showNavbar ? <Header /> : null}
        {showCareer ? <CareerHeader /> : null}

        <div id="main" className="layout-outlet-container">
          <Outlet />
        </div>

        {showFooter ? <Footer /> : null}
      </div>
      {isModalOpen ? <Modal>{modalChildren}</Modal> : null}
    </>
  );
};

export default Layout;
