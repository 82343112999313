import { createApi } from "@reduxjs/toolkit/query/react";
import { baseBeanfieldQuery } from "api/config";

export const SearchApi = createApi({
  reducerPath: "SearchApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getSearch: builder.query({
      query: ({ address }) => {
        return {
          url: `/v1/search/address?q=${address}`,
          method: "GET",
        };
      },
    }),
    getSearchId: builder.query({
      query: ({ address_id }) => {
        return {
          url: `/v1/search/address_id?q=${address_id}`,
          method: "GET",
        };
      },
    }),
    rateLimitVerify: builder.mutation({
      query: ({ token }) => {
        return {
          url: `/v2/rate-limit/verify`,
          method: "POST",
          body: {
            token: token
          }
        };
      },
    }),
  }),
});

export const { useGetSearchQuery, useLazyGetSearchQuery, useGetSearchIdQuery, useLazyGetSearchIdQuery, useRateLimitVerifyMutation } = SearchApi;
