import "components/BusinessCampaign/HeaderComponent.scss";
import MainLogo from "assets/svg/MainLogo";
import {
  LangOptions,
} from "utils/DropDownData";
import LanguageDropdown from "./LanguageDropdown";
import {useLocation} from "react-router";
import CampaignHamburgerMenu from "./CampaignHamburgerMenu";
import {internalUrlReaderWithLangReg} from "utils/wagtail";

const HeaderComponent = ({
  links,
  show_language_toggle
}) => {
  const { pathname } = useLocation();
  let path = pathname.split("/");
  return (
    <>
      <div className="header-wrapper resi-container">
        <header className="resi-margin-container">
          <div className="header-flex">
            <div className="logo"><a href="/"><MainLogo /></a></div>
            <div className="links">
              <div className="main-nav">
                {links.map((link) => {
                    if(link.is_external_link) {
                      return <a href={link.link_url} className="nav-link">{link.link_text}</a>
                    }
                    else{
                        return <a href={"/" + internalUrlReaderWithLangReg(link.link_url, pathname)} className="nav-link">
                            {link.link_text}
                        </a>
                    }
                })}
              </div>
              {show_language_toggle ?
                  <div className="lang-toggle">
                    <LanguageDropdown options={LangOptions} placeholder={path[1]}  urlPosition={1} />
                  </div>
                  : null}
            </div>

          </div>
          <div className="header-flex-mobile">
            <div className="logo"><a href="/"><MainLogo /></a></div>
            <CampaignHamburgerMenu links={links} langOptions={LangOptions} urlPosition={1}/>
          </div>

        </header>
      </div>
    </>
  );
};

export default HeaderComponent;