import ServiceAvailable from "assets/availabilityMap/available.png";
import ServiceComing from "assets/availabilityMap/unavailable.png";

//FUNCTION FOR MARKER RENDERING
export const renderMarkers = (
  map,
  buildings,
  openInfoWindowRef,
  selectedForFilter,
  setDropdownOpen,
  setSelectedForFilter,
  getModal,
  availablePin,
  comingSoonPin
) => {
  buildings.map((building, i) => {
    if (
      building.status === "On-Net" ||
      building.status === "Fibre Build In Progress"
    ) {
      const marker = new window.google.maps.Marker({
        position: {
          lat: Number(building.latitude),
          lng: Number(building.longitude),
        },
        map,
        icon: {
          url: building.status === "On-Net" ? ServiceAvailable : ServiceComing,
          scaledSize: new window.google.maps.Size(25, 25),
        },
        title: building.building,
      });
      const availability =
        building.status === "On-Net" ? "available" : "coming";

      const setSelectedBuilding = () => {
        setSelectedForFilter(building);
        setDropdownOpen(false);
        getModal(building);
      };

      const infoWindow = new window.google.maps.InfoWindow({
        content: `
            <div class="info_window_container" key={i}>
              <h2 class="info_window">${building.addresses?.length > 1 || building.addresses?.length === 0 ? building.building : building.addresses[0]?.streetAddress}</h2>
              <p>${
                building.addresses[0]?.city || building.addresses[1]?.city || ""
              }</p>
                <div class="info_window_service" >
                  <img
                    src="${
                      availability === "available"
                        ? ServiceAvailable
                        : ServiceComing
                    }"
                    alt="service image"
                  />
                  <p style={{ marginLeft: "5px" }}>
                    ${
                      availability === "available"
                        ? availablePin?.title
                        : comingSoonPin?.title
                    }
                  </p> 
                </div>
                <button class="info_window_button">
                  ${
                    availability === "available"
                      ? `<p
                        class="info_window_button_p"
                      >
                      ${availablePin?.btn_text}
                      </p>`
                      : `<p
                        class="info_window_button_p"
                      >
                      ${comingSoonPin?.btn_text}
                      </p>`
                  }
                </button>
            </div>
            `,
      });

      if (infoWindow) {
        infoWindow.addListener("domready", () => {
          const deleteEvent = () => {
            //remove all infoWindow listeners DO NOT REMOVE
            window.google.maps.event.clearInstanceListeners(infoWindow);
          };
          const button = document.querySelector(".info_window_button");
          button.addEventListener("click", () => {
            setSelectedBuilding();
            deleteEvent();
          });
        });
      }

      if (marker) {
        marker.addListener("click", () => {
          setDropdownOpen(false);

          // Close the currently open InfoWindow, if any
          if (openInfoWindowRef.current) {
            openInfoWindowRef.current.close();
          }

          // Open the clicked marker's InfoWindow
          infoWindow.open(map, marker);

          // Update the currently open InfoWindow reference
          openInfoWindowRef.current = infoWindow;
        });
      }
      // Check if this marker is the selected one
      if (
        building.latitude === selectedForFilter?.lat &&
        building.longitude === selectedForFilter?.lng
      ) {
        if (openInfoWindowRef.current) {
          openInfoWindowRef.current.close();
          openInfoWindowRef.current = null;
        }
        infoWindow.open(map, marker);
        openInfoWindowRef.current = infoWindow;
        map.panTo(marker.getPosition());
      } else {
        infoWindow.close();
      }

      if (map) {
        map.addListener("click", () => {
          // Close the currently open InfoWindow, if any
          if (openInfoWindowRef.current) {
            openInfoWindowRef.current.close();
            openInfoWindowRef.current = null;
            setDropdownOpen(false);

            //clearing button click events DO NOT REMOVE
            window.google.maps.event.clearInstanceListeners(infoWindow);
          }
        });
      }
    } else {
    }
  });
};
