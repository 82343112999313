import React, { useEffect, useRef } from "react";
import "components/OrderPage/StepThree/PersonalDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { setEmailStep, setUserInfo } from "store/reducers/order/orderSlice";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";
import AddressModalContent from "../YourAddress/AddressModalContent";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useState } from "react";

const PersonalDetails = ({ info, setInfo }) => {
  const dispatch = useDispatch();
  const { email, address } = useSelector((state) => state.orderSlice.userInfo);
  const [keyPressed, setKeyPressed] = useState(false);
  //CMS DATA
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  const infoRef = useRef(info);

  //REGESTERING KEY PRESS TO BYPASSS BACKSPACE DURING REGEX CHECK
  const keypressEvent = document.addEventListener("keydown", (e) => {
    if (e.key === "Backspace") {
      setKeyPressed(true);
    } else {
      setKeyPressed(false);
    }
  });

  const handleStateUpdate = (e) => {
    const { name, value } = e.target;
    const charNumbersAndDashRegex = /^[0-9-]+$/;
    //CHECK IF VALUE IS EMPTY AND IF IT IS, SET IT TO EMPTY STRING AND RETURN BEFORE REGEX CHECK
    if (name === "phoneNumber" && value.length === 0)
      return setInfo({ ...info, [name]: value });
    // REGEX CHECK FOR ONLY NUMBERS AND DASHES
    if (name === "phoneNumber" && !charNumbersAndDashRegex.test(value)) return;
    //LENGTH CHECKS FOR PHONE NUMBER
    if (name === "phoneNumber" && value.length > 12) return;
    if (name === "phoneNumber" && value.length === 3 && !keyPressed)
      return setInfo({ ...info, [name]: value + "-" });
    if (name === "phoneNumber" && value.length === 7 && !keyPressed)
      return setInfo({ ...info, [name]: value + "-" });
    setInfo({ ...info, [name]: value });
  };

  useEffect(() => {
    infoRef.current = info;
  }, [info]);

  useEffect(() => {
    return () => {
      dispatch(setUserInfo(infoRef.current));
      window.removeEventListener("keydown", keypressEvent);
    };
  }, []);

  const editEmail = () => {
    dispatch(setEmailStep(1));
    dispatch(
      setUserInfo({
        ...infoRef.current,
        email: "",
        emailVerificationComplete: false,
      })
    );
  };

  const editAddress = () => {
    dispatch(toggleModal({ children: <AddressModalContent cmsData={data} /> }));
  };

  return (
    <div className="stepThree_yourInfo_form">
      <div className="form_title">
        <h2 tabIndex="0">{data?.step_3_form_info_header}</h2>
      </div>
      <div>
        <div className="form_sub_title">
          <p tabIndex="0">{data?.step_3_form_info_subtitle}</p>
        </div>
        <form name="user_info">
          <div>
            <label htmlFor="firstName">
              {data?.step_3_form_firstname_title}
            </label>
            <input
              type="text"
              name="firstName"
              value={info.firstName}
              onChange={(e) => handleStateUpdate(e)}
              aria-label={data?.step_3_form_firstname_title}
            />
          </div>
          <div>
            <label htmlFor="lastName">{data?.step_3_form_lastname_title}</label>
            <input
              type="text"
              name="lastName"
              value={info.lastName}
              onChange={(e) => handleStateUpdate(e)}
              aria-label={data?.step_3_form_lastname_title}
            />
          </div>
          <div style={{ position: "relative" }}>
            <label htmlFor="email">{data?.step_3_form_email_info_title}</label>
            <input
              disabled
              value={email}
              type="text"
              name="email"
              aria-label={data?.step_3_form_email_info_title}
            />
            <span onClick={editEmail} className="edit_btn">
              {data?.step_3_form_edit_btn_txt}
            </span>
          </div>
          <div>
            <label htmlFor="phoneNumber">{data?.step_3_form_phone_title}</label>
            <input
              type="tel"
              name="phoneNumber"
              value={info.phoneNumber}
              onChange={(e) => handleStateUpdate(e)}
              aria-label={data?.step_3_form_phone_title}
            />
          </div>
          <div style={{ position: "relative" }}>
            <label htmlFor="address">{data?.step_3_form_address_title}</label>
            <input
              disabled
              value={address}
              type="text"
              name="address"
              aria-label={data?.step_3_form_address_title}
            />
            <span onClick={editAddress} className="edit_btn">
              {data?.step_3_form_edit_btn_txt}
            </span>
          </div>
          <div>
            <label htmlFor="city">{data?.step_3_form_unit_title}</label>
            <input
              type="text"
              name="unit"
              value={info.unit}
              onChange={(e) => handleStateUpdate(e)}
              aria-label={data?.step_3_form_unit_title}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetails;
