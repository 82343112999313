import React from "react";
import "components/BusinessCampaign/ImageDescriptionTwoColumnComponent.scss";
import {convertHtmlToReact} from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import {WagtailImage} from "components/Wagtail/WagtailImage";

const ImageDescriptionTwoColumnComponent = ({
    title_text,
    items,
}) => {
    return (
        <>
            <div className="resi-container">
                <div className="resi-margin-container">
                    <div className="two-column-component-container">
                        <div className="title-text" tabIndex="0">
                            {convertHtmlToReact(title_text)}
                        </div>
                        <div className="items-container">
                            {items.map((item, index) => {
                                return (
                                    <div className="item-container" key={index}>
                                        <div className="image">
                                            <WagtailImage image={item?.image}/>
                                        </div>
                                        <div className="content">
                                            {convertHtmlToReact(item?.description)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageDescriptionTwoColumnComponent;