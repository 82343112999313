import React, { useEffect, useState } from "react";
import "components/Modals/Email/Email.scss";
import Button from "components/reusable/Button/Button";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { useSelector } from "react-redux";
import { sendGoogleTagEvent } from "../../../services/hooks/GoogleTags";

const EmailModal = () => {
  const [copied, setCopied] = useState(false);
  const { localization } = useSelector((state) => state.localizationSlice);
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);
  const generalText = () => {
    return cmsData?.modal_int_tenown_email_preview;
  };

  const linkText = () => {
    if (localization === "en") {
      return `mailto:?cc=getfibre@beanfield.com&subject=Request Beanfield&body=${encodeURIComponent(
        `Hello,\n\nI’d like to request that we bring Beanfield services to our building. Not only do they build and own their own fibre network and provide an amazing alternative for residents, they also include some great building perks as well!\n\nI’ve cc’d a Beanfield rep, who can provide all the necessary information.\n\nLet me know, thanks!\n[your name]\n[your address/unit]\n`
      )}
        `;
    } else {
      return `mailto:?cc=getfibre@beanfield.com&subject=Demandez Beanfield&body=${encodeURIComponent(
        `Bonjour,\n\nJ'aimerais vous demander d'apporter les services de Beanfield à notre immeuble. Non seulement ils construisent et possèdent leur propre réseau de fibre optique et fournissent une alternative étonnante pour les résidents, mais ils inclueraient également des avantages importants pour l'immeuble !\n\nJ'ai envoyé une copie à un représentant de Beanfield, qui peut fournir toutes les informations nécessaires.\n\nTenez-moi au courant, merci !\n[votre nom]\n[votre adresse/unité]\n`
      )}
      `;
    }
  };

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(generalText());
  };

  const handleSendemail = () => {
    window.open(linkText());
    sendGoogleTagEvent({ eventName: "Tenant Request:Sent"});
  };

  useEffect(() => {
    const tags = document.getElementsByTagName("a");
    for (const tag of tags) {
      if (tag.getAttribute("href") === "mailto:getfibre@beanfield.com") {
        tag.setAttribute("href", linkText());
      }
    }
  }, []);

  return (
    <div
      style={{ paddingBottom: copied ? "10px" : "2rem" }}
      className="email-modal-container"
    >
      <div className="email-modal-header">
        <h2>{cmsData?.modal_int_tenown_header}</h2>
        {convertHtmlToReact(cmsData?.modal_int_tenown_description)}
      </div>

      <div className="email-modal-copy-container">
        {convertHtmlToReact(cmsData?.modal_int_tenown_email_preview)}
      </div>

      <div className="email-modal-button-container">
        <button
          className="email-modal-copy-button Wbold"
          aria-pressed={copied}
          onClick={() => handleCopy()}
        >
          {copied
            ? cmsData?.modal_int_tenown_copied_btn_text
            : cmsData?.modal_int_tenown_copy_btn_text}
        </button>
        <Button click={() => handleSendemail()}>
          {cmsData?.modal_int_tenown_send_email_btn_text}
        </Button>
      </div>
      <div className="email-modal-reminder">
        {copied
          ? convertHtmlToReact(cmsData?.modal_int_tenown_copied_subtext)
          : null}
      </div>
    </div>
  );
};

export default EmailModal;
