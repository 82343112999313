import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4c-3.26 0-3.667.014-4.947.072-1.278.058-2.15.262-2.914.558-.8.301-1.525.773-2.125 1.384A5.887 5.887 0 0 0 4.63 8.14c-.296.763-.5 1.637-.558 2.915C4.014 12.333 4 12.74 4 16s.014 3.667.072 4.947c.058 1.278.262 2.15.558 2.914.301.8.773 1.525 1.384 2.125.6.61 1.325 1.083 2.125 1.384.763.296 1.636.5 2.914.558 1.28.058 1.688.072 4.947.072 3.26 0 3.667-.014 4.947-.072 1.278-.058 2.15-.262 2.914-.558.8-.301 1.525-.773 2.125-1.384a5.887 5.887 0 0 0 1.384-2.125c.296-.763.5-1.636.558-2.914.058-1.28.072-1.688.072-4.947 0-3.26-.014-3.667-.072-4.947-.058-1.278-.262-2.15-.558-2.914a5.883 5.883 0 0 0-1.384-2.125A5.886 5.886 0 0 0 23.86 4.63c-.763-.296-1.637-.5-2.915-.558C19.667 4.014 19.26 4 16 4Zm0 2.162c3.204 0 3.584.012 4.85.07 1.17.054 1.804.248 2.228.414.56.216.96.477 1.38.896.42.42.68.82.896 1.38.165.424.36 1.059.414 2.228.058 1.266.07 1.646.07 4.85 0 3.204-.012 3.584-.07 4.85-.054 1.17-.248 1.804-.414 2.228a3.718 3.718 0 0 1-.896 1.38 3.714 3.714 0 0 1-1.38.896c-.424.165-1.059.36-2.228.414-1.266.058-1.645.07-4.85.07-3.205 0-3.584-.012-4.85-.07-1.17-.054-1.804-.248-2.228-.414a3.717 3.717 0 0 1-1.38-.896 3.718 3.718 0 0 1-.896-1.38c-.165-.424-.36-1.059-.414-2.228-.058-1.266-.07-1.646-.07-4.85 0-3.204.012-3.584.07-4.85.054-1.17.248-1.804.414-2.228.216-.56.477-.96.896-1.38a3.714 3.714 0 0 1 1.38-.896c.424-.165 1.059-.36 2.228-.414 1.266-.058 1.646-.07 4.85-.07Z"
      fill="#325a32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 20.004a4.003 4.003 0 1 1 0-8.006 4.003 4.003 0 0 1 0 8.006Zm0-10.172a6.168 6.168 0 1 0 0 12.337 6.168 6.168 0 0 0 0-12.337Zm7.962-.112a1.458 1.458 0 1 1-2.915 0 1.458 1.458 0 0 1 2.915 0"
      fill="#325a32"
    />
  </svg>
);

export default SvgComponent;
