import React, { useState, useEffect, useRef } from "react";
import "pages/Support/Support/Support.scss";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import SearchGlass from "assets/svg/Search";
import { SupportText } from "mock/SupportText";
import Caret from "assets/Icons/Caret";
import Link from "components/reusable/Link/PageLink/PageLink";

import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { useLocation, useNavigate } from "react-router-dom";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { useLazyGetSupportSearchQuery } from "api/cms/live/Search";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import Head from "components/reusable/Head/Head";

const SupportSearchBar = (info) => {
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState(false);
  const [trigger, { data }] = useLazyGetSupportSearchQuery({
    searchQuery: search,
  });
  const locale = useLocation().pathname.split("/")[1];
  const navigate = useNavigate();
  const searchDropDownRef = useRef();

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      trigger({ searchQuery: search, localePath: locale });
    }, 700);

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  const handleDropdown = (value) => {
    setSearchClick(true);
    setSearch('');
    navigate(value.page.slug + '#' + makeAnchorFriendly(value.original));
  };

  const handleSearchInput = (value) => {
    setSearchClick(false);
    setSearch(value);
  };

  const handleKeyDownSearch = (event) => {
    if (event.key === "Enter") {
      handleSearchInput(event.target.value);
    } else if (data?.search_query && event.key === "ArrowDown") {
      event.preventDefault();
      searchDropDownRef.current.getElementsByTagName("p")[0].focus();
    }
  };

  const handlePKeyDown = (e, item, index) => {
    const resultsLength = searchDropDownRef.current.getElementsByTagName("p").length;
    if (e.key === "ArrowUp") {
      e.view.event.preventDefault();
      if (index === 0) {
        searchDropDownRef.current.getElementsByTagName("p")[resultsLength - 1].focus();
      } else {
        searchDropDownRef.current.getElementsByTagName("p")[index - 1].focus();
      }
    }
    if (e.key === "ArrowDown") {
      e.view.event.preventDefault();
      if (index === resultsLength - 1) {
        searchDropDownRef.current.getElementsByTagName("p")[0].focus();
      } else {
        searchDropDownRef.current.getElementsByTagName("p")[index + 1].focus();
      }
    }
    if (e.key === "Enter") {
      handleDropdown(item);
    }
  };

  return (
    <div className="support-search-bar-container">
      <input
        value={search}
        onChange={(e) => handleSearchInput(e.target.value)}
        onKeyDown={(e) => handleKeyDownSearch(e)}
        placeholder={info?.info?.header_search_placeholder}
        className="support-input"
      />
      <div ref={searchDropDownRef} className="support-dropdown">
        {!data?.search_query ? null : data?.search_results?.length === 0 ? (
          <p tabIndex="0">{ info?.info?.header_search_no_results }</p>
        ) : searchClick ? null : (
          data?.search_results?.map((item, index) => {
            return (
              <p key={index}
                 onClick={() => handleDropdown(item)}
                 onKeyDown={(e) => handlePKeyDown(e, item, index)}
                 tabIndex="0"
              >
                {convertHtmlToReact(item.highlight)}
              </p>
            );
          })
        )}
      </div>

      <SearchGlass className="support-search-icon" color={"black"} />
    </div>
  );
};

const makeAnchorFriendly = (string) => {
  return string.replace(/\W/g, "");
};

const APIMassageSupportSection = (supportSection) => {
  return {
    img: supportSection.img,
    header: supportSection.header,
    slug: supportSection?.support_page?.slug,
    anchors: [
      supportSection.anchor_1,
      supportSection.anchor_2,
      supportSection.anchor_3,
    ],
    viewAllText: supportSection.view_all,
  };
};

const SupportSection = ({ img, header, slug, anchors, viewAllText }) => {
  const navigate = useNavigate();

  const handleSupportPKeyDown = (event, item) => {
    if (event.key === "Enter") {
      navigate(`${slug}#${makeAnchorFriendly(item)}`)
    }
  }

  return (
    <div className="support-question-container">
      <WagtailImage image={img} />
      <h2 tabIndex="0">{header}</h2>

      {anchors.map((item, i) => {
        return (
          <div
            className="support-question-text"
            aria-border={SupportText.length === i + 1 ? false : true}
            onClick={() => {
              navigate(`${slug}#${makeAnchorFriendly(item)}`);
            }}
          >
            <div className="support-carret-container">
              <Caret color={"#325a32"} turn={"right"} />
            </div>
            <p tabIndex="0" onKeyDown={(e) => handleSupportPKeyDown(e, item)}>{item}</p>
          </div>
        );
      })}

      <div className="support-link-container">
        <Link bold color={"#325a32"} to={`${slug}`}>
          {viewAllText}
        </Link>
      </div>
    </div>
  );
};

const Support = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetSupportLandingPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  return (
    <div className="support-container resi-container">
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield Soutien" :
          "Beanfield Support"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "L'équipe Beanfield est disponible 24/7. Notre équipe de soutien basée entièrement au Canada est prête à vous aider." :
          "The Beanfield team is avialable 24/7 to provide support if ever needed. Our support team is 100% Canadian-based and ready to assist."
        }
        imageName={"Support"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-margin-container">
        <div className="support-header">
          <h1 tabIndex="0">{info?.header_title}</h1>

          <SupportSearchBar info={info} />

          <p tabIndex="0">{info?.header_caption}</p>
        </div>

        <div className="support-item-container">
          {info?.support_sections?.map((section) => {
            return React.createElement(
              SupportSection,
              APIMassageSupportSection(section)
            );
          })}
        </div>

        {info?.contact_us_page?.badges?.length > 0 && (
          <ContactInfo
            title={info.contact_us_page.title}
            description={info.contact_us_page.description}
            badges={info.contact_us_page.badges}
          />
        )}
      </div>
    </div>
  );
};

export default Support;
