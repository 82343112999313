import React from "react";
import "components/Modals/Fibrestream/Fibrestream.scss";
import Button from "components/reusable/Button/Button";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import AddressModal from "components/Modals/Address/Address";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { useGetHomePageData } from "../../../services/api/useGetHomePageData";

const FibrestreamModal = () => {
  const dispatch = useDispatch();

  const handleReturn = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <AddressModal /> }));
  };

  const handleStart = () => {
    dispatch(toggleModal());
    window.location.replace('https://www.fibrestream.ca');
    return null;
  };

  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);
  return (
    <div className="fibrestream-modal-container">
      <div className="fibrestream-container">
        {convertHtmlToReact(cmsData?.modal_fibrestream_header)}
        {convertHtmlToReact(cmsData?.modal_fibrestream_description)}
      </div>


      <div className="fibrestream-container">
        <Button click={() => handleStart()}>
          {cmsData?.modal_fibrestream_btn_text}
        </Button>

        <p onClick={() => handleReturn()} className="Wbold fibrestream-modal-text">
          {cmsData?.modal_fibrestream_change_address_link_text}
        </p>
      </div>
    </div>
  );
};

export default FibrestreamModal;
