import React, {useState, useLayoutEffect} from "react";
import "pages/Start/StartPage.scss";
import Dropdown from "components/reusable/Dropdown/Dropdown";
import {
  ProvinceOptions,
  LangOptions,
  FRProvinceOptions,
} from "utils/DropDownData";
import SecondaryLogo from "assets/svg/SecondaryLogo";
import { Link } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { useLocation } from "react-router-dom";
import { deriveRegionByLatAndLong } from "services/hooks/useGeolocation";
import RadioBtnStartPage from "components/reusable/Radio/RadioBtnStartPage";
import Caret from "assets/Icons/Caret";
import Head from "components/reusable/Head/Head";

const StartPage = () => {
  const getLocationValue = (location) => {
    const language = localStorage.getItem("language");
    const provinceData =  language === "fr" ? FRProvinceOptions : ProvinceOptions;
    let locationObj = provinceData.find(l => l.label === location.name);
    return locationObj ? locationObj.value : null;
  };

  const getCheckedValue = (region, location) => {
    return region === getLocationValue(location);
  };

  const handleRegionSelect = (value) => {
    setRegion(value.target.value);
    localStorage.setItem("region", value.target.value);
  };

  const { pathname } = useLocation();
  const [region, setRegion] = useState(localStorage.getItem("region"));

  useLayoutEffect(() => {
    if (localStorage.getItem("region") === null) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { longitude, latitude } = position.coords;
          const region = deriveRegionByLatAndLong(latitude, longitude);
          setRegion(region);
          localStorage.setItem("region", region);
        }
      );
    } else {
      setRegion(localStorage.getItem("region"));
    }
  }, []);

  let path = pathname.split("/");
  const {
    page: info
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetStartPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const homeUrl = "/" + path[1] + "/" + (region ? region : 'tor') + "/residential";

 const businessUrl = "/business";

  return (
    <div className="start-background">
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield" :
          "Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Nous sommes votre fournisseur de services pour les entreprises et les particuliers, fournis par notre réseau de fibre optique indépendant depuis plus de 35 ans." :
          "We're your provider for Business and Residential services, delivered over our independent fibre network for over 35 years."
        }
        imageName={"StartPage"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="start-dropdown-container">
        <Dropdown
          options={LangOptions}
          BGColor={"#fff"}
          textColor={"#202020"}
          placeholder={path[1]}
          urlPosition={1}
        />
      </div>

      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="start-content-container">
            <SecondaryLogo />
            <p className="start-text">{info?.main_description}</p>

            <p className="locations-text">{info?.location_services_description}</p>

            <div className="start-locations">
              {info?.locations?.length &&
              info?.locations
                .map((location, index) => [
                  [
                    <div className="radio_btn_wrap" key={index}>
                      <RadioBtnStartPage
                        id={location.name}
                        name="location"
                        value={getLocationValue(location)}
                        onClick={(e) => handleRegionSelect(e)}
                        checked={region ? getCheckedValue(region, location) : false}
                      />
                    </div>,
                  ]
                ])}
            </div>

            <div className="start-link-container">
              <a href={businessUrl}>
                <div className="Wbold start-button-text">
                  <p>{info?.business_btn_text}</p>
                  <Caret color={"#325a32"} turn={"right"} />
                </div>
              </a>
              <Link to={homeUrl}>
                <div className="Wbold start-button-text">
                  <p>{info?.residential_btn_text}</p>
                  <Caret color={"#325a32"} turn={"right"} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartPage;
