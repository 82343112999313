import React, { useState } from "react";
import "components/BusinessCampaign/TwoColumnMediaComponent.scss";
import {convertHtmlToReact} from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import {WagtailImage} from "../Wagtail/WagtailImage";

const TwoColumnMediaComponent = ({
    title_text,
    sub_text,
    media,
    media_position_left
}) => {
  return (
    <>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className={`tc-component-container ${media_position_left? 'flex-reverse':''}`}>
            <div className="text" tabIndex="0">
              <div className="title-text">{convertHtmlToReact(title_text)}</div>
              <div className="sub-text">{convertHtmlToReact(sub_text)}</div>
            </div>
            <div className={`media ${media_position_left? '':'flex-reverse'}`}>
              { media[0].type==='image'?
                <div className={`media-image ${media_position_left? '':'flex-reverse'}`}>
                  <WagtailImage image={media[0].value} className="tcc-image"/>
                </div>:
                <div className={`media-video ${media_position_left? '':'flex-reverse'}`}>
                  <VideoPlayer url={media[0].value}/>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoColumnMediaComponent;