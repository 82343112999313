import React, { useState } from "react";
import Hamburger from "hamburger-react";
import MainLogo from "assets/svg/MainLogo";
import Caret from "assets/Icons/Caret";
import "components/HamburgerMenu/CareerHamburger/CareerHamburger.scss";

const CareerHamburger = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="career-hamburger-container">
      <div
        className="career-hamburger-title"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="career-hamburger-logo-container">
          <MainLogo />
          <p>careers</p>
        </div>

        <Hamburger
          color="#202020"
          rounded
          toggled={isOpen}
          toggle={setIsOpen}
        />
      </div>
      <div className="career-hamburger-menu" aria-expanded={!isOpen}>
        <div className="career-hamburger-menu-item"
          onClick={() => window.location.href = props.links.lifeAtBeanfield} >
          <p>Life at Beanfield</p>

          <Caret color={"black"} turn={"right"} />
        </div>

        <div className="career-hamburger-menu-item"
          onClick={() => window.location.href = props.links.howWeHire} >
          <p>How we hire</p>

          <Caret color={"black"} turn={"right"} />
        </div>
      </div>
    </div>
  );
};

export default CareerHamburger;
