import * as DraftPageQueries from "api/cms/drafts/Pages";
import * as PageQueries from "api/cms/live/Pages";
import parseQueryStringToDictionary from "utils/urlParams";

// Check query parameters passed to page and hit the
//  appropriate wagtail API (live vs draft API)
const Queries = {
  live: PageQueries,
  draft: DraftPageQueries,
};

const getQueries = (queryString) => {
  let params = {};
  queryString.replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
    params[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  const queryDict = parseQueryStringToDictionary(queryString);
  if (!queryDict || !queryDict.content_type || !queryDict.token) {
    return Queries["live"];
  }

  return Queries["draft"];
};

export default Queries;

export { getQueries };
