import React from "react";
import "pages/Careers/Careers.scss";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import TextMarquee from "components/Marquee/TextMarquee/TextMarquee";
import Button from "components/reusable/Button/Button";
import PageLink, {
  WagTailDOMString,
} from "components/reusable/Link/PageLink/PageLink";
import ListBadge from "components/Badges/ListBadge";
import JobCarousel from "components/Carousel/JobCarousel/JobCarousel";
import ReviewCarousel from "components/Carousel/ReviewCarousel/ReviewCarousel";

import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import Head from "components/reusable/Head/Head";

const Careers = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetCareersPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );
  const handleClick = () => {
      window.open("https://recruiting.ultipro.ca/BEA5000BEAN/JobBoard/d0d559da-ea8c-4302-9f29-633b74dbebee/?q=&o=postedDateDesc&w=&wc=&we=&wpst=");
  };

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Carrières | Beanfield" :
          "Careers | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Atteignez de nouveaux sommets dans votre carrière avec Beanfield, alors que nous nous développons à travers Canada et brisons le moule des télécommunications." :
          "Reach new heights in your career with Beanfield, as we continue to expand across Canada and break the traditional telecom mold. "
        }
        imageName={"Careers"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="career-header">
            <div className="career-header-content">
              {convertHtmlToReact(info?.header)}

              <Button click={handleClick}>{info?.header_btn}</Button>
            </div>
            <div className="career-header-video">
              {info?.header_video === null ? (
                <WagtailImage image={info?.header_video_img} />
              ) : (
                <VideoPlayer url={info?.header_video} />
              )}
            </div>
          </div>
        </div>
      </div>

      <h2 className="career-marquee-header">{info?.text_wheel_header}</h2>

      { info?.text_wheel_text_a || info?.text_wheel_text_b || info?.text_wheel_text_c ? (
        <TextMarquee
          double
          textOne={info?.text_wheel_text_a}
          textTwo={info?.text_wheel_text_b}
          textThree={info?.text_wheel_text_c}
          colorPrimary={info?.text_wheel_colour_a}
          colorSecondary={info?.text_wheel_colour_b}
        />
        ) : null
      }
      <JobCarousel header={info?.job_carousel_header} items={info?.job_items} />

      <div className="resi-container">
        <div className="resi-margin-container">
          <h2 className="career-package-header">{info?.benefits_header}</h2>

          <div className="career-badge-container">
            {info?.benefits_items?.map((item) => {
              return <ListBadge {...item} />;
            })}
          </div>
        </div>
      </div>

      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="career-life-container">
            <div className="career-life-content">
              {<WagTailDOMString DOMString={info?.life_at_beanfield_body} />}

              <PageLink to={info?.life_at_beanfield_link_url} bold arrow={"true"}>
                {info?.life_at_beanfield_link_txt}
              </PageLink>
            </div>

            <div className="career-life-img-container">
              <WagtailImage image={info?.life_at_beanfield_img} />
            </div>
          </div>
        </div>
      </div>

      <div className="career-learn-container">
        <p className="Wbold">{info?.story_header}</p>

        <PageLink to={info?.story_link_url} bold border>
          {info?.story_link_txt}
        </PageLink>
      </div>

      <div className="career-review-container">
        <h2>{info?.testimonials_header}</h2>

        <ReviewCarousel items={info?.testimonials_items} />
      </div>
    </div>
  );
};

export default Careers;
