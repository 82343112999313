import React, { useState, useEffect } from "react";
import "components/Carousel/BlogCarousel/BlogCarousel.scss";
import Slider from "react-slick";
import BlogRight from "assets/Carousel/Blog/BlogRight";
import BlogLeft from "assets/Carousel/Blog/BlogLeft";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import PageLink from "components/reusable/Link/PageLink/PageLink";

const Carousel = ({ items }) => {

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initalSlide: 0,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initalSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initalSlide: 0,
        },
      },
    ],
    nextArrow: (
      <div>
        <BlogRight />
      </div>
    ),
    prevArrow: (
      <div>
        <BlogLeft />
      </div>
    ),
  };

  return (
    <div className="blog-carousel-container">
      { items?.length > 0 && <Slider {...settings}>
        {
          items.map((item) => {
            return (
              <div className="blog-carousel-card">
                {item?.image?.meta?.download_url ? (
                  <WagtailImage image={item?.image} alt={item.title} />
                ) : (
                  <img src={item.image} alt={item.title} />
                )}

                <div className="blog-carousel-content-container">
                  <h2>{item.title}</h2>

                  {item.link_txt && item.link_url && (
                    <PageLink to={item.link_url} bold arrow={"true"}>
                      {item.link_txt}
                    </PageLink>
                  )}
                </div>
              </div>
            );
          })}
      </Slider>
      }
    </div>
  );
};

export default Carousel;
