import React, { useEffect, useState } from "react";
import OrderCheckMark from "assets/svg/OrderCheckMark";
import "components/OrderPage/StepProgress/StepProgress.scss";
import { useDispatch, useSelector } from "react-redux";
import { stepPlus, stepMinus } from "store/reducers/order/orderSlice";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { Page } from "api/cms/live/Pages";
import { createSelector } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";

const StepProgress = () => {
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.orderSlice.order);
  const [progressWidth, setProgressWidth] = useState(0);
  //PAGE DATA
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  const stepsData = [
    {
      title: data?.step_1_h,
    },
    {
      title: data?.step_2_h,
    },
    {
      title: data?.step_3_h,
    },
    {
      title: data?.step_4_h,
    },
    {
      title: data?.step_5_h,
    },
  ];

  useEffect(() => {
    setProgressWidth(25 * currentStep + "%");
  }, [currentStep]);

  return (
    <>
      <div className="stepProgress_container">
        <div className="progress" style={{ width: progressWidth }}></div>
        {stepsData.map((step, index) => {
          return (
            <div
              key={step?.id}
              className={
                "progress_step" +
                (currentStep === 4 ? " completed" : "") +
                (index === currentStep ? " progress-step-active" : "") +
                (currentStep > index ? " completed" : "")
              }
              data-label={step?.title}
            >
              {(currentStep !== 4 && index === currentStep) ||
              currentStep < index ? (
                <>
                  <p className="Wbold" tabIndex={index === currentStep ? "0" : ""}>{index + 1}</p>
                  <div className="step-title" tabIndex={index === currentStep ? "0" : ""}>
                    {step?.title}
                  </div>
                </>
              ) : (
                <OrderCheckMark />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StepProgress;
