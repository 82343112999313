import React, { useEffect, useState } from "react";
import "./moneris.scss";
import { useGetAutoPayTokenQuery } from "api/beanfield/OrderPage";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";

export default ({ submit = false, setSubmit, setResponseData }) => {
  const [listener, setListener] = useState(null);
  const monerisErrorCodes = {
    943: "Card data is invalid",
    944: "Invalid expiration date",
    945: "Invalid CVV data",
  };

  const { data: autoPayToken } = useGetAutoPayTokenQuery();
  //CMS DATA
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  if (autoPayToken) {
    var { key, hpp_url } = autoPayToken;
  }
  useEffect(() => {
    if (submit) {
      doMonerisSubmit();
      setSubmit(false);
    }
  }, [submit]);

  const doMonerisSubmit = () => {
    setListener(window.addEventListener("message", respMsg, false));

    var monFrameRef = document.getElementById("monerisFrame").contentWindow;
    monFrameRef.postMessage(
      "tokenize",
      "https://esqa.moneris.com/HPPtoken/index.php"
    );
    //change link according to table above
    return false;
  };

  const respMsg = function (e) {
    window.removeEventListener("message", listener);
    setListener(null);

    const respData = eval("(" + e.data + ")");

    const errorDoc = document.getElementById("error");
    errorDoc.innerHTML = "";
    if (respData.responseCode.includes("001") && respData.dataKey) {
      setResponseData(respData);
      document.getElementById("monerisFrame").style.display = "none";
    } else if (!respData.responseCode.includes("001")) {
      document.getElementById("monerisFrame").style.display = "block";
      errorDoc.innerHTML =
        monerisErrorCodes[respData?.responseCode[0]] || "Invalid data";
      errorDoc.style.color = "red";
      errorDoc.style.marginTop = "1rem";
    }
  };
  const style = `&pmmsg=true&css_body=background:white;&css_textbox=margin-bottom:1.5em;border:1px solid rgba(172, 172, 172, 1); box-sizing: border-box; border-radius: 8px; height: 40px; padding-left: 15px;&css_textbox_pan=width:400px;&enable_exp=1&css_textbox_exp=width:400px;&enable_cvd=1&css_textbox_cvd=width:400px;&display_labels=1&css_input_label=font-size:11px;margin-bottom: 0.375rem; font-family: Arial, Webdings, sans-serif; display:inline-block;&pan_label=${data?.step_3_payment_credit_number_label}&exp_label=${data?.step_3_payment_credit_expiry_label}&cvd_label=${data?.step_3_payment_credit_cvv_label}`;
  return (
    <div id={"monerisResponse"}>
      <div id="error"></div>
      {hpp_url && key ? (
        <iframe
          style={{
            overflow: "hidden",
            height: "240px",
            border: "none",
            marginTop: "1rem",
          }}
          className="monerisFrame"
          id="monerisFrame"
          src={`${hpp_url}?id=${key}${style}`}
        ></iframe>
      ) : null}
    </div>
  );
};
