import React, { useEffect } from "react";
import "components/Carousel/OverflowCarousel/OverflowCarousel.scss";
import Slider from "react-slick";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import "components/Carousel/InternetServiceOverflowCarousel/InternetServiceOverflowCarousel.scss";
import ActiveDot from "assets/Carousel/ActiveDot";
import InactiveDot from "assets/Carousel/InactiveDot";

const OverflowCarousel = ({ header, description, items }) => {
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    className: "center",
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    adaptiveHeight: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: true,
        },
      },
    ],
  };
  return (
    <div className="overflow-carousel-container">
      <h2 className="header-title">{header}</h2>
      <p>{description}</p>
      <Slider {...settings}>
        {items &&
          items.map((item, index) => {
            return (
              <div className="carousel-item" key={index}>
                <div className="carousel-image">
                  <WagtailImage image={item?.image} alt={item?.header} />
                </div>
                <div className="carousel-titles">
                  <h2>{item.header}</h2>
                  <p>{item.subtitle}</p>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default OverflowCarousel;
