import { useLocation } from "react-router-dom";

export const useRegionCheckModal = () => {
  const { pathname } = useLocation();
  const regexes = [
    /(ca|bc|on|qc|tor|mtl|van)\/?$/g,
    /\/careers\/?$/g,
    /\/careers\/how-we-hire\/?$/g,
    /\/about-us\/life-at-beanfield\/?$/g,
    /\/residential-order\/?$/g,
  ];

  let showModal = true;

  for (const regex of regexes) {
    if (regex.test(pathname)) {
      showModal = false;
    }
  }

  return showModal && localStorage.getItem("region") === null;
};

export const useShowWhichNavbars = () => {
  const { pathname } = useLocation();
  const mainHeaderRegexes = [
    /(ca|bc|on|qc|tor|mtl|van)\/?$/g,
    /\/careers\/?$/g,
    /\/careers\/how-we-hire\/?$/g,
    /\/about-us\/life-at-beanfield\/?$/g,
    /\/residential-order\/?$/g,
    /\/survey\/?$/g,
  ];

  const mainFooterRegexes = [
    /(ca|bc|on|qc|tor|mtl|van)\/?$/g,
    /\/residential-order\/?$/g,
    /\/careers\/?$/g,
    /\/survey\/?$/g,
  ];

  const careerHeaderRegexes = [
    /\/careers\/?$/g,
    /\/careers\/how-we-hire\/?$/g,
    /\/about-us\/life-at-beanfield\/?$/g,
  ];

  let showNavbar = true;
  let showFooter = true;
  let showCareer = false;

  for (const regex of mainHeaderRegexes) {
    if (regex.test(pathname)){
      showNavbar = false;
    }
  }
  for (const regex of mainFooterRegexes) {
    if (regex.test(pathname)) {
      showFooter = false;
    }
  }
  for (const regex of careerHeaderRegexes) {
    if (regex.test(pathname)) {
      showCareer = true;
    }
  }

  return { showNavbar, showFooter, showCareer};
};
