import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <circle cx={10} cy={9} r={8} fill="#00663E" />
      <circle cx={10} cy={9} r={7} stroke="#fff" strokeWidth={2} />
    </g>
    <defs>
      <filter
        id="a"
        width={20}
        height={20}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7699_64492"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7699_64492"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;
