import React, { useState } from "react";
import "components/reusable/Radio/RadioBtnStartPage.scss";

const Select = ({ name, checked = false, onClick, style, value, id }) => {
  return (
    <div className="radioBtnStart_container" style={{ ...style }}>
      <div className="radio-content-container">
        <input
        id={id}
        value={value}
        onChange={(e) => {
        onClick(e);
      }}
        type="radio"
        name={name}
        checked={checked}
        />
        <label htmlFor={id}>
          <svg
            className="radio-unchecked"
            width={30}
            height={30}
            fill="none"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="#325a32"
                  d="m19.83,37.73C9.9,37.73,1.83,29.66,1.83,19.73S9.9,1.73,19.83,1.73s18,8.07,18,18-8.07,18-18,18Zm0-32.82c-8.17,0-14.82,6.65-14.82,14.82s6.65,14.82,14.82,14.82,14.82-6.65,14.82-14.82-6.65-14.82-14.82-14.82Z"/>
          </svg>
          <svg
            className="radio-checked"
            width={30}
            height={30}
            fill="none"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="#325a32"
                  d="m18.63,2.05c3.27-.24,6.53.41,9.43,1.88.72.36,1.01,1.24.65,1.96-.36.72-1.24,1.01-1.96.65-2.43-1.23-5.16-1.78-7.9-1.57-3.42.25-6.66,1.67-9.17,4.01l-1-1.07,1,1.07c-2.51,2.34-4.15,5.47-4.64,8.86-.49,3.4.19,6.86,1.93,9.82h0c1.74,2.96,4.44,5.23,7.65,6.45,3.21,1.22,6.74,1.3,10,.24,3.26-1.06,6.07-3.2,7.95-6.07h0c1.55-2.36,2.4-5.1,2.47-7.9.02-.81.69-1.44,1.49-1.42.81.02,1.44.69,1.42,1.49-.08,3.34-1.1,6.61-2.95,9.43-2.25,3.42-5.6,5.98-9.49,7.25h0c-3.9,1.26-8.11,1.16-11.94-.29h0c-3.83-1.45-7.05-4.17-9.13-7.7-2.08-3.53-2.89-7.66-2.3-11.72.59-4.05,2.54-7.79,5.54-10.58,3-2.79,6.86-4.48,10.94-4.78l.11,1.45-.11-1.45Zm18.93,5.91c.56.58.54,1.51-.04,2.06l-16.54,15.85c-.55.53-1.42.54-1.99.03l-6.89-6.2c-.6-.54-.65-1.46-.11-2.06.54-.6,1.46-.65,2.06-.11l5.88,5.3,15.56-14.91c.58-.56,1.51-.54,2.06.04Z"/>
          </svg>
          {id}
        </label>
      </div>
    </div>
  );
};

export default Select;
