import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseSearch = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_WAGTAIL_SEARCH_URL,
});

export const supportSearch = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_WAGTAIL_SUPPORT_SEARCH_URL,
});

// content_type needs to be application/json.
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // TODO: Set this to env variable to control where info can come from.
    const token = getState()?.user?.accessToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseDraftQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_DRAFT_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.user?.accessToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseBeanfieldQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BEANFIELD_API_URL,
  credentials: "include",
  prepareHeaders: (headers) => {
    return headers;
  },
});
