import React from "react";
import "pages/TVService/TVService.scss";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import MovieMarquee from "components/Marquee/MovieMarquee/MovieMarquee";
import ChannelPackages from "components/ChannelPackages/ChannelPackages";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { generateTelevisionPanels } from "components/reusable/Package/CreatePackages";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import {
  useGetPremiumPackageQuery,
  useGetBasicPackageQuery,
} from "api/beanfield/ChannelList";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { MediaContextProvider } from "utils/MediaQueries/Media";
import { processWagtailImage } from "components/Wagtail/WagtailImage";
import Head from "components/reusable/Head/Head";

const TVService = () => {
  const { data: premiumPack } = useGetPremiumPackageQuery();
  const { data: basicPack } = useGetBasicPackageQuery();
  const {
    page: info,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetServiceTelevisionPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const basic = premiumPack?.tvPackage?.channels;
  const premium = basicPack?.tvPackage?.channels;
  const channels = { premium, basic };

  const modalContent = () => {
    return (
      <>
        <div className="tv-header-title">
          <h1>{convertHtmlToReact(info?.main_banner_title)}</h1>
          <p className="tv-header-subtitle">
            {convertHtmlToReact(info?.main_banner_subtitle)}
          </p>
        </div>
        <div className="tv-banner-image">
          <div className="main-image" style={{
            backgroundImage: `url(${processWagtailImage(
              info?.main_image
            )})`,
          }}>
          </div>
          <div className="overlay-image">
            <WagtailImage image={info?.overlay_image} />
          </div>
        </div>
      </>
    );
  };
  return (
    <MediaContextProvider>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield | Télévision" :
          "TV | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Toutes émissions et toute la musique dont vous avez envie avec la Télé Beanfield." :
          "Enjoy all the TV shows and music you crave with Beanfield TV."
        }
        imageName={"TV"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div>
        <div className="tv-banner-background">
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="tv-banner-container">
                {modalContent()}
              </div>
            </div>
          </div>
        </div>

        <div className="tv-package-container resi-container">
          <div className="resi-margin-container">
            <div className="tv-package-title-container">
              <h1>{info?.plans_header}</h1>
              <p>{info?.plans_description}</p>
            </div>
          </div>

          {navigator.userAgent !== 'ReactSnap' ? (
              info && generateTelevisionPanels(info?.plans, channels)
            ) : null
          }
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="tv-badge-container">
              {info &&
                info?.feature_badges.map((badge) => {
                  return (
                    <div>
                      <WagtailImage
                        image={badge.image}
                        style={{
                          width: 110,
                          height: 110,
                        }}
                        alt={badge.title}
                      />
                      <p className="badge-title">{badge.title}</p>
                      <p>{convertHtmlToReact(badge.text)}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="tv-marquee-container" style={{ background: "#e0d9ec" }}>
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="tv-marquee-content">
                <div className="left-content">
                  <h2>{info?.on_the_go_header}</h2>
                  <p>{info?.on_the_go_description}</p>

                  <PageLink to={info?.on_the_go_link} bold arrow={"true"}>
                    {info?.on_the_go_link_txt}
                  </PageLink>
                </div>
                <div className="right-content">
                  {info?.otg_panels && <MovieMarquee items={info?.otg_panels} />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <ChannelPackages info={info} />
          </div>
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="tv-content-container">
              <div className="tv-content-text">
                <h2>{info?.explore_channels_header}</h2>

                <PageLink bold arrow={"true"} to={info?.explore_channels_link}>
                  {info?.explore_channels_link_txt}
                </PageLink>
              </div>
              <div className="tv-content-img">
                <WagtailImage
                  image={info?.explore_channels_img}
                  alt={info?.explore_channels_link_txt}
                />
              </div>
            </div>
          </div>
        </div>

        {info?.contact_us_page?.badges?.length > 0 && (
          <ContactInfo
            title={info.contact_us_page.title}
            description={info.contact_us_page.description}
            badges={info.contact_us_page.badges}
          />
        )}
      </div>
    </MediaContextProvider>
  );
};

export default TVService;
