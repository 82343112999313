import React from "react";
import "components/OrderPage/YourAddress/YourAddress.scss";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";
import AddressModalContent from "components/OrderPage/YourAddress/AddressModalContent";

const YourAddress = () => {
  const dispatch = useDispatch();

  const { address, unit } = useSelector((state) => state.orderSlice.userInfo);
  const { data: cmsData } = useGetOrderPageData(useLocation);

  return (
    <div className="yourAddress_container">
      <span tabIndex="0">
        <h4>{cmsData?.your_address_header}</h4>
        <p>{(unit ? unit + "-" : "") + address}</p>
      </span>
      <button
        onClick={() =>
          dispatch(
            toggleModal({ children: <AddressModalContent cmsData={cmsData} /> })
          )
        }
        className="Wbold"
      >
        {cmsData?.your_address_edit_btn_txt}
      </button>
    </div>
  );
};

export default YourAddress;
