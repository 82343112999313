import React from "react";
import SinglePackage from "./SinglePackage";
import NumberPackage from "./NumberPackage";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const convertInternetToItem = (plan) => {
  return {
    title: plan.promoted_plan_header,
    description: convertHtmlToReact(plan.promoted_plan_description),
    subTitle: plan.promoted_plan_subtext,
    button: plan.promoted_plan_btn,
    cost: plan.promoted_plan_cost,
    tax: plan.promoted_plan_tax,
    internet: plan.promoted_plan_speed,
  };
};

const convertTelevisionToItem = (plan) => {
  return {
    title: plan.plan_header,
    channel_link_txt: plan.channel_link_txt,
    description: convertHtmlToReact(plan.plan_description),
    subTitle: "",
    button: plan.plan_btn,
    cost: plan.plan_cost,
    tax: plan.plan_tax,
    internet: null,
    features: plan.plan_features.map((feature) => {
      return convertHtmlToReact(feature.value);
    }),
  };
};

const convertPhoneToItem = (plan) => {
  return {
    title: plan.plan_header,
    description: convertHtmlToReact(plan.plan_description),
    subTitle: plan.plan_subtext,
    button: plan.plan_btn,
    cost: plan.plan_cost,
    tax: plan.plan_tax,
    internet: plan.plan_speed,
  };
};

export const generateInternetPanels = (plans) => {
  if (plans === undefined || !plans || plans.length === 0) return;

  if (plans.length === 1)
    return <SinglePackage item={convertInternetToItem(plans[0])} />;

  return (
    <div className="internet-title-container">
      <div className="internet-sale-package-multi">
        {plans.map((plan) => (
          <NumberPackage item={convertInternetToItem(plan)} />
        ))}
      </div>
    </div>
  );
};

export const generateTelevisionPanels = (plans, channels) => {
  return (
    <div className="tv-package">
      {plans.map((plan, i) => {
        return (
          <NumberPackage
            key={i}
            item={convertTelevisionToItem(plan)}
            channels={channels}
            itemsCount={plans.length}
          />
        );
      })}
    </div>
  );
};

export const generatePhonePanels = (plans) => {
  return <SinglePackage item={convertPhoneToItem(plans[0])} />;
};
