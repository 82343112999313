import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.41386 3.56947e-05C6.94454 3.56947e-05 4.57653 0.948286 2.83046 2.63607C1.08447 4.32386 0.103516 6.61321 0.103516 9C0.103516 11.3868 1.08447 13.6761 2.83046 15.3639C4.57645 17.0517 6.94476 18 9.41386 18C11.883 18 14.2512 17.0517 15.9973 15.3639C17.7433 13.6761 18.7242 11.3868 18.7242 9C18.7242 7.42021 18.294 5.86818 17.4768 4.5C16.6597 3.13182 15.4843 1.99568 14.069 1.20579C12.6537 0.415893 11.0481 0 9.41382 0L9.41386 3.56947e-05ZM10.9738 13.215C10.4452 13.9359 9.91595 14.4303 9.34662 14.7068V14.707C8.9343 14.9016 8.47883 14.9957 8.02018 14.9811C6.81741 14.8848 6.2586 14.0086 6.78349 12.6147L8.36467 8.4151C8.70599 7.50885 8.3752 7.23317 8.1046 7.22606C7.78248 7.22034 7.39742 7.51205 6.94945 8.1012H6.94931C6.87296 8.20458 6.72446 8.22871 6.61722 8.15533C6.51014 8.08181 6.4846 7.9384 6.56022 7.83446C7.08888 7.1136 7.61809 6.61921 8.18743 6.34268H8.18728C8.59975 6.14792 9.05522 6.05389 9.51386 6.0684C10.7166 6.16466 11.2754 7.0409 10.7506 8.43476L9.16938 12.6344C8.82806 13.5407 9.15884 13.8163 9.42945 13.8234C9.75157 13.8292 10.1366 13.5374 10.5846 12.9483L10.5847 12.9482C10.6611 12.8449 10.8096 12.8206 10.9167 12.8942C11.0239 12.9677 11.0495 13.1111 10.9738 13.2149L10.9738 13.215ZM12.2423 4.47325C12.1402 4.74417 11.9311 4.96489 11.6608 5.08668C11.3905 5.20847 11.0812 5.22158 10.801 5.12295C10.5207 5.02432 10.2925 4.82217 10.1664 4.56088C10.0404 4.29958 10.027 4.00059 10.1289 3.7297C10.2309 3.45878 10.44 3.23806 10.7103 3.11627C10.9806 2.99449 11.2899 2.98137 11.5702 3.08C11.8504 3.17863 12.0787 3.38078 12.2047 3.64207C12.3309 3.90337 12.3443 4.20236 12.2423 4.47325Z"
      fill="#201F1F"
    />
  </svg>
);

export default SvgComponent;
