import React, { useState } from "react";
import Hamburger from "hamburger-react";
import MainLogo from "assets/svg/MainLogo";
import "components/HamburgerMenu/HamburgerMenu.scss";
import Caret from "assets/Icons/Caret";
import UserIcon from "assets/svg/User";
import SearchInput from "components/Search/SearchBar/SearchInput";
import Button from "components/reusable/Button/Button";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";
import AddressModal from "components/Modals/Address/Address";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import {Link, useLocation} from "react-router-dom";
import { internalUrlReaderWithLangReg } from "utils/wagtail";

const HamburgerMenu = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shopIsOpen, setShopIsOpen] = useState(false);
  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(search).useGetMainHeaderQuery({
      queryParams: search,
      localePath: pathname.split("/")[1],
      region: pathname.split("/")[2],
    })
  );

  return (
    <div className="hamburger-container">
      <div className="hamburger-title">
        <Link to="/">
          <MainLogo />
        </Link>
        <Hamburger
          color="#202020"
          rounded
          toggled={isOpen}
          toggle={setIsOpen}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <div className="hamburger-menu" aria-expanded={!isOpen}>
        <div onClick={() => setShopIsOpen(!shopIsOpen)}>
          <div className="hamburger-shop-item">
            <p>{info?.services_dropdown_title}</p>
            <Caret
              className="hamburger-carrot"
              aria-expanded={!shopIsOpen}
              color={"black"}
            />
          </div>

          <div className="hamburger-shop" aria-expanded={!shopIsOpen}>
            {info?.services_items?.map((item, index) => {
              return (
                <a
                  href={
                    "/" + internalUrlReaderWithLangReg(item.link, pathname)
                  }
                  key={index}
                >
                  <p>{item.text}</p>
                </a>
              );
            })}
          </div>
        </div>

        <div onClick={() => setAboutIsOpen(!aboutIsOpen)}>
          <div className="hamburger-about-item">
            <p>{info?.about_dropdown_title}</p>
            <Caret
              className="hamburger-carrot"
              aria-expanded={!aboutIsOpen}
              color={"black"}
            />
          </div>

          <div className="hamburger-about" aria-expanded={!aboutIsOpen}>
            {info?.about_items?.map((item, index) => {
              return (
                <a
                  href={
                    "/" + internalUrlReaderWithLangReg(item.link, pathname)
                  }
                  key={index}
                >
                  <p>{item.text}</p>
                </a>
              );
            })}
          </div>
        </div>

        <div className="hamburger-item">
          <a href={links.contact}>
            {info?.link_1_txt}
          </a>
        </div>

        <div className="hamburger-item">
          <a href={links.support}>
            {info?.link_2_txt}
          </a>
        </div>

        <div className="hamburger-sign-in">
          <UserIcon color={"black"} />
          <a href={info?.nav_sign_in_button_link}>
            {info?.nav_sign_in_button_txt}
          </a>
        </div>

        <SearchInput hamburger />

        <Button
          click={() => dispatch(toggleModal({ children: <AddressModal /> }))}
        >
          {info?.btn_txt}
        </Button>
      </div>
    </div>
  );
};

export default HamburgerMenu;
