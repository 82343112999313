import React from "react";
import "components/reusable/Modal/Modal.scss";
import BackDrop from "components/reusable/Modal/BackDrop";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import Close from "assets/Icons/Close";

const Modal = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <BackDrop setShowModal={() => dispatch(toggleModal())}>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.22 }}
        className="modal_container"
      >
        <div
          onClick={() => dispatch(toggleModal())}
          className="close_modal_btn"
        >
          <Close />
        </div>

        {Object.keys(children).length ? children : null}
      </motion.div>
    </BackDrop>
  );
};

export default Modal;
