import React, { useState } from "react";
import "components/ChannelPackages/ThemeCard/ThemeCard.scss";
import Star from "assets/Icons/Star";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { modalContents } from "components/OrderPage/Accordions/ModalContents";
import { useDispatch } from "react-redux";

const ThemeCard = ({ item, seeAllChannelsString }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeemore] = useState(
    item?.channels?.length > 6 ? true : false
  );

  return (
    <div className="theme-card">
      <div className="theme-card-text">
        <h2>{item.name}</h2>
        <p>${item.price}/mo.</p>
        {!item.premium ? null : (
          <div className="theme-card-star">
            <Star />
          </div>
        )}
      </div>
      <div className="theme-card-channel-container">
        {item.channels.map((channel, index) => {
          if (index < 6) {
            return (
              <div className="theme-card-image-container">
                <img
                  alt={channel.name}
                  src={channel.logoUrl}
                  title={channel.name}
                  key={index}
                />
              </div>
            );
          }
        })}
      </div>

      {!seeMore ? null : (
        <p
          onClick={() =>
            dispatch(
              toggleModal({
                children: modalContents.themePacks(item.channels, item.name),
              })
            )
          }
          className="Wbold theme-card-all-channels-text"
        >
          {seeAllChannelsString}
        </p>
      )}
    </div>
  );
};

export default ThemeCard;
