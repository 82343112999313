import React, { useState, useEffect } from "react";
import "components/reusable/Link/PageLink/PageLink.scss";
import Carot from "assets/Icons/Caret";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const createStepBackPath = (pathLength) => {
  let stepBack = "";
  for (let i = 0; i < pathLength - 1; i++) {
    stepBack += "../";
  }
  return stepBack;
};

const internalLinkRegex = /\"https\:\/\/internallink\.com[^"]+\"/g;

const buildNewLink = (match, location) => {
  let link = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port !== 80) link += `:${window.location.port}`;

  link += `/${location[1]}/${location[2]}/${match
    .split("/")
    .slice(3)
    .join("/")}`;

  return '"' + link;
};

export const processInternalLinkAnchors = (DOMString, location) => {
  // Find all hrefs with an https://internallink.com address.
  let matches = internalLinkRegex.exec(DOMString);

  if (!matches) return DOMString;

  let newDOMString = `${DOMString}`;
  for (let match of matches) {
    newDOMString = newDOMString.replace(match, buildNewLink(match, location));
  }

  return newDOMString;
};

export const WagTailDOMString = ({ DOMString }) => {
  const location = useLocation();
  const [newDOMString, setNewDOMString] = useState(DOMString);

  useEffect(() => {
    setNewDOMString(
      processInternalLinkAnchors(DOMString, location.pathname.split("/"))
    );
  }, [DOMString, location.pathname]);

  return <>{convertHtmlToReact(newDOMString)}</>;
};

const PageLink = (props) => {
  const location = useLocation().pathname;

  let uri;
  if (props?.to?.includes("https://internallink.com")) {
    const pathLength = location.split("/").length;
    uri =
      createStepBackPath(pathLength) +
      internalUrlReaderWithLangReg(props.to, location);
  } else {
    uri = props?.to;
  }

  return (
    <div className={`page-link-container ${props.border ? 'bordered' : ''}`}>
      <Link
        to={`${uri}`}
        relative="path"
        className={props.bold ? "Wbold" : null}
        target={props.target ? "_blank" : null}
      >
        {props.children}
      </Link>

      {props.arrow ? (
        <Carot color={"#325a32"} turn={"right"} arrow={props.arrow} />
      ) : null}
    </div>
  );
};

export default PageLink;
