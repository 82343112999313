import React, { useState } from "react";
import "components/BusinessCampaign/TextTitleComponent.scss";
import {convertHtmlToReact} from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const TextTitleComponent = ({
   title_text,
   description
}) => {
   return (
     <>
       <div className="resi-container">
         <div className="resi-margin-container">
           <div className="text-title-component-container Wbold">
             <div className="title-text" tabIndex="0">
               {convertHtmlToReact(title_text)}
             </div>
             <div className="text-title-description">
               {convertHtmlToReact(description)}
             </div>
           </div>
         </div>
       </div>
     </>
   );
 };

export default TextTitleComponent;