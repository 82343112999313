import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

const getInitialSelection = (urlPosition, options, pathname) => {
  for (const item of options) {
    if (item.value === pathname.split("/")[urlPosition]) {
      return item;
    }
  }
};

const Dropdown = ({
  options,
  BGColor,
  textColor,
  placeholder,
  urlPosition,
  isOpenMenu,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const selectRef = useRef();
  const [selection, setSelection] = useState(
    getInitialSelection(urlPosition, options, pathname)
  );
  const colorStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "rgba(0, 0, 0, 0);",
      border: "none",
      width: 85,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      padding: 0,
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
      },
      "@media (max-width: 768px)": {
        width: 80,
      },
      "@media (max-width: 380px)": {
        width: 70,
      },
    }),
    option: (styles, state) => ({
      ...styles,
      background: (state.isSelected ||state.isFocused) ? "#d0e88a" : BGColor,
      color: (state.isSelected ||state.isFocused) ? "#000" : textColor,
      "&:hover": {
        background: "#d0e88a",
        cursor: "pointer",
        color: "#000"
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: textColor,
    }),
    menu: (styles) => ({
      ...styles,
      width: "max-content",
      minWidth: "100%",
      margin: 0,
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
    }),
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: textColor,
      "&:hover": {
        color: textColor,
        cursor: "pointer",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: textColor,
    }),
  };

  const handleChange = (value) => {
    localStorage.setItem("language", value.value);
    if (urlPosition === 1) {
      document.cookie = `_LOCALE_=${value}; path=/`;
      navigate(
        `/${value.value}/${pathname.split("/")[2]}/${pathname
          .split("/")
          .slice(3)
          .join("/")}`
      );
    }

    if (urlPosition === 2) {
      localStorage.setItem("region", value.value);
      navigate(
        `/${pathname.split("/")[1]}/${value.value}/${pathname
          .split("/")
          .slice(3)
          .join("/")}`
      );
    }
  };

  return (
    <div style={{ zIndex: 4 }}>
      <Select
        styles={colorStyles}
        options={options}
        placeholder={placeholder.toUpperCase()}
        components={{ IndicatorSeparator: () => null }}
        onChange={(value) => {
          handleChange(value);
          setSelection(value);
        }}
        value={selection?.value}
        isSearchable={false}
        menuIsOpen={isOpenMenu}
        ref={selectRef}
      />
    </div>
  );
};

export default Dropdown;
