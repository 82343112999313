import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={10}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.2 1.143a.664.664 0 0 0-.94 0L5 4.396l-3.26-3.26a.664.664 0 1 0-.94.94l3.26 3.26L.8 8.596a.664.664 0 1 0 .94.94L5 6.276l3.26 3.26a.664.664 0 1 0 .94-.94l-3.26-3.26 3.26-3.26a.668.668 0 0 0 0-.933Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
