import React from "react";
import "components/reusable/Button/Button.scss";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

const Button = ({
  click = null,
  children,
  btnStyle = null,
  disabled = false,
  style = {},
  extraClass = "",
  preventDefault = false,
  loading = false,
}) => {
  const time = useSelector((state) => state.orderSlice.order.installationTime);
  let classes = btnStyle ? btnStyle : "beanfield-button Wbold";

  if (disabled) {
    classes += " disabled";
  }

  return (
    <button
      type={click ? "button" : "submit"}
      disabled={disabled}
      onClick={(e) => {
        preventDefault && e.preventDefault();
        click && click();
      }}
      className={`${classes} ${
        time === children ? "selected" : ""
      } ${extraClass}`}
      style={{ ...style }}
    >
      {loading ? (
        <div style={{ minWidth: "115px" }}>
          <ClipLoader
            color={"#336600"}
            size={18}
            aria-label="Loading Spinner"
          />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
