import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePagesApiParser = ({ data, isLoading, isError, error }) => {
  const [page, setPage] = useState(null);
  const location = useLocation();
  const region = useLocation().pathname.split("/")[2];

  useEffect(() => {
    if (!data?.items) return setPage(data);
    if (!region) return setPage(data.items[0]);

    let ca;
    for (const item of data.items) {
      if (item.REGION === region) return setPage(item);
      if (item.REGION === "ca") ca = item;
    }
    setPage(ca);
  }, [data]);

  const homeRegex = /\/residential\/?/g;

  useEffect(() => {
    if (!isLoading) {
      // Redirect to home if no pages are found.
      if (data?.meta?.total_count === 0 && !homeRegex.test(location.pathname)) {
        let baseLink = `${window.location.protocol}//${window.location.hostname}`;
        if (window.location.port !== 80) baseLink += `:${window.location.port}`;
        baseLink +=
          "/" +
          location.pathname.split("/")[1] +
          "/" +
          location.pathname.split("/")[2];
        baseLink += "/residential";
        window.location.href = baseLink;
      }
    }
  }, [isLoading, location]);

  return { page, isLoading, isError, error };
};
