import React from "react";
import "components/Modals/Select/Select.scss";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import EmailModal from "components/Modals/Email/Email";
import FormModal from "components/Modals/Form/Form";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const SelectModal = ({ address, unit }) => {
  const dispatch = useDispatch();
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);

  const handleTenant = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <EmailModal /> }));
  };

  const handlePM = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <FormModal PM editAddress={address} unit={unit}/> }));
  };

  return (
    <div className="select-modal-container">
      <div>
        <h2>{cmsData?.modal_int_header}</h2>
        {convertHtmlToReact(cmsData?.modal_int_description)}
      </div>

      <div className="select-modal-button-container">
        <button onClick={() => handleTenant()} className="Wbold">
          {cmsData?.modal_int_tenown_btn}
        </button>
        <button onClick={() => handlePM()} className="Wbold">
          {cmsData?.modal_int_mngboard_btn}
        </button>
      </div>
    </div>
  );
};

export default SelectModal;
