import React from "react";
import "components/Modals/OldResidential/OldResidential.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { useGetHomePageData } from "services/api/useGetHomePageData";
import { useLocation } from "react-router";
import { toggleModal } from "store/reducers/modal/modalSlice";
import AddressModal from "components/Modals/Address/Address";
import { useDispatch } from "react-redux";

const OldResidentialModal = () => {
  //CMS API CALL
  const { data: cmsData } = useGetHomePageData(useLocation);
  const dispatch = useDispatch();

  const handleReturn = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <AddressModal /> }));
  };

  return (
    <div className="old-residential-container">
      {convertHtmlToReact(cmsData?.modal_old_residential_header)}
      <div className="old-residential-body">
        {convertHtmlToReact(cmsData?.modal_old_residential_body)}
        <p onClick={() => handleReturn()} className="Wbold old-residential-text">
          {cmsData?.modal_old_residential_change_address_link_text}
        </p>
      </div>
    </div>
  );
};

export default OldResidentialModal;
