import { createApi } from "@reduxjs/toolkit/query/react";
import { baseBeanfieldQuery } from "api/config";

export const AvailabilityApi = createApi({
  reducerPath: "AvailabilityApi",
  baseQuery: baseBeanfieldQuery,
  tagTypes: ["buildings"],
  endpoints: (builder) => ({
    getAllBuildings: builder.query({
      query: ({ survey }) => {
        return {
          url: "/v2/residential-buildings",
          method: "GET",
          providesTags: ["buildings"],
        };
      },
    }),
  }),
});

export const { useGetAllBuildingsQuery } = AvailabilityApi;
