import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 19 20"
    xmlns="http://www.w3.org/2000/svg"
    style={{paddingTop: 3}}
    {...props}
  >
<path d="M13.6009 1H16.0544L10.6943 7.12618L17 15.4625H12.0627L8.19566 10.4066L3.77087 15.4625H1.31595L7.04904 8.90989L1 1H6.06262L9.55811 5.62133L13.6009 1ZM12.7399 13.994H14.0993L5.32392 2.39137H3.86506L12.7399 13.994Z" fill="#325a32"/>
</svg>

);

export default SvgComponent;
