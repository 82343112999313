const parseQueryStringToDictionary = (queryString) => {
  const dict = {};
  if (queryString.indexOf("?") === 0) {
    queryString = queryString.substring(1);
  }

  const parts = queryString.split("&");

  for (let i = 0; i < parts.length; i++) {
    const p = parts[i];

    const kv = p.split("=");

    const k = kv[0];
    let v = kv[1];

    v = decodeURIComponent(v);
    v = v.replace(/\+/g, " ");

    dict[k] = v;
  }

  return dict;
};

export default parseQueryStringToDictionary;
