import React from "react";
import "components/Carousel/ReviewCarousel/ReviewCarousel.scss";
import Slider from "react-slick";
import BlogRight from "assets/Carousel/Blog/BlogRight";
import BlogLeft from "assets/Carousel/Blog/BlogLeft";
import ActiveDot from "assets/Carousel/ActiveDot";
import InactiveDot from "assets/Carousel/InactiveDot";
import Star from "assets/Icons/Star";
import useWindowDimensions from "utils/WindowWidth";

const Carousel = ({ items, review, internetService }) => {
  const { width } = useWindowDimensions();
  const isInfinite = items?.length > 3;
  const isMobile = width < 768;

  const settings = {
    dots: false,
    infinite: internetService ? isInfinite || isMobile : true,
    speed: 500,
    slidesToShow: 3.65,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          dots: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: (
      <div>
        <BlogRight />
      </div>
    ),
    prevArrow: (
      <div>
        <BlogLeft />
      </div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          position: "relative",
          top: 0,
        }}
      >
        {dots.map((item, index) => {
          if (item.props.className === "slick-active") {
            return <ActiveDot key={index} />;
          } else {
            return <InactiveDot key={index} />;
          }
        })}
      </div>
    ),
  };

  //CALCULATE RATING AND PRINT STARS
  const calculateRating = (item) => {
    let ratingNumber = Math.ceil(Number(item?.rating));

    if (ratingNumber > 5) {
      ratingNumber = 5;
    } else if (ratingNumber <= 0) {
      ratingNumber = 1;
    }

    return new Array(ratingNumber).fill(0).map(() => {
      return <Star width="20" height="19" />;
    });
  };

  return (
    <div className="review-carousel-container">
      <Slider {...settings}>
        {items &&
          items.map((item, index) => {
            return (
              <div key={index}>
                <div
                  style={{ height: internetService ? "19.5rem" : "19.5rem" }}
                  className="review-carousel"
                >
                  {/* RATING */}
                  {review ? (
                    <div className="review-stars">{calculateRating(item)}</div>
                  ) : null}
                  {/* BODY */}
                  <p className="review-carousel-text">{item?.body}</p>
                  {/* FOOTER/NAME */}
                  <div
                    className={
                      !review
                        ? "review-carousel-stacked-footer"
                        : "review-carousel-footer"
                    }
                  >
                    <p className="Wbold review-carousel-name">
                      {review ? item.reviewer : item?.name}
                    </p>
                    <p className="review-carousel-location">{item?.location}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Carousel;
