import React from "react";
import "components/Marquee/TextMarquee/TextMarquee.scss";
import Marquee from "react-fast-marquee";

const TextMarquee = ({
  textOne,
  textTwo,
  textThree,
  colorPrimary,
  colorSecondary,
  double,
}) => {
  return double ? (
    <Marquee speed={50} gradient={false}>
      <span style={{ color: colorPrimary }}>{textOne}</span>
      <span style={{ color: colorSecondary }}>{textTwo}</span>
      <span style={{ color: colorPrimary }}>{textThree}</span>
      <span style={{ color: colorSecondary }}>{textOne}</span>
      <span style={{ color: colorPrimary }}>{textTwo}</span>
      <span style={{ color: colorSecondary }}>{textThree}</span>
    </Marquee>
  ) : (
    <Marquee speed={50} gradient={false}>
      <span style={{ color: colorPrimary }}>{textOne}</span>
      <span style={{ color: colorSecondary }}>{textTwo}</span>
      <span style={{ color: colorPrimary }}>{textOne}</span>
      <span style={{ color: colorSecondary }}>{textTwo}</span>
    </Marquee>
  );
};

export default TextMarquee;
