import { getQueries } from "api/wagtailAdapter";

export const useGetHomePageData = (useLocation) => {
  let { data, isLoading, isError, error } = getQueries(
    useLocation().search
  ).useGetHomePageQuery({
    queryParams: useLocation().search,
    localePath: useLocation().pathname.split("/")[1],
    region: useLocation().pathname.split("/")[2],
  });
  data = data?.items ? data?.items[0] : data;
  return { data, isLoading, isError, error };
};
