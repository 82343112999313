import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.333 16.08c0-7.408-5.969-13.413-13.333-13.413S2.667 8.672 2.667 16.08c0 6.697 4.874 12.246 11.25 13.252V19.96H10.53V16.08h3.385v-2.955c0-3.361 1.99-5.22 5.037-5.22 1.458 0 2.985.263 2.985.263v3.301h-1.683c-1.656 0-2.171 1.035-2.171 2.096v2.516h3.697l-.59 3.877h-3.107v9.375c6.375-1.006 11.25-6.555 11.25-13.252Z"
      fill="#325a32"
    />
  </svg>
);

export default SvgComponent;
