import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={64}
    height={64}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <g clipPath="url(#b)">
        <rect
          width={32}
          height={32}
          rx={16}
          transform="matrix(-1 0 0 1 44 12)"
          fill="#325a32"
        />
        <g clipPath="url(#c)">
          <path
            d="M32.517 21.533 26.05 28l6.467 6.467c.65.65.65 1.7 0 2.35-.65.65-1.7.65-2.35 0l-7.65-7.65a1.66 1.66 0 0 1 0-2.35l7.65-7.65c.65-.65 1.7-.65 2.35 0 .633.65.65 1.716 0 2.366Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <rect
          width={32}
          height={32}
          rx={16}
          transform="matrix(-1 0 0 1 44 12)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="c">
        <path
          fill="#fff"
          transform="matrix(0 -1 -1 0 48 48)"
          d="M0 0h40v40H0z"
        />
      </clipPath>
      <filter
        id="a"
        x={0}
        y={0}
        width={64}
        height={64}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={4} dy={4} />
        <feGaussianBlur stdDeviation={8} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8481_74897"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_8481_74897"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgComponent;
