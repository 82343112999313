import React from "react";
import "pages/LifeAtBeanfield/LifeAtBeanfield.scss";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import useWindowDimensions from "utils/WindowWidth";
import BlogLeft from "assets/Carousel/Blog/BlogLeft";
import BlogRight from "assets/Carousel/Blog/BlogRight";
import Carousel from "nuka-carousel";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import Head from "components/reusable/Head/Head";

const ImagePanel = ({ body, list_left, list_right, image }) => {
  return (
    <div className="tbd_photo_wrapper">
      <WagtailImage alt="Build lasting connections" image={image} />
      <div className="connections_resource_right_side">
        {body}
        {(list_left || list_right) && (
          <div className="ul_lists">
            {list_left}
            {list_right}
          </div>
        )}
      </div>
    </div>
  );
};

const LifeAtBeanfield = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetLifeAtBeanfieldPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const items = [1, 2, 3, 4, 5];
  const { width } = useWindowDimensions();
  return (
    <>
      <Head
        c
        imageName={"LifeAtBeanfield"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="lifeatbeanfield_container">
        {/* DESCRIPTION */}
        <div className="lifeatbeanfield_description">
          {width > 768 ? (
            <>
              <div className="left_side">
                {convertHtmlToReact(info?.header_body)}
              </div>
              <div className="right_side">
                <WagtailImage alt="Beanfield Office" image={info?.header_img} />
              </div>
            </>
          ) : (
            <>
              <div className="right_side">
                <WagtailImage alt="Beanfield Office" image={info?.header_img} />
              </div>
              <div className="left_side">
                {convertHtmlToReact(info?.header_body)}
              </div>
            </>
          )}
        </div>
        {/* PART OF SOMETHING BIGGER */}
        <div className="lifeatbeanfield_somethingBigger">
          <h2>{info?.content_header}</h2>
          <WagtailImage
            width="100%"
            alt="Employees in office at large desk"
            image={info?.content_1_img}
          />

          {convertHtmlToReact(info?.content_1_body)}

          <div className="player">
            {info?.content_2_video === null ? (
              <WagtailImage image={info?.content_2_video_img} />
            ) : (
              <VideoPlayer
                text={info?.content_2_video_text}
                url={info?.content_2_video}
              />
            )}
          </div>
          {/* DIVERSITY */}
          <div className="diversity">
            <div className="diversity_left">
              {convertHtmlToReact(info?.content_2_body_left)}
            </div>
            <div className="diversity_right">
              <div className="image">
                <div className="bg" />
              </div>
              <div className="title_text">
                {convertHtmlToReact(info?.content_2_body_right)}

                <PageLink target to={info?.content_2_link_right}>
                  {info?.content_2_link_txt_right}
                </PageLink>
              </div>
            </div>
          </div>

          {/* CONNECTIONS & RESOURCE GROUP */}
          <div className="connections_resource_container">
            <div className="tbd_photos_container">
              {info?.image_panel?.length > 0 &&
                info.image_panel
                  .map((panel) => {
                    return {
                      body: convertHtmlToReact(panel.body),
                      list_left: panel.list_left_enabled
                        ? convertHtmlToReact(panel.list_left)
                        : null,
                      list_right: panel.list_right_enabled
                        ? convertHtmlToReact(panel.list_right)
                        : null,
                      image: panel.img ? panel.img : null,
                    };
                  })
                  .map((item) => {
                    return (
                      <ImagePanel
                        body={item.body}
                        list_left={item.list_left}
                        list_right={item.list_right}
                        image={item.image}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>

      {/*<div className="instagram-outer-container">*/}
      {/*  <div className="inner-container">*/}
      {/*    <div className="left">{convertHtmlToReact(info?.instagram_body)}</div>*/}
      {/*    <div className="right">*/}
      {/*      <Carousel*/}
      {/*        //if not enough elements them spacing need to be increased*/}
      {/*        cellSpacing={*/}
      {/*          width < 768 ? 15 : info?.instagram_panel?.length >= 4 ? 15 : 45*/}
      {/*        }*/}
      {/*        slidesToShow={*/}
      {/*          width < 768 ? 1.25 : width > 1440 ? 3.5 : width / 500*/}
      {/*        }*/}
      {/*        slidesToScroll={1}*/}
      {/*        cellAlign={width < 768 ? "center" : "left"}*/}
      {/*        //CUSTOM CONTROL BUTTONS*/}
      {/*        renderBottomCenterControls={(props) => {*/}
      {/*          return width < 768 ? (*/}
      {/*            <div className="carousel-dots-container">*/}
      {/*              {props.pagingDotsIndices.map((slide, index) => (*/}
      {/*                <div*/}
      {/*                  className="carousel-dot"*/}
      {/*                  style={{*/}
      {/*                    backgroundColor:*/}
      {/*                      props.currentSlide === index ? "black" : "white",*/}
      {/*                  }}*/}
      {/*                ></div>*/}
      {/*              ))}*/}
      {/*            </div>*/}
      {/*          ) : null;*/}
      {/*        }}*/}
      {/*        renderCenterLeftControls={({ previousDisabled, previousSlide }) =>*/}
      {/*          width > 768 ? (*/}
      {/*            <div*/}
      {/*              style={{*/}
      {/*                cursor: "pointer",*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <BlogLeft*/}
      {/*                disabled={previousDisabled}*/}
      {/*                onClick={previousSlide}*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          ) : (*/}
      {/*            <></>*/}
      {/*          )*/}
      {/*        }*/}
      {/*        renderCenterRightControls={({ nextDisabled, nextSlide }) =>*/}
      {/*          width > 768 ? (*/}
      {/*            <div*/}
      {/*              style={{*/}
      {/*                cursor: "pointer",*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <BlogRight disabled={nextDisabled} onClick={nextSlide} />*/}
      {/*            </div>*/}
      {/*          ) : (*/}
      {/*            <></>*/}
      {/*          )*/}
      {/*        }*/}
      {/*      >*/}
      {/*        {info?.instagram_panel.map((item, index) => (*/}
      {/*          <div className="blog-carousel-card">*/}
      {/*            <WagtailImage alt="instagram photo" image={item?.img} />*/}
      {/*          </div>*/}
      {/*        ))}*/}
      {/*      </Carousel>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default LifeAtBeanfield;
