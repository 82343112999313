import React from "react";
import "components/Search/SearchResultItem/SearchResultItem.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { useLocation } from "react-router-dom";

const SearchResultItem = ({ item }) => {
  const { pathname } = useLocation();

  const handleDirect = () => {
    const path = pathname.split("/");
    let baseLink = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port !== 80) baseLink += `:${window.location.port}`;
    baseLink += "/" + path[1] + "/" + path[2];

    window.location.href = baseLink + item.url_path;
  };

  return (
    <div
      onClick={() => handleDirect()}
      className="search-result-item-container"
      tabIndex="0"
    >
      <h2>{item.title?.split(" | ")[0]}</h2>

      <p className="search-result-path">in {item.slug}</p>

      <p className="search-result-text">
        {convertHtmlToReact(item.search_description)}
      </p>
    </div>
  );
};

export default SearchResultItem;
