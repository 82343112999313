import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.414 17.716c0 .644-.184 1.196-.644 1.564-.46.367-1.011.551-1.747.551h-.827v2.115H5.908V15.51h2.115c.736 0 1.38.184 1.747.552.368.368.644.92.644 1.655Zm-1.287 0c0-.275-.092-.551-.276-.735-.184-.184-.46-.276-.828-.276h-.827v2.115h.827c.368 0 .644-.092.828-.276.184-.276.276-.552.276-.828ZM16.941 18.728c0 1.103-.276 1.839-.828 2.39-.551.552-1.379.828-2.482.828h-2.207V15.51h2.207c1.011 0 1.747.276 2.39.828.644.644.92 1.38.92 2.39Zm-1.287 0c0-.644-.184-1.104-.552-1.471-.368-.368-.828-.552-1.471-.552h-1.104v4.23h.92c1.563-.092 2.207-.736 2.207-2.207ZM22.093 15.51v1.103h-2.759v1.471h2.667v1.104h-2.667v2.758H18.14V15.51h3.954ZM23.196 14.41H4.806c-.276 0-.46.18-.46-.096V1.164c0-.275.184-.46.46-.46H15.84c.092 0 .276.093.368.093l7.356 7.448c.092.092.092.184.092.368v5.701c0 .184-.184.095-.46.095Zm-17.93-.556h17.47V8.797l-7.08-7.173H5.266v12.23ZM23.196 27.372H4.806c-.276 0-.46-.184-.46-.46v-3.678c0-.276.184-.46.46-.46h18.39c.276 0 .46.184.46.46v3.678c0 .276-.184.46-.46.46Zm-17.93-.92h17.47v-2.759H5.267v2.76Z"
      fill="#000"
    />
    <path
      d="M23.195 9.073h-7.356c-.276 0-.46-.184-.46-.46V1.165c0-.184.092-.368.276-.46.184-.092.368 0 .46.092l7.264 7.356c.092.092.184.184.184.368.092.368-.092.552-.368.552Zm-6.897-.92h5.794l-5.794-5.885v5.885ZM26.873 23.693H1.126c-.276 0-.46-.184-.46-.46v-9.011c0-.276.184-.46.46-.46h25.747c.276 0 .46.184.46.46v9.011c0 .276-.184.46-.46.46Zm-25.287-.92h24.827v-8.091H1.586v8.092Z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
