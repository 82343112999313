import React, { useEffect, useState } from "react";
import "pages/SearchResults/SearchResults.scss";
import SearchResultItem from "components/Search/SearchResultItem/SearchResultItem";
import Caret from "assets/Icons/Caret";
import SadSearch from "assets/Icons/SadSearch";
import { useGetSearchQuery } from "api/cms/live/Search";
import { useLocation } from "react-router-dom";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { getQueries } from "api/wagtailAdapter";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import {internalUrlReaderWithLangReg} from "../../utils/wagtail";

const handleInternalLinks = (link) => {
  let uri;
  const language = localStorage.getItem("language");
  const region = localStorage.getItem("region");
  if (link.includes("https://internallink.com")) {
    uri =
      "/" + internalUrlReaderWithLangReg(link, `/${language}/${region}/`);
  } else {
    uri = link;
  }
  return uri;
};

const transform = (node) => {
  if (node.type === "tag" && node.name === "a") {
    node.attribs.href = handleInternalLinks(node.attribs.href);
  }
};

const SearchResult = () => {
  const { search, pathname } = useLocation();
  const splitSearch = search.split("=");
  const splitPath = pathname.split("/");
  const [page, setPage] = useState(1);
  const { data } = useGetSearchQuery({
    searchQuery: splitSearch[1],
    localePath: splitPath[1],
    region: splitPath[2],
    page,
  });
  const [totalPages, setTotalPages] = useState(data?.num_pages);
  const {
    page: info,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetHomePageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );
  useEffect(() => {
    setTotalPages(data?.num_pages);
  }, [data]);

  return (
    <div className="resi-container">
      <div className="resi-margin-container">
        {!data ? null : (
          <div className="search-result-container">
            {data?.search_results?.length > 0 ? (
              <div>
                <div className="search-result-header" tabIndex="0">
                  <p>{info?.matches_found_txt}</p>
                  <p className="Wbold">“{decodeURI(splitSearch[1])}”</p>
                </div>

                <div className="search-result-content">
                  {data?.search_results?.map(
                    (data, index) => {
                      return <SearchResultItem item={data} key={index} />;
                    })}
                </div>
              </div>
            ) : (
              <div>
                <div className="search-result-header">
                  <SadSearch />
                  <p>{info?.matches_not_found_txt}</p>
                  <p className="Wbold">“{decodeURI(splitSearch[1])}”</p>
                </div>
                <div className="no-results-tips">
                  {convertHtmlToReact(info?.search_tips, {transform: transform})}
                </div>
              </div>
            )}

            {totalPages ? (
              <div  className="search-result-page-container">
                <button
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                >
                  <Caret color={"#000"} turn={"left"} />
                </button>

                <p>{page}</p>
                <p>of</p>
                <p className="Wbold">{totalPages}</p>

                <button
                  disabled={page === totalPages}
                  onClick={() => setPage(page + 1)}
                >
                  <Caret color={"#000"} turn={"right"} />
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResult;
