import "components/BusinessCampaign/FooterComponent.scss";
import MainLogo from "assets/svg/MainLogo";
import {convertHtmlToReact} from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const FooterComponent = ({
    column_1_text,
    column_2_text,
    column_3_text,
}) => {
    return (
      <>
          <div className="footer-wrapper resi-container">
              <hr className="black-line resi-margin-container" />
              <footer className="resi-margin-container">
                  <div className="footer-flex">
                      <div className="logo"><MainLogo /></div>
                      <div tabIndex="0">{ convertHtmlToReact(column_1_text) }</div>
                      <div tabIndex="0">{ convertHtmlToReact(column_2_text) }</div>
                      <div tabIndex="0">{ convertHtmlToReact(column_3_text) }</div>
                      <div className="copyright" tabIndex="0">&copy; Beanfield, 2024. All rights reserved</div>
                  </div>
              </footer>
              <hr className="black-line bottom-line resi-margin-container" />
          </div>
      </>
    );
};

export default FooterComponent;