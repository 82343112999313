import * as React from "react";

/**
 *
 * @param {string} color right,left,up,down to render corresponding arrows
 * @param {string} turn right,left,up,down to render corresponding arrows
 * @returns
 */

const SvgComponent = (props) => {
  let turn;
  switch (props.turn) {
    case "right":
      turn = -0.25;
      break;
    case "left":
      turn = 0.25;
      break;
    case "up":
      turn = 0.5;
      break;
    case "down":
      turn = 0;
      break;
    default:
      turn = 0;
  }

  return (
    <div
      style={{
        transform: `rotate(${turn}turn)`,
        bottom: props.arrow,
        ...props.containerStyle,
      }}
    >
      <svg
        width={12}
        height={8}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.88 1.29 6 5.17 2.12 1.29A.996.996 0 1 0 .71 2.7L5.3 7.29c.39.39 1.02.39 1.41 0L11.3 2.7a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0Z"
          fill={props.color}
        />
      </svg>
    </div>
  );
};

export default SvgComponent;
