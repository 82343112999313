import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={23}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 19.027 5.533 3.346c1.014.614 2.254-.293 1.987-1.44l-1.467-6.293 4.894-4.24c.893-.773.413-2.24-.76-2.333l-6.44-.547-2.52-5.947c-.454-1.08-2-1.08-2.454 0l-2.52 5.934-6.44.546c-1.173.094-1.653 1.56-.76 2.334l4.894 4.24L4.48 20.92c-.267 1.147.973 2.053 1.987 1.44L12 19.027Z"
      fill="#325a32"
    />
  </svg>
);

export default SvgComponent;
