import "components/reusable/Verbatim/Verbatim.scss";

const Verbatim = ({ name , question, number, callback, placeholder, error, classes}) => {
  const classNames = `verbatim-container ${error? "error": ''} ${classes}`;
  return (
    <div className={classNames} key={name}>
       <div className="question">
           {number? <span>{number}.</span>: null }
           <span>{ question }</span>
       </div>
       <div className="question">
          <textarea name={name + "-answer"} cols="70" rows="5" onChange={(e) => { callback(e);}} placeholder={placeholder}></textarea>
       </div>
    </div>
  );
};

export default Verbatim;