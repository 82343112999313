import React from "react";
import "components/Marquee/MovieMarquee/MovieMarquee.scss";
import { VerticalTicker } from "react-infinite-ticker";
import MarqueeCard from "../MarqueeCard/MarqueeCard";
import { MobileMoviesArr } from "mock/ServicesPages/MoviesArr";
import Marquee from "react-fast-marquee";

const processMovies = (movies) => {
  const output = [[], [], [], []];

  for (const movie of movies) {
    output[movie.column - 1].push(movie);
  }

  return output;
};

const MovieMarquee = ({ items }) => {
  const movies = processMovies(items);
  return (
    <>
      <div className="movie-marquee-container-desktop">
        {movies?.map((column, index) => {
          return (
            <div
              className={
                index % 2 === 0 ? "movie-marquee-up" : "movie-marquee-down"
              }
              key={index}
            >
              <VerticalTicker duration={15000} delay={0}>
                {column.map((movie, index) => {
                  return <MarqueeCard movie={movie} test="Vertical" key={index}/>;
                })}
              </VerticalTicker>
            </div>
          );
        })}
      </div>
      <div className="movie-marquee-container-mobile">
        <Marquee speed={10} gradient={false}>
          {items.map((movie) => {
            return <MarqueeCard movie={movie} test="Mobile"/>;
          })}
        </Marquee>
      </div>
    </>
  );
};

export default MovieMarquee;
