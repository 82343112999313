import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 6.833H6.834V11a.836.836 0 0 1-.834.833.836.836 0 0 1-.833-.833V6.833H1A.836.836 0 0 1 .167 6c0-.458.375-.833.833-.833h4.167V1c0-.458.375-.833.833-.833.459 0 .834.375.834.833v4.167H11c.459 0 .834.375.834.833a.836.836 0 0 1-.834.833Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
