import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.603 10.56c.016.235.016.47.016.707 0 7.23-5.503 15.567-15.566 15.567v-.004a15.489 15.489 0 0 1-8.386-2.453 10.986 10.986 0 0 0 8.097-2.267 5.478 5.478 0 0 1-5.112-3.8c.82.159 1.665.126 2.47-.094a5.472 5.472 0 0 1-4.388-5.362v-.07c.76.424 1.612.659 2.483.685a5.478 5.478 0 0 1-1.693-7.305 15.528 15.528 0 0 0 11.275 5.716 5.477 5.477 0 0 1 9.323-4.99c1.226-.242 2.4-.691 3.475-1.328a5.491 5.491 0 0 1-2.405 3.025 10.88 10.88 0 0 0 3.141-.86c-.734 1.1-1.66 2.059-2.73 2.833Z"
      fill="#325a32"
    />
  </svg>
);

export default SvgComponent;
