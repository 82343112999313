import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#201F1F"
      d="m.554 9.071 1.924 2.033a.712.712 0 0 0 1.047 0L5.45 9.07c.468-.494.134-1.342-.527-1.342H1.074c-.66 0-.988.848-.52 1.342ZM.554 2.929 2.478.896a.712.712 0 0 1 1.047 0L5.45 2.93c.468.494.134 1.342-.527 1.342H1.074c-.66 0-.988-.848-.52-1.342Z"
    />
  </svg>
);
export default SvgComponent;
