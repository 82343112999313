import React, { useState, useEffect, useRef } from "react";
import "pages/Support/SupportFAQ/SupportFAQ.scss";
import SearchGlass from "assets/svg/Search";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import {
  ControlledAccordion,
  useAccordionProvider,
} from "@szhsin/react-accordion";
import SupportAccordion from "components/SupportAccordion/SupportAccordion";
import DownloadLink from "components/reusable/Link/DownloadLink/DownloadLink";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetSupportSearchQuery } from "api/cms/live/Search";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const makeAnchorFriendly = (string) => {
  return string.replace(/\W/g, "");
};

const FAQSearchBar = (info) => {
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState(false);
  const [trigger, { data }] = useLazyGetSupportSearchQuery({
    searchQuery: search,
  });
  const searchDropDownRef = useRef();
  const location = useLocation().pathname.split("/");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      trigger({ searchQuery: search, localePath: location[1] });
    }, 700);

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  const handleDropdown = (value) => {
    setSearchClick(true);
    setSearch("");
    const tempPath = pathname.replace(/\/support\/[\w#]+\/?/g, "/support/");
    navigate(
      tempPath + value.page.slug + "#" + makeAnchorFriendly(value.original)
    );
  };

  const handleSearchInput = (value) => {
    setSearchClick(false);
    setSearch(value);
  };

  const handleKeyDownSearch = (event) => {
    if (event.key === "Enter") {
      handleSearchInput(event.target.value);
    } else if (data?.search_query && event.key === "ArrowDown") {
      event.preventDefault();
      searchDropDownRef.current.getElementsByTagName("p")[0].focus();
    }
  };

  const handlePKeyDown = (e, item, index) => {
    const resultsLength = searchDropDownRef.current.getElementsByTagName("p").length;
    if (e.key === "ArrowUp") {
      e.view.event.preventDefault();
      if (index === 0) {
        searchDropDownRef.current.getElementsByTagName("p")[resultsLength - 1].focus();
      } else {
        searchDropDownRef.current.getElementsByTagName("p")[index - 1].focus();
      }
    }
    if (e.key === "ArrowDown") {
      e.view.event.preventDefault();
      if (index === resultsLength - 1) {
        searchDropDownRef.current.getElementsByTagName("p")[0].focus();
      } else {
        searchDropDownRef.current.getElementsByTagName("p")[index + 1].focus();
      }
    }
    if (e.key === "Enter") {
      handleDropdown(item);
    }
  };

  return (
    <div className="faq-search-bar-container">
      <input
        value={search}
        onChange={(e) => handleSearchInput(e.target.value)}
        onKeyDown={(e) => handleKeyDownSearch(e)}
        placeholder={info?.info?.banner_search_placeholder}
        className="faq-input"
      />
      <div ref={searchDropDownRef} className="faq-dropdown">
        {!data?.search_query ? null : data?.search_results?.length === 0 ? (
          <p tabIndex="0">{info?.info?.banner_search_no_results}</p>
        ) : searchClick ? null : (
          data?.search_results?.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => handleDropdown(item)}
                onKeyDown={(e) => handlePKeyDown(e, item, index)}
                tabIndex="0"
              >
                {convertHtmlToReact(item.highlight)}
              </p>
            );
          })
        )}
      </div>
      <SearchGlass className="faq-search-icon" color={"black"} />
    </div>
  );
};

const SupportFAQ = ({ location }) => {
  const [searchText, setSearchText] = useState("");
  const { pathname, search } = useLocation();

  const accordianValue = useAccordionProvider({
    allowMultiple: false,
    transition: true,
    transitionTimeout: 200,
  });
  const { toggle, toggleAll } = accordianValue;

  let slug = pathname.split("/");
  slug = slug[slug.length - 1] ? slug[slug.length - 1] : slug[slug.length - 2];
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(search).useGetSupportFAQPageQuery({
      queryParams: search,
      localePath: pathname.split("/")[1],
      region: pathname.split("/")[2],
      slug,
    })
  );

  useEffect(() => {
    if (!isLoading) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const yOffset = -80;
        const yTarget =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yTarget, behavior: "smooth" });
        toggle(makeAnchorFriendly(id), true);
      }
    }
  }, [info]);

  return (
    <div className="resi-container">
      <div className="resi-margin-container">
        <div className="faq-container">
          <div className="faq-header">
            <h1 tabIndex="0">{info?.banner_header}</h1>

            <FAQSearchBar info={info}/>
          </div>

          <div className="faq-questions-container">
            <h2 tabIndex="0">{info?.header}</h2>

            {!isLoading && (
              <ControlledAccordion providerValue={accordianValue}>
                {info?.body.map((item, i) => {
                  return (
                    <SupportAccordion
                      key={i}
                      item={item}
                      toggle={toggle}
                      index={i}
                    />
                  );
                })}
              </ControlledAccordion>
            )}
          </div>

          <div className="faq-download-container">
            <h2 className="faq-download-title">{info?.downloads_header}</h2>
            {info?.support_files?.map((file, index) => {
              return <DownloadLink file={file.file_url} index={index} />;
            })}
          </div>

          {info?.contact_us_page?.badges?.length > 0 && (
            <ContactInfo
              title={info.contact_us_page.title}
              description={info.contact_us_page.description}
              badges={info.contact_us_page.badges}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const WithLocation = (Component) => {
  const ComponentWithLocationProp = (props) => {
    let location = useLocation();
    return <Component {...props} location={location} />;
  };

  return ComponentWithLocationProp;
};

export default WithLocation(SupportFAQ);
