import React from "react";
import "components/Carousel/JobCarousel/JobCarousel.scss";
import Slider from "react-slick";
import BlogRight from "assets/Carousel/Blog/BlogRight";
import BlogLeft from "assets/Carousel/Blog/BlogLeft";
import ActiveDot from "assets/Carousel/ActiveDot";
import InactiveDot from "assets/Carousel/InactiveDot";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const Carousel = ({ header, items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: items?.length < 5 ? items?.length : 5,
    slidesToScroll: 1,
    adaptiveHeight: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: items?.length < 3 ? items?.length : 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: (
      <div>
        <BlogRight />
      </div>
    ),
    prevArrow: (
      <div>
        <BlogLeft />
      </div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          position: "relative",
          top: 0,
        }}
      >
        {dots.map((item, index) => {
          if (item.props.className === "slick-active") {
            return <ActiveDot key={index} />;
          } else {
            return <InactiveDot key={index} />;
          }
        })}
      </div>
    ),
  };

  return (
    <div className="job-carousel-container">
      {convertHtmlToReact(header)}

      <Slider {...settings}>
        {items &&
          items.map((item, index) => {
            return (
              <div onClick={() => window.open(item.url)}>
                <div className="job-carousel-card">
                  <div>
                    <p className="job-carousel-card-department">
                      {item.department}
                    </p>
                    <p className="Wbold job-carousel-card-title">
                      {item.title}
                    </p>
                  </div>

                  <p className="job-carousel-card-location">{item.location}</p>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Carousel;
